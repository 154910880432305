import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { protectedResources } from '../authConfig';
import { useMsal } from '@azure/msal-react';




const GlobalAdminDashboard = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);

  const handleSelect = (org_id) => {
    setSelectedOrg(org_id);
    navigate(`/organization-admin-dashboard`, { state: { org_id: org_id } });
  };

  const fetchOrgs = async () => {
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiListOrgs.scopes,
        account: accounts[0],
      };

      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const fetchResponse = await fetch(
        protectedResources.apiListOrgs.endpoint,
        requestOptions
      );
      const data = await fetchResponse.json();
      setOrganizations(data.orgs);
    }
  };

  useEffect(() => {
    fetchOrgs();
    
    console.log(organizations);
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold mb-6">Select an Organization</h1>
        <select 
          onChange={(e) => handleSelect(e.target.value)} 
          value={selectedOrg || ''} 
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="" disabled>Select an organization</option>
          {organizations.map(org => (
            <option key={org.org_id} value={org.org_id}>
              {org.org_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default GlobalAdminDashboard;
