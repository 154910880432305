import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const ProgressBar = ({ title, value }) => {
  const percentage = (value / 5) * 100;
  return (
    <Container>
      <Title>{title}</Title>
      <BarContainer>
        <Bar width={percentage} />
      </BarContainer>
      <ValueDisplay>{`${value}/5`}</ValueDisplay>
    </Container>
  );
};

ProgressBar.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default ProgressBar;

const Container = styled.div`
  margin-bottom: 0.25rem;
`;

const Title = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const BarContainer = styled.div`
  width: 100%;
  height: 0.75rem;
  background-color: #e5e7eb;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const subtleAnimation = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 50px 0; }
`;

const Bar = styled.div`
  height: 100%;
  background: linear-gradient(to right, #3b82f6, #9333ea, #ec4899);
  border-radius: 0.25rem;
  transition: width 0.5s ease-in-out;
  width: ${(props) => props.width}%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.3), transparent);
    opacity: 0.3;
    background-size: 200% 100%;
    animation: ${subtleAnimation} 3s linear infinite;
  }
`;

const ValueDisplay = styled.span`
  display: block;
  text-align: right;
  margin-top: 0.25rem;
  font-weight: 600;
  color: #374151;
  font-size: 0.75rem;
`;