// src/pages/AcceptPolicies.js

import React, { useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "../components/catalyst/button";
import { Fieldset, Legend, FieldGroup } from "../components/catalyst/fieldset";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../authConfig";

const AcceptPolicies = () => {
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const location = useLocation();
    //const { actorInfo } = location.state;
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(false);

    const actorInfo = {
        actor_id: 1,
        actor_uuid: "1234-5678-91011-1213",
        email: "test@example.com",
        full_name: "Test User",
        accepted_most_recent_terms: false,
        accepted_most_recent_privacy_policy: false,
    };

    const getToken = async () => {
        const request = {
            scopes: protectedResources.apiGetMyInfo.scopes,
            account: accounts[0],
        };

        const response = await instance.acquireTokenSilent(request);
        return response.accessToken;
    };

    const handleAcceptPolicies = useCallback(async () => {
        try {
            const token = await getToken();

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({}),
            };

            const response = await fetch(protectedResources.apiAcceptPolicies.endpoint, requestOptions);
            const result = await response.json();

            if (result.success) {
                navigate("/er-dashboard", { state: { actorInfo: actorInfo } });
            } else {
                console.error("Failed to update actor info");
            }
        } catch (error) {
            console.error("Error updating actor info:", error);
        }
    }, [getToken, agreedToTerms, agreedToPrivacyPolicy, actorInfo, navigate]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="max-w-lg w-full bg-white shadow-lg rounded-lg p-8">
                <Fieldset>
                    <Legend className="text-black pb-4 text-2xl">Policy Refresh</Legend>
                    <p className="text-leading text-gray-600 mb-8">
                        We've refreshed our policies! Please acknowledge them below to continue using Cleo.
                    </p>
                    <FieldGroup className="flex flex-col items-start">
                        <div className="mb-4">
                            <input
                                type="checkbox"
                                id="terms"
                                checked={agreedToTerms}
                                onChange={(e) => setAgreedToTerms(e.target.checked)}
                                className="mr-2"
                            />
                            <label htmlFor="terms" className="text-lg">
                                I accept the{" "}
                                <a
                                    className="text-indigo-900"
                                    href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Terms_of_Use.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Terms of Use
                                </a>
                            </label>
                        </div>
                        <div className="mb-8">
                            <input
                                type="checkbox"
                                id="privacy"
                                checked={agreedToPrivacyPolicy}
                                onChange={(e) => setAgreedToPrivacyPolicy(e.target.checked)}
                                className="mr-2"
                            />
                            <label htmlFor="privacy" className="text-lg">
                                I accept the{" "}
                                <a
                                    className="text-indigo-900"
                                    href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Privacy_Policy.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </label>
                        </div>
                        <Button
                            onClick={handleAcceptPolicies}
                            color="indigo"
                            className="self-center w-full"
                            disabled={!agreedToTerms || !agreedToPrivacyPolicy}
                        >
                            Accept and Continue
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </div>
        </div>
    );
};

export default AcceptPolicies;
