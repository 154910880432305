import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useLocation, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave, faEdit, faChartLine, faCopy, faCheck,
    faArrowCircleLeft, faQuestionCircle, faFileCode,
    faCalculator, faChevronLeft, faChevronRight,
    faInfoCircle, faExclamationTriangle, faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'

import { Fieldset } from '../components/catalyst/fieldset';
import { Text, Strong } from '../components/catalyst/text';
//import { Textarea } from '../components/catalyst/textarea';
import { Button } from '../components/catalyst/button';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/catalyst/dialog';
import '../styles/ViewReports.css';

import {
    CheckCircleIcon, XMarkIcon, XCircleIcon, ExclamationCircleIcon,
    ShieldCheckIcon, InformationCircleIcon, ExclamationTriangleIcon, ChatBubbleLeftIcon
} from '@heroicons/react/20/solid';

import { useFeatureFlagEnabled } from 'posthog-js/react'

import TextareaAutosize from 'react-textarea-autosize';
import { PlusIcon } from '@heroicons/react/20/solid';

import ProgressBar from '../components/PatientViewer/ProgressBars';


const mockMacros = {
    "Chest Pain": {
        command: "Insert Chest Pain Description",
        content: "Patient describes the chest pain as sharp and constant, worsening with deep breaths."
    },
    "Normal EKG": {
        command: "Insert Normal EKG Results",
        content: "The EKG shows normal sinus rhythm with no signs of ischemia, injury, or infarct."
    },
    "Diabetes Management": {
        command: "Insert Diabetes Treatment Plan",
        content: "Increase Metformin to 1000mg twice daily. Consider initiation of SGLT2 inhibitor if no contraindications."
    }
};

function HospitalistChart() {
    const [hpi, setHPI] = useState('');
    const [ros, setROS] = useState('');
    const [exam, setExam] = useState('');
    const [reeval, setReeval] = useState('');
    const [mdm, setMDM] = useState('');
    const [riskAnalysis, setRiskAnalysis] = useState('');
    const [riskContent, setRiskContent] = useState([]);
    const [copa, setCopa] = useState(-1);
    const [complexityOfData, setComplexityOfData] = useState(-1);
    const [riskOfComplications, setRiskOfComplications] = useState(-1);
    const [riskScoreLabel, setRiskScoreLabel] = useState('');
    const riskScores = ["HEART", "Wells", "CURB-65", "GCS", "PERC Rule"];
    const [currentRiskScoreKey, setCurrentRiskScoreKey] = useState("");
    const [showRiskScoreDropdown, setShowRiskScoreDropdown] = useState(false);
    const [showMacroDropdown, setShowMacroDropdown] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading Notes...');
    const [patientName, setPatientName] = useState('');
    const [documentStatus, setDocumentStatus] = useState('');
    const [procedureDescription, setProcedureDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMDM, setIsLoadingMDM] = useState(false);
    const { instance, accounts, inProgress } = useMsal();
    const [copiedHPI, setCopiedHPI] = useState(false);
    const [copiedROS, setCopiedROS] = useState(false);
    const [copiedExam, setCopiedExam] = useState(false);
    const [copiedReeval, setCopiedReeval] = useState(false);
    const [copiedMDM, setCopiedMDM] = useState(false);
    const [copiedAll, setCopiedAll] = useState(false);
    const [isEditingPatientName, setIsEditingPatientName] = useState(false);
    const [isEditingProcedureDescription, setIsEditingProcedureDescription] = useState(false);
    const [riskScoreNotificationVisible, setRiskScoreNotificationVisible] = useState(false);
    const [riskAnalysisNotificationVisible, setRiskAnalysisNotificationVisible] = useState(false);
    const [canShowRiskAnalysisNotification, setCanShowRiskAnalysisNotification] = useState(false);
    const [mdmCodingNotificationVisible, setMDMCodingNotificationVisible] = useState(false);
    const [riskScoreText, setRiskScoreText] = useState('');
    const location = useLocation();
    const document = location.state?.document;
    const providedDate = location.state?.providedDate;
    const navigate = useNavigate();
    const [editedDocument, setEditedDocument] = useState(document);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
    const [showNotification, setShowNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [riskScore, setRiskScore] = useState(false);
    const [saveTriggered, setSaveTriggered] = useState(false);
    const [macro_list, setMacroList] = useState({});
    const riskScoreDropdownRef = useRef(null);
    const macroDropdownRef = useRef(null);
    const [isShowingMDM, setIsShowingMDM] = useState(false);
    const [noteFeedbackNotificationVisible, setNoteFeedbackNotificationVisible] = useState(!document.note_rating);
    const [noteFeedback, setNoteFeedback] = useState('');
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const isRiskAnalystEnabled = useFeatureFlagEnabled('risk_analyst');
    const isMDMRecodeEnabled = useFeatureFlagEnabled('mdm_recode');
    const [macroSearchQuery, setMacroSearchQuery] = useState('');
    const [originalMDM, setOriginalMDM] = useState(mdm);
    const [cleanedMDM, setCleanedMDM] = useState(mdm);
    const [isRemoved, setIsRemoved] = useState(false);
    const [icd10Codes, setICD10Codes] = useState('');
    const [copiedICD10, setCopiedICD10] = useState(false);
    const [isLoadingICD10, setIsLoadingICD10] = useState(false);
    const [subjective, setSubjective] = useState('');
    const [objective, setObjective] = useState('');
    const [assessmentAndPlan, setAssessmentAndPlan] = useState('');
    const [dischargeSummary, setDischargeSummary] = useState('');
    const [copiedSubjective, setCopiedSubjective] = useState(false);
    const [copiedObjective, setCopiedObjective] = useState(false);
    const [copiedAssessmentAndPlan, setCopiedAssessmentAndPlan] = useState(false);
    const [copiedDischargeSummary, setCopiedDischargeSummary] = useState(false);
    const updateSubjective = (value) => {
        setSubjective(value);
        // Additional logic if needed
    };
    const updateObjective = (value) => {
        setObjective(value);
        // Additional logic if needed
    };
    const updateAssessmentAndPlan = (value) => {
        setAssessmentAndPlan(value);
        // Additional logic if needed
    };
    const updateDischargeSummary = (value) => {
        setDischargeSummary(value);
        // Additional logic if needed
    };


    const generateICD10Codes = async () => {
        setIsLoadingICD10(true);
        try {
            const request = {
                scopes: protectedResources.apiGenerateICD10.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiGenerateICD10.endpoint, requestOptions);

            const data = await fetchResponse.json();
            updateICD10Codes(data.code_suggestions);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error generating ICD-10 codes:', error);
            // Handle any errors here
        }
        setIsLoadingICD10(false);
    };

    useEffect(() => {
        try {
            const riskAnalysisObj = JSON.parse(riskAnalysis);
            const contentArray = Object.keys(riskAnalysisObj).map(key => riskAnalysisObj[key]);
            setRiskContent(contentArray);
        } catch (error) {
            console.error('Invalid JSON string:', error);
        }
    }, [riskAnalysis]);

    const handleRiskNotificationClose = () => {
        setRiskAnalysisNotificationVisible(false);
    };

    const handleMDMNotificationClose = () => {
        setMDMCodingNotificationVisible(false);
    };

    const renderRiskContent = () => {
        if (!Array.isArray(riskContent)) return null;

        return (
            <>
                {riskContent.map((item, index) => (
                    <div key={index} className="py-4 px-2 rounded-lg shadow-md mb-4">
                        <div className="flex items-center">
                            {getClassStyle(item.class)}
                            <p className={`text-sm ml-2 ${item.class === 'warning' ? 'font-bold' : ''}`}>{item.msg}</p>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    const getClassStyle = (type) => {
        switch (type) {
            case 'info':
                return <FontAwesomeIcon icon={faInfoCircle} className="ml-0 p-0 h-6 w-6 text-blue-800" />;
            case 'caution':
                return <FontAwesomeIcon icon={faExclamationTriangle} className="ml-0 p-0 h-6 w-6 text-yellow-800" />;
            case 'warning':
                return <FontAwesomeIcon icon={faExclamationCircle} className="ml-0 p-0 h-6 w-6 text-red-800" />;
            default:
                return null;
        }
    };


    const handleRiskScoreChange = (field, value) => {
        setRiskScore((prevScores) => {
            // Check if the field is being unselected (the new value is the same as the current value)
            const isUnselecting = prevScores[field] === value;

            return {
                ...prevScores,
                // If unselecting, set the field's value to null, otherwise, update it with the new value
                [field]: isUnselecting ? null : value,
            };
        });
    };

    const insertSelectedMacro = async (macro) => {
        const macroSection = macro.section;
        console.log("macro: ", macro);
        const sectionMapping = {
            "hpi": hpi,
            "ros": ros,
            "physical_exam": exam,
            "reevaluation": reeval
        }
        console.log(macroSection);
        const sectionToUpdate = sectionMapping[macroSection];
        const updatedSection = sectionToUpdate ? sectionToUpdate + "\n\n" + macro.content : macro.content;
        console.log("macro section", macroSection);
        const setterMapping = {
            "hpi": setHPI,
            "ros": setROS,
            "physical_exam": setExam,
            "reevaluation": setReeval
        };
        // Update the section with the new macro content
        setterMapping[macroSection](updatedSection);
        // Save the updated section to the database
        saveDocument(macroSection, updatedSection);

        // Update macro_manually_inserted flag in backend
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            // Prepare the API call
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    document_id: document.document_id,
                    field_to_update: 'macro_manually_inserted',
                    new_value: true
                })
            };

            // Make the API call to update the macro_manually_inserted flag
            await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);

        } catch (error) {
            console.error('Error updating macro_manually_inserted flag:', error);
            // Handle any errors here
        }
    };


    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);

    const copyAllToClipboard = () => {
        let allText = `HISTORY OF PRESENT ILLNESS: \n${hpi}\n\nREVIEW OF SYSTEMS: \n${ros}\n\nPHYSICAL EXAM: \n${exam}\n\nEVALUATIONS: \n${reeval}`;

        if (mdm) {
            allText += `\n\nMEDICAL DECISION-MAKING: \n${mdm}`;
        }
        navigator.clipboard.writeText(allText).then(() => {
            setCopiedAll(true);
            updateDocument(document.document_id, 'copied_all', true);
            setTimeout(() => {
                setCopiedAll(false);
            }, 3000); // Reset copy state after 3 seconds
        });
    };

    const copyMDMToClipboard = () => {
        navigator.clipboard.writeText(mdm).then(() => {
            setCopiedAll(true);
            setTimeout(() => {
                setCopiedAll(false);
            }, 3000); // Reset copy state after 3 seconds
        });
    };

    const updateHPI = (newHPI) => {
        setHPI(newHPI);
        setEditedDocument(prev => ({ ...prev, hpi: newHPI }));
    };

    const updateROS = (newROS) => {
        setROS(newROS);
        setEditedDocument(prev => ({ ...prev, ros: newROS }));
    };

    const updateExam = (newExam) => {
        setExam(newExam);
        setEditedDocument(prev => ({ ...prev, physical_exam: newExam }));
    };

    const updateReeval = (newReeval) => {
        setReeval(newReeval);
        setEditedDocument(prev => ({ ...prev, reevaluation: newReeval }));
    };

    const updateMDM = (newMDM) => {
        setMDM(newMDM);
        setEditedDocument(prev => ({ ...prev, mdm: newMDM }));
    };

    const updateProcedureDescription = (newProcedureDescription) => {
        setProcedureDescription(newProcedureDescription);
        setEditedDocument(prev => ({ ...prev, procedure_description: newProcedureDescription }));
    };

    const updatePatientName = (newPatientName) => {
        setPatientName(newPatientName);
        setEditedDocument(prev => ({ ...prev, patient_name: newPatientName }));
    };

    const updateICD10Codes = (newICD10Codes) => {
        setICD10Codes(newICD10Codes);
        setEditedDocument(prev => ({ ...prev, icd10_codes: newICD10Codes }));
    };

    const saveDocument = async (fieldToSave, newValue) => {
        let hasChanges = false;

        if (newValue === undefined) {
            switch (fieldToSave) {
                case 'hpi':
                    hasChanges = document.hpi !== hpi;
                    newValue = hpi;
                    break;
                case 'ros':
                    hasChanges = document.ros !== ros;
                    newValue = ros;
                    break;
                case 'physical_exam':
                    hasChanges = document.physical_exam !== exam;
                    newValue = exam;
                    break;
                case 'reeval':
                    hasChanges = document.reevaluation !== reeval;
                    newValue = reeval;
                    break;
                case 'mdm':
                    hasChanges = document.mdm !== mdm;
                    newValue = mdm;
                    break;
                case 'procedure_description':
                    hasChanges = document.procedure_description !== procedureDescription;
                    newValue = procedureDescription;
                    break;
                case 'patient_name':
                    hasChanges = document.patient_name !== patientName;
                    newValue = patientName;
                    break;
                case 'note_feedback':
                    hasChanges = document.note_feedback !== noteFeedback;
                    newValue = noteFeedback;
                    break;
                case 'code':
                    hasChanges = document.icd10_codes !== icd10Codes;
                    newValue = icd10Codes;
                    break;
                case 'hospitalist_discharge_summary':
                    hasChanges = document.hospitalist_discharge_summary !== dischargeSummary;
                    newValue = dischargeSummary;
                    break;
                case 'subjective':
                    hasChanges = document.subjective !== subjective;
                    newValue = subjective;
                    break;
                case 'objective':
                    hasChanges = document.objective !== objective;
                    newValue = objective;
                    break;
                case 'assessment_and_plan':
                    hasChanges = document.assessment_and_plan !== assessmentAndPlan;
                    newValue = assessmentAndPlan;
                    break;
                default:
                    console.log('Invalid field to save');
                    return;
            }
        } else {
            // Directly check changes against the document's existing value
            hasChanges = document[fieldToSave] !== newValue;
        }

        if (!hasChanges) {
            console.log("No changes to save.");
            return; // Exit if no changes
        }

        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id, "field_to_update": fieldToSave, "new_value": newValue })
            };

            const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (data.updated_document) {
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 3000);
            }
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            setErrorMessage("An error occurred while updating the document."); // Customize this message based on the error if needed
            setShowErrorNotification(true);
            setTimeout(() => setShowErrorNotification(false), 5000); // Hide the notification after 5 seconds
        }

    };

    const parseMDMCoding = (mdmCoding) => {
        // Initialize default values
        let copa = -1;
        let complexityOfData = -1;
        let riskOfComplications = -1;

        // Split the mdmCoding string by commas to get individual parts
        const parts = mdmCoding.split(', ');

        // Parse each part to extract the integer values
        parts.forEach(part => {
            const [key, value] = part.split(': ');
            const intValue = parseInt(value.trim());
            if (key.trim() === 'copa') {
                copa = intValue;
            } else if (key.trim() === 'complexity_of_data') {
                complexityOfData = intValue;
            } else if (key.trim() === 'risk_of_complications') {
                riskOfComplications = intValue;
            }
        });

        setCopa(copa);
        setComplexityOfData(complexityOfData);
        setRiskOfComplications(riskOfComplications);
    };

    const approveDocument = async () => {
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id, "field_to_update": "document_status", "new_value": "FINALIZED" })
            };

            const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);

            console.log('providedDate:', providedDate);

            navigate('/hospitalist-inpatient-dashboard', { state: { providedDate: providedDate } });

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }
    };

    const calculateSelectedRiskScore = async (score) => {
        setLoadingMessage("Calculating Risk Score...");
        setIsLoading(true);
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            let allText = `HISTORY OF PRESENT ILLNESS: \n${hpi}\n\nREVIEW OF SYSTEMS: \n${ros}\n\nPHYSICAL EXAM: \n${exam}\n\nEVALUATIONS: \n${reeval}`;
            let newRiskScoreKey = '';

            if (score === 'HEART') {
                setRiskScoreLabel('HEART Score');
                setCurrentRiskScoreKey('HEART');
                newRiskScoreKey = 'HEART';
            } else if (score === "Wells") {
                setRiskScoreLabel('Wells');
                setCurrentRiskScoreKey('Wells');
                newRiskScoreKey = 'Wells';
            } else if (score === "CURB-65") {
                setRiskScoreLabel('CURB-65');
                setCurrentRiskScoreKey('CURB65'); // Remove dash for code-friendly key
                newRiskScoreKey = 'CURB65'; // Remove dash for code-friendly key
            } else if (score === "GCS") {
                setRiskScoreLabel('GCS');
                setCurrentRiskScoreKey('GCS');
                newRiskScoreKey = 'GCS';
            } else if (score === "PERC Rule") {
                setRiskScoreLabel('PERC Rule');
                setCurrentRiskScoreKey('PERCRule'); // Remove space for code-friendly key
                newRiskScoreKey = 'PERCRule'; // Remove space for code-friendly key
            } else {
                setRiskScoreLabel('Risk Score');
                setCurrentRiskScoreKey('Generic');
                newRiskScoreKey = 'Generic';
            }

            //Update risk score flag
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    document_id: document.document_id,
                    field_to_update: `generated_${newRiskScoreKey.toLowerCase()}_score`,
                    new_value: true
                })
            };

            await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);

            if (riskScoreConfigurations[newRiskScoreKey]) {
                // Initialize riskScore based on the selected score type
                const initialRiskScore = {};
                riskScoreConfigurations[newRiskScoreKey].forEach(item => {
                    initialRiskScore[item.key] = null;
                });

                setRiskScore(initialRiskScore);
            } else {
                console.error('Invalid score type:', newRiskScoreKey);
                setRiskScore({});
            }
            setRiskScoreNotificationVisible(true); // Show the notification card

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error calculating risk score:', error);
            // Handle any errors here
        }
        setIsLoading(false);
        setShowRiskScoreDropdown(false);
    };

    const calculateMDM = async () => {
        setIsLoadingMDM(true);
        try {

            const request = {
                scopes: protectedResources.apiCalculateMDM.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiCalculateMDM.endpoint, requestOptions);

            const data = await fetchResponse.json();
            setMDM(data.mdm_content);
            parseMDMCoding(data.mdm_coding);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error calculating MDM:', error);
            // Handle any errors here
        }
        setIsLoadingMDM(false);
    };

    const codeMDM = async () => {
        setIsLoading(true);
        try {

            const request = {
                scopes: protectedResources.apiCodeMDM.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiCodeMDM.endpoint, requestOptions);

            const data = await fetchResponse.json();
            console.log('data:', data);
            parseMDMCoding(data.mdm_coding);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error coding MDM:', error);
            // Handle any errors here
        }
        setIsLoading(false);
    };

    const fetchDocument = useCallback(async () => {
        setLoadingMessage("Fetching Document...");
        setIsLoading(true);
        if (accounts.length > 0) {
            try {
                const documentRequest = {
                    scopes: protectedResources.apiGetDocument.scopes,
                    account: accounts[0]
                };
                const macrosRequest = {
                    scopes: protectedResources.apiGetMacros.scopes,
                    account: accounts[0]
                };

                // Acquire tokens for both requests
                const [documentToken, macrosToken] = await Promise.all([
                    instance.acquireTokenSilent(documentRequest),
                    instance.acquireTokenSilent(macrosRequest)
                ]);

                // Fetch document and macros in parallel
                const documentFetchOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${documentToken.accessToken}`
                    },
                    body: JSON.stringify({ document_id: document.document_id })
                };

                const macrosFetchOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${macrosToken.accessToken}`
                    }
                };


                const [documentResponse, macrosResponse] = await Promise.all([
                    fetch(protectedResources.apiGetDocument.endpoint, documentFetchOptions),
                    fetch(protectedResources.apiGetMacros.endpoint, macrosFetchOptions)
                ]);

                const documentData = await documentResponse.json();
                const macrosData = await macrosResponse.json();

                if (documentData.document) {
                    setHPI(documentData.document.hpi);
                    setROS(documentData.document.ros);
                    setExam(documentData.document.physical_exam);
                    setReeval(documentData.document.reevaluation);
                    setMDM(documentData.document.mdm);
                    setSubjective(documentData.document.subjective);
                    setObjective(documentData.document.objective);
                    setAssessmentAndPlan(documentData.document.assessment_and_plan);
                    setDischargeSummary(documentData.document.hospitalist_discharge_summary);
                    setPatientName(documentData.document.patient_name);
                    setDocumentStatus(documentData.document.document_status);
                    setProcedureDescription(documentData.document.procedure_description);
                    if (documentData.document.mdm_coding) {
                        parseMDMCoding(documentData.document.mdm_coding);
                    }
                    setICD10Codes(documentData.document.code_suggestions);
                } else {
                    setHPI("");
                    setROS("");
                    setExam("");
                    setReeval("");
                    setMDM('');
                    setPatientName("");
                    setProcedureDescription("");
                    setICD10Codes("");
                }

                if (documentData.document.risk_analysis) {
                    setRiskAnalysis(documentData.document.risk_analysis);
                    setCanShowRiskAnalysisNotification(true);
                    setRiskAnalysisNotificationVisible(true);
                }

                const newMacros = Object.keys(macrosData.macros).map(key => ({
                    title: key,
                    command: macrosData.macros[key].command,
                    content: macrosData.macros[key].content,
                    section: macrosData.macros[key].section
                }));
                setMacroList(newMacros);

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching reports:', error);
            }
        } setIsLoading(false);
    }, [instance, accounts, document, handleTokenExpiration]);

    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text);
        switch (type) {
            case 'hpi':
                setCopiedHPI(true);
                updateDocument(document.document_id, 'copied_hpi', true);
                setTimeout(() => setCopiedHPI(false), 3000);
                break;
            case 'ros':
                setCopiedROS(true);
                updateDocument(document.document_id, 'copied_ros', true);
                setTimeout(() => setCopiedROS(false), 3000);
                break;
            case 'physical_exam':
                setCopiedExam(true);
                updateDocument(document.document_id, 'copied_physical_exam', true);
                setTimeout(() => setCopiedExam(false), 3000);
                break;
            case 'reeval':
                setCopiedReeval(true);
                updateDocument(document.document_id, 'copied_reevaluation', true);
                setTimeout(() => setCopiedReeval(false), 3000);
                break;
            case 'mdm':
                setCopiedMDM(true);
                updateDocument(document.document_id, 'copied_mdm', true);
                setTimeout(() => setCopiedMDM(false), 3000);
                break;
            case 'icd10_codes':
                setCopiedICD10(true);
                updateDocument(document.document_id, 'copied_icd10_codes', true);
                setTimeout(() => setCopiedICD10(false), 3000);
                break;
            default:
                console.error('Invalid copy type:', type);
                break;
        }
    };

    const updateDocument = async (documentId, fieldToUpdate, newValue) => {
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    document_id: documentId,
                    field_to_update: fieldToUpdate,
                    new_value: newValue
                })
            };

            await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
            console.log('Updated', fieldToUpdate, 'to', newValue, 'for document', documentId);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };



    useEffect(() => {
        const loading = inProgress === InteractionStatus.Startup ||
            inProgress === InteractionStatus.HandleRedirect ||
            inProgress === InteractionStatus.Login ||
            inProgress === InteractionStatus.Logout;

        setIsLoading(loading);
        const timeoutId = setTimeout(() => {
            fetchDocument();
        }, 250); // Delay of 250 milliseconds
    }, [fetchDocument, inProgress]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (riskScoreDropdownRef.current && !riskScoreDropdownRef.current.contains(event.target)) {
                setShowRiskScoreDropdown(false);
            }
            if (macroDropdownRef.current && !macroDropdownRef.current.contains(event.target)) {
                setShowMacroDropdown(false);
            }
        };

        // Attach the listener if either dropdown is visible
        if (showRiskScoreDropdown || showMacroDropdown) {
            window.addEventListener('click', handleClickOutside);
        }

        // Cleanup the listener
        return () => window.removeEventListener('click', handleClickOutside);
    }, [showRiskScoreDropdown, showMacroDropdown]);


    const goBack = () => {
        navigate('/hospitalist-inpatient-dashboard', { state: { providedDate: providedDate } });
    };




    const formatTime = (utcString) => {

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const utcDate = new Date(utcString);
        const formatter = new Intl.DateTimeFormat(undefined, options);
        return formatter.format(utcDate);
    };

    const formatDate = (utcString) => {
        const date = new Date(utcString);

        const options = { month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    };


    function RiskScoreOptions({ label, options, selectedValue, onChange }) {
        return (
            <div className="flex justify-between items-center w-full my-2 border-b border-gray-300 pb-2 px-2">
                <div className="text-sm font-semibold mr-8 flex items-center">
                    {selectedValue === null ? (
                        <span className="mr-2 h-2 w-2 bg-red-500 rounded-full inline-block"></span>
                    ) : null}
                    {label}:
                </div>
                <div className="flex flex-col items-end">
                    {options.map((option, index) => {
                        let borderRadiusClass = '';
                        if (index === 0) {
                            borderRadiusClass = 'rounded-t-md';
                        } else if (index === options.length - 1) {
                            borderRadiusClass = 'rounded-b-md';
                        } else {
                            borderRadiusClass = '';
                        }

                        return (
                            <button
                                key={index}
                                type="button"
                                className={`relative inline-flex justify-between items-center w-64 px-3 py-2 text-sm font-semibold text-gray-900 ${selectedValue === option.value ? 'bg-indigo-500 text-white' : 'bg-gray-100'} ring-1 ring-inset ring-gray-300 focus:z-10 ${borderRadiusClass}`}
                                onClick={() => onChange(option.value)}
                            >
                                <span>{option.label}</span> <span>{option.value}</span>
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    }

    const heartScoreCategories = [
        {
            key: 'H',
            label: 'History',
            options: [
                { label: 'Slightly suspicious', value: 0 },
                { label: 'Moderately suspicious', value: 1 },
                { label: 'Highly suspicious', value: 2 },
            ],
        },
        {
            key: 'E',
            label: 'EKG',
            options: [
                { label: 'Normal', value: 0 },
                { label: 'Non-specific repolarization', value: 1 },
                { label: 'Significant ST deviation', value: 2 },
            ],
        },
        {
            key: 'A',
            label: 'Age',
            options: [
                { label: '<45', value: 0 },
                { label: '45-65', value: 1 },
                { label: '>65', value: 2 },
            ],
        },
        {
            key: 'R',
            label: 'Risk Factors',
            options: [
                { label: 'No risk factors', value: 0 },
                { label: '1-2 risk factors', value: 1 },
                { label: '>= 3 risk factors', value: 2 },
            ],
        },
        {
            key: 'T',
            label: 'Troponin',
            options: [
                { label: '<= normal limit', value: 0 },
                { label: '1-3x normal limit', value: 1 },
                { label: '>3x normal limit', value: 2 },
            ],
        },
    ];
    const riskScoreConfigurations = {
        HEART: [
            {
                key: 'H',
                label: 'History',
                options: [
                    { label: 'Slightly suspicious', value: 0 },
                    { label: 'Moderately suspicious', value: 1 },
                    { label: 'Highly suspicious', value: 2 },
                ],
            },
            {
                key: 'E',
                label: 'EKG',
                options: [
                    { label: 'Normal', value: 0 },
                    { label: 'Non-specific repolarization', value: 1 },
                    { label: 'Significant ST deviation', value: 2 },
                ],
            },
            {
                key: 'A',
                label: 'Age',
                options: [
                    { label: '<45', value: 0 },
                    { label: '45-65', value: 1 },
                    { label: '>65', value: 2 },
                ],
            },
            {
                key: 'R',
                label: 'Risk Factors',
                options: [
                    { label: 'No risk factors', value: 0 },
                    { label: '1-2 risk factors', value: 1 },
                    { label: '>= 3 risk factors', value: 2 },
                ],
            },
            {
                key: 'T',
                label: 'Troponin',
                options: [
                    { label: '<= normal limit', value: 0 },
                    { label: '1-3x normal limit', value: 1 },
                    { label: '>3x normal limit', value: 2 },
                ],
            },
        ],
        Wells: [
            {
                key: 'DVT',
                label: 'Clinical signs and symptoms of DVT',
                options: [{ label: 'Yes', value: 3 }, { label: 'No', value: 0 }]
            },
            {
                key: 'PE',
                label: 'PE is #1 diagnosis OR equally likely',
                options: [{ label: 'Yes', value: 3 }, { label: 'No', value: 0 }]
            },
            {
                key: 'HR',
                label: 'Heart rate > 100 bpm',
                options: [{ label: 'Yes', value: 1.5 }, { label: 'No', value: 0 }]
            },
            {
                key: 'Immobilization',
                label: 'Immobilization at least 3 days OR surgery in the previous 4 weeks',
                options: [{ label: 'Yes', value: 1.5 }, { label: 'No', value: 0 }]
            },
            {
                key: 'PreviousDVT',
                label: 'Previous, objectively diagnosed PE or DVT',
                options: [{ label: 'Yes', value: 1.5 }, { label: 'No', value: 0 }]
            },
            {
                key: 'Hemoptysis',
                label: 'Hemoptysis',
                options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
            },
            {
                key: 'Cancer',
                label: 'Malignancy w/ treatment within 6 months or palliative',
                options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
            }
        ],
        CURB65: [
            {
                key: 'Confusion',
                label: 'Confusion',
                options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
            },
            {
                key: 'Urea',
                label: 'BUN >19 mg/dL (>7 mmol/L urea)',
                options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
            },
            {
                key: 'RespiratoryRate',
                label: 'Respiratory Rate ≥ 30/min',
                options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
            },
            {
                key: 'BloodPressure',
                label: 'Systolic BP <90 mmHg or Diastolic BP ≤60 mmHg',
                options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
            },
            {
                key: 'Age',
                label: 'Age ≥ 65 years',
                options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
            }
        ],
        GCS: [
            {
                key: 'EyeOpening',
                label: 'Best eye response',
                options: [
                    { label: 'Spontaneous', value: 4 },
                    { label: 'To voice', value: 3 },
                    { label: 'To pain', value: 2 },
                    { label: 'No eye opening', value: 1 },
                ],
            },
            {
                key: 'VerbalResponse',
                label: 'Best verbal response',
                options: [
                    { label: 'Oriented', value: 5 },
                    { label: 'Confused', value: 4 },
                    { label: 'Inappropriate words', value: 3 },
                    { label: 'Incomprehensible sounds', value: 2 },
                    { label: 'No verbal response', value: 1 },
                ],
            },
            {
                key: 'MotorResponse',
                label: 'Best motor response',
                options: [
                    { label: 'Obeys commands', value: 6 },
                    { label: 'Localizes pain', value: 5 },
                    { label: 'Withdraws to pain', value: 4 },
                    { label: 'Flexion to pain', value: 3 },
                    { label: 'Extension to pain', value: 2 },
                    { label: 'No motor response', value: 1 },
                ],
            },
        ],
        PERCRule: [
            {
                key: 'Age',
                label: 'Age >= 50 years',
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ]
            },
            {
                key: 'HR',
                label: 'Heart rate >= 100 bpm',
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ]
            },
            {
                key: 'OxygenSaturation',
                label: 'Oxygen saturation < 95%',
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ]
            },
            {
                key: 'LegSwelling',
                label: 'Unilateral leg swelling',
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ]
            },
            {
                key: 'Hemoptysis',
                label: 'Hemoptysis',
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ]
            },
            {
                key: 'Surgery',
                label: 'Recent surgery ortrauma',
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ]
            },
            {
                key: 'PreviousPE',
                label: 'Prior PE or DVT',
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ]
            },
            {
                key: 'HormoneUse',
                label: 'Hormone Use',
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ]
            }
        ],

    }


    function evaluateRiskScore(riskScore, scoreType) {
        const scoreValues = Object.values(riskScore);
        const totalScore = scoreValues.reduce((total, current) => current !== null ? total + current : total, 0);
        const isComplete = scoreValues.length > 0 && scoreValues.every(value => value !== null);
        let advisories = [];

        // Initialize advisories based on scoreType
        switch (scoreType) {
            case 'HEART':
                advisories.push(`Total HEART Score: ${totalScore}`);
                if (totalScore >= 0 && totalScore <= 3) {
                    advisories.push('Low Score (0-3 points)');
                    advisories.push('Risk of MACE of 0.9-1.7%.');
                } else if (totalScore >= 4 && totalScore <= 6) {
                    advisories.push('Moderate Score (4-6 points)');
                    advisories.push('Risk of MACE of 12-16.6%.');
                } else if (totalScore >= 7 && totalScore <= 10) {
                    advisories.push('High Score (7-10 points)');
                    advisories.push('Risk of MACE of 50-65%.');
                }

                if (riskScore['E'] === 2 && totalScore <= 6) {
                    advisories.push("If EKG is highly suspicious, many experts recommend further workup and admission even with a low HEART Score.");
                }
                if ((riskScore['T'] === 1 || riskScore['T'] === 2) && totalScore <= 6) {
                    advisories.push("If troponin is positive, many experts recommend further workup and admission even with a low HEART Score.");
                }
                // Add HEART-specific advisories based on totalScore and other conditions
                break;
            case 'Wells':
                advisories.push(`Total Wells Score: ${totalScore}`);
                if (totalScore < 2) {
                    advisories.push("Low risk group: 1.3% chance of PE in an ED population.");
                } else if (totalScore >= 2 && totalScore <= 6) {
                    advisories.push("Moderate risk group: 16.2% chance of PE in an ED population.");
                } else if (totalScore > 6) {
                    advisories.push("High risk group: 40.6% chance of PE in an ED population.");
                }
                if (totalScore <= 4) {
                    advisories.push("Another study assigned scores ≤ 4 as “PE Unlikely” and had a 3% incidence of PE.");
                } else if (totalScore > 4) {
                    advisories.push("Another study assigned scores > 4 as “PE Likely” and had a 28% incidence of PE.");
                }
                break;
            case 'CURB65':
                advisories.push(`Total CURB-65 Score: ${totalScore}`);
                if (totalScore === 0) {
                    advisories.push("Low risk group: 0.6% 30-day mortality.");
                    advisories.push("Consider outpatient treatment.");
                } else if (totalScore === 1) {
                    advisories.push("Low risk group: 2.7% 30-day mortality.");
                    advisories.push("Consider outpatient treatment.");
                } else if (totalScore === 2) {
                    advisories.push("Moderate risk group: 6.8% 30-day mortality.");
                    advisories.push("Consider inpatient treatment or outpatient with close followup.");
                } else if (totalScore === 3) {
                    advisories.push("Severe risk group: 14.0% 30-day mortality.");
                    advisories.push("Consider inpatient treatment with possible intensive care admission.");
                } else if (totalScore >= 4) {
                    advisories.push("Highest risk group: 27.8% 30-day mortality.");
                    advisories.push("Consider inpatient treatment with possible intensive care admission.");
                }
                break;
            case 'GCS':
                const EyeScore = riskScore['EyeOpening'];
                const VerbalScore = riskScore['VerbalResponse'];
                const MotorScore = riskScore['MotorResponse'];
                advisories.push(`Total GCS Score: ${totalScore}`);
                advisories.push(`Glasgow Coma Scale: E(${EyeScore}) V(${VerbalScore}) M(${MotorScore})`);
                break;
            case 'PERCRule':
                advisories.push(`Total PERC Rule Criteria: ${totalScore}`);
                if (totalScore === 0) {
                    advisories.push("No need for further workup, as <2% chance of PE.");
                    advisories.push("If no criteria are positive and clinician’s pre-test probability is <15%, PERC Rule criteria are satisfied.");
                }
                if (totalScore > 0) {
                    advisories.push("If any criteria are positive, the PERC rule cannot be used to rule out PE in this patient.");
                }
                // Add PERC Rule-specific advisories
                break;
            default:
                advisories.push("Score type not recognized.");
                break;
        }

        return { totalScore, isComplete, advisories };
    }


    const updateReevaluationWithSummary = () => {
        const { advisories } = evaluateRiskScore(riskScore, currentRiskScoreKey);
        const riskScoreConfiguration = riskScoreConfigurations[currentRiskScoreKey];

        // Dynamically generate the INPUTS section based on the current risk score configuration and selected values
        const inputsSummary = riskScoreConfiguration.map(category => {
            const selectedOption = category.options.find(option => option.value === riskScore[category.key]);
            return `${category.label} —> ${riskScore[category.key]} = ${selectedOption ? selectedOption.label : 'Not selected'}`;
        }).join('\n');

        const summary = `
${riskScoreLabel} Calculations

RESULT SUMMARY:
${advisories.join('\n\n')}

INPUTS:
${inputsSummary}
    `;

        updateReeval(reeval + "\n" + summary);
        setSaveTriggered(true);
        setRiskScoreNotificationVisible(false);
    };


    useEffect(() => {
        if (saveTriggered) {
            // Now inside this effect, you know reeval has been updated
            saveDocument('reeval');
            setSaveTriggered(false); // Reset trigger to avoid unintended saves
        }
    }, [reeval, saveTriggered]); // Reacts to changes in reeval and the save trigger

    const filteredMacros = Object.values(macro_list).filter(macro =>
        macro.title.toLowerCase().includes(macroSearchQuery.toLowerCase())
    );

    const sectionMapping = {
        hpi: 'HPI',
        ros: 'ROS',
        physical_exam: 'Physical Exam',
        reevaluation: 'Evaluations'
    };

    const [showHpiMacros, setShowHpiMacros] = useState(false);

    const toggleHpiMacros = () => {
        setShowHpiMacros(!showHpiMacros);
    };

    const [showExamMacros, setShowExamMacros] = useState(false);

    const toggleExamMacros = () => {
        setShowExamMacros(!showExamMacros);
    };

    const [showRosMacros, setShowRosMacros] = useState(false);

    const toggleRosMacros = () => {
        setShowRosMacros(!showRosMacros);
    };

    const [showEvaluationsMacros, setShowEvaluationsMacros] = useState(false);

    const toggleEvaluationsMacros = () => {
        setShowEvaluationsMacros(!showEvaluationsMacros);
    };

    const submitNoteFeedback = async () => {
        if (!noteFeedback) return;

        try {
            setFeedbackSubmitted(true);
            setTimeout(() => {
                setNoteFeedbackNotificationVisible(false);
            }, 3000);
            await updateDocument(document.document_id, 'note_feedback', noteFeedback);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };



    const toggleBracketedText = () => {
        if (!isRemoved) {
            const bracketedPattern = /^[^\S\r\n]*.*?\[\*\*\*.*?\*\*\*\].*$(\r?\n|\r)?/gm;
            const cleanedMDM = mdm.replace(bracketedPattern, '').trim();
            setOriginalMDM(mdm); // Save the current state before changing
            setCleanedMDM(cleanedMDM);
            setMDM(cleanedMDM);
            setIsRemoved(true); // Update flag to indicate the text is removed
        } else if (mdm === cleanedMDM) {
            setMDM(originalMDM);
            setIsRemoved(false);
        } else {
            alert("MDM has been edited since bracketremoval; undo is not available.");
        }
    };






    const MacroList = ({ macros, onSelectMacro }) => {
        const sectionMapping = {
            hpi: "HPI",
            ros: "ROS",
            physical_exam: "Physical Exam",
            reevaluation: "Evaluations"
        };

        return (
            <div className="bg-gray-100 p-4 rounded shadow-lg">
                <h2 className="text-lg font-bold mb-4">Macros</h2>
                <ul>
                    {macros.map((macro, index) => (
                        <li key={index}>
                            <button
                                className="text-left w-full px-2 py-1 mb-2 border border-gray-300 rounded hover:bg-indigo-200"
                                onClick={() => onSelectMacro(macro)}
                            >
                                {macro.title} ({sectionMapping[macro.section]})
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const [showRatingButtons, setShowRatingButtons] = useState(true);

    const handleRatingUpdate = async (rating) => {
        setShowRatingButtons(false);
        await updateDocument(document.document_id, 'note_rating', rating);
        setShowRatingButtons(false);  // Hide the rating buttons
    };


    const Sidebar = ({ macros, onSelectMacro, isExpanded, toggleSidebar, position = 'left', maxHeight, section }) => {
        const sectionMapping = {
            hpi: "HPI",
            ros: "ROS",
            physical_exam: "Physical",
            reevaluation: "A&P"
        };

        const sectionMacros = macros.filter(macro => macro.section === section);

        const handleCreateMacro = () => {
            navigate('/macros');
        };

        return (
            <div className={`transition-width duration-300 ${isExpanded ? 'w-64' : 'w-6'} flex flex-col rounded-l-lg ${position === 'right' ? 'rounded-r-lg' : 'rounded-l-lg'}`} style={{ maxHeight }}>
                <button
                    className={`bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none items-center ${position === 'right' ? 'rounded-r-lg' : 'rounded-l-lg'}`}
                    onClick={toggleSidebar}
                >
                    {isExpanded ? (position === 'left' ? '<' : '>') : (position === 'left' ? 'M' : 'M')}
                </button>
                {!isExpanded && (
                    <div className="flex-grow flex items-center justify-center">
                        <div className="text-center transform -rotate-90 whitespace-nowrap">
                            {/* {sectionMapping[section]} Macros */}
                        </div>
                    </div>
                )}
                {isExpanded && (
                    <div className="p-2 overflow-auto" style={{ maxHeight: '300px' }}>
                        <h2 className="text-md text-center font-bold mb-2">{sectionMapping[section]} Macros</h2>
                        {sectionMacros.length > 0 ? (
                            <div className="rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-full">
                                <div className="py-1" role="none">
                                    {sectionMacros.map((macro, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            className="text-gray-700 block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 hover:text-indigo-700"
                                            role="menuitem"
                                            tabIndex="-1"
                                            onClick={() => onSelectMacro(macro)}
                                        >
                                            {macro.title}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="text-center">
                                {/* <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        vectorEffect="non-scaling-stroke"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                    />
                                </svg> */}
                                {/* <h3 className="mt-2 text-sm font-semibold text-gray-900">No {sectionMapping[section]} macros</h3> */}
                                <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                <div className="mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={handleCreateMacro}
                                    >
                                        {/* <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5 bg-indigo-600 hover:bg-indigo-500" aria-hidden="true" /> */}
                                        + New Macro
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const renderDocumentContent = () => {
        switch (document.hospitalist_document_type) {
            case 'ADMISSION_NOTE':
                return (
                    <>
                        <div className="flex" style={{ marginBottom: '25px', minHeight: '175px' }}>
                            <Sidebar
                                macros={filteredMacros}
                                onSelectMacro={insertSelectedMacro}
                                isExpanded={showHpiMacros}
                                toggleSidebar={toggleHpiMacros}
                                position='left'
                                maxHeight='500px'
                                section="hpi"
                            />
                            <div className={`flex-grow transition-width duration-300 mr-6`}>
                                <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                    <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                        <div className="font-bold text-slate-700 font-sans text-xl pl-2">History of Present Illness</div>
                                        <div className="flex justify-around">
                                            <Fieldset
                                                className="rounded p-2 cursor-pointer"
                                                onClick={() => { saveDocument('hpi'); }}
                                            >
                                                Save
                                                <FontAwesomeIcon icon={faSave} className="text-indigo-700 ml-2 mr-4" />
                                            </Fieldset>
                                            {copiedHPI ? (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(hpi, 'hpi')}>
                                                    Copied!
                                                    <FontAwesomeIcon icon={faCheck} className="text-indigo-700 ml-2" />
                                                </Fieldset>
                                            ) : (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(hpi, 'hpi')}>
                                                    Copy
                                                    <FontAwesomeIcon icon={faCopy} className="text-indigo-700 ml-2" />
                                                </Fieldset>
                                            )}
                                        </div>
                                    </Fieldset>
                                    <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                        <TextareaAutosize
                                            className="w-full border-none outline-none text-slate-600 resize-none whitespace-pre-line overflow-y-auto"
                                            value={hpi || ''}
                                            onChange={(e) => updateHPI(e.target.value)}
                                            onBlur={() => saveDocument('hpi', hpi)}>
                                        </TextareaAutosize>
                                    </div>
                                </Fieldset>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '30px', marginBottom: '25px', minHeight: '175px' }}>
                            {/* ROS Section with Sidebar on the Left */}
                            <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                                <Sidebar
                                    macros={filteredMacros}
                                    onSelectMacro={insertSelectedMacro}
                                    isExpanded={showRosMacros}
                                    toggleSidebar={toggleRosMacros}
                                    position='left'
                                    maxHeight='500px'
                                    section="ros"
                                />
                                <div className={`flex-grow transition-width duration-300`}>
                                    <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                        <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                            <div className="font-bold text-slate-700 font-sans text-xl pl-2">Review of Systems</div>
                                            <div className="flex justify-around">
                                                <Fieldset
                                                    className="rounded p-2 cursor-pointer"
                                                    onClick={() => { saveDocument('ros'); }}
                                                >
                                                    Save
                                                    <FontAwesomeIcon icon={faSave} className="text-indigo-700 ml-2 mr-4" />
                                                </Fieldset>
                                                {copiedROS ? (
                                                    <Fieldset
                                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                        onClick={() => copyToClipboard(ros, 'ros')}>
                                                        Copied!
                                                        <FontAwesomeIcon icon={faCheck} className="text-indigo-700 ml-2" />
                                                    </Fieldset>
                                                ) : (
                                                    <Fieldset
                                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                        onClick={() => copyToClipboard(ros, 'ros')}>
                                                        Copy
                                                        <FontAwesomeIcon icon={faCopy} className="text-indigo-700 ml-2" />
                                                    </Fieldset>
                                                )}
                                            </div>
                                        </Fieldset>
                                        <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                            <TextareaAutosize
                                                className="w-full border-none outline-none text-slate-600 resize-none whitespace-pre-line overflow-y-auto"
                                                value={ros || ''}
                                                onChange={(e) => updateROS(e.target.value)}
                                                onBlur={() => saveDocument('ros', ros)}>
                                            </TextareaAutosize>
                                        </div>
                                    </Fieldset>
                                </div>
                            </div>

                            {/* Physical Exam Section with Sidebar on the Right */}
                            <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                                <div className={`flex-grow transition-width duration-300`}>
                                    <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                        <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                            <div className="font-bold text-slate-700 font-sans text-xl pl-2">Physical Exam</div>
                                            <div className="flex justify-around">
                                                <Fieldset
                                                    className="rounded p-2 cursor-pointer"
                                                    onClick={() => { saveDocument('physical_exam'); }}
                                                >
                                                    Save
                                                    <FontAwesomeIcon icon={faSave} className="text-indigo-700 ml-2 mr-4" />
                                                </Fieldset>
                                                {copiedExam ? (
                                                    <Fieldset
                                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                        onClick={() => copyToClipboard(exam, 'physical_exam')}>
                                                        Copied!
                                                        <FontAwesomeIcon icon={faCheck} className="text-indigo-700 ml-2" />
                                                    </Fieldset>
                                                ) : (
                                                    <Fieldset
                                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                        onClick={() => copyToClipboard(exam, 'physical_exam')}>
                                                        Copy
                                                        <FontAwesomeIcon icon={faCopy} className="text-indigo-700 ml-2" />
                                                    </Fieldset>
                                                )}
                                            </div>
                                        </Fieldset>
                                        <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                            <TextareaAutosize className="w-full border-none outline-none text-slate-600 whitespace-pre-line overflow-y-auto"
                                                value={exam || ''}
                                                onChange={(e) => updateExam(e.target.value)}
                                                onBlur={() => saveDocument('physical_exam', exam)}>
                                            </TextareaAutosize>
                                        </div>
                                    </Fieldset>
                                </div>
                                <Sidebar
                                    macros={filteredMacros}
                                    onSelectMacro={insertSelectedMacro}
                                    isExpanded={showExamMacros}
                                    toggleSidebar={toggleExamMacros}
                                    position="right"
                                    maxHeight='500px'
                                    section="physical_exam"
                                />
                            </div>
                        </div>


                        <div style={{ display: 'flex', width: '100%', marginBottom: '50px' }}>
                            {/* Evaluations Section */}
                            <Sidebar
                                macros={filteredMacros}
                                onSelectMacro={insertSelectedMacro}
                                isExpanded={showEvaluationsMacros}
                                toggleSidebar={toggleEvaluationsMacros}
                                position='left'
                                maxHeight='500px'
                                section="reevaluation"
                            />
                            <div className={`flex-grow transition-width duration-300 mr-6`}>
                                <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                    <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                        <div className="font-bold text-slate-700 font-sans text-xl pl-2">Assessment and Plan</div>
                                        <div className="flex justify-around">
                                            <Fieldset
                                                className="rounded p-2 cursor-pointer"
                                                onClick={() => { saveDocument('reeval'); }}
                                            >
                                                Save
                                                <FontAwesomeIcon icon={faSave} className="text-indigo-700 ml-2 mr-4" />
                                            </Fieldset>
                                            {copiedReeval ? (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(reeval, 'reeval')}>
                                                    Copied!
                                                    <FontAwesomeIcon icon={faCheck} className="text-indigo-700 ml-2" />
                                                </Fieldset>
                                            ) : (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(reeval, 'reeval')}>
                                                    Copy
                                                    <FontAwesomeIcon icon={faCopy} className="text-indigo-700 ml-2" />
                                                </Fieldset>
                                            )}
                                        </div>
                                    </Fieldset>
                                    <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                        <TextareaAutosize className="w-full border-none outline-none text-slate-600 whitespace-pre-line overflow-y-auto"
                                            value={reeval || ''}
                                            onChange={(e) => updateReeval(e.target.value)}
                                            onBlur={() => saveDocument('reeval', reeval)}>
                                        </TextareaAutosize>
                                    </div>
                                </Fieldset>
                            </div>
                        </div>
                    </>
                );

            case 'PROGRESS_NOTE':
                return (
                    <>
                        <div className="flex" style={{ marginBottom: '25px', minHeight: '175px' }}>
                            <div className={`flex-grow transition-width duration-300 mr-6`}>
                                <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                    <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                        <div className="font-bold text-slate-700 font-sans text-xl pl-2">Subjective</div>
                                        <div className="flex justify-around">
                                            <Fieldset
                                                className="rounded p-2 cursor-pointer"
                                                onClick={() => { saveDocument('subjective'); }}
                                            >
                                                Save
                                                <FontAwesomeIcon icon={faSave} className="text-indigo-700 ml-2 mr-4" />
                                            </Fieldset>
                                            {copiedSubjective ? (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(subjective, 'subjective')}>
                                                    Copied!
                                                    <FontAwesomeIcon icon={faCheck} className="text-indigo-700 ml-2" />
                                                </Fieldset>
                                            ) : (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(subjective, 'subjective')}>
                                                    Copy
                                                    <FontAwesomeIcon icon={faCopy} className="text-indigo-700 ml-2" />
                                                </Fieldset>
                                            )}
                                        </div>
                                    </Fieldset>
                                    <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                        <TextareaAutosize
                                            className="w-full border-none outline-none text-slate-600 resize-none whitespace-pre-line overflow-y-auto"
                                            value={subjective || ''}
                                            onChange={(e) => updateSubjective(e.target.value)}
                                            onBlur={() => saveDocument('subjective', subjective)}>
                                        </TextareaAutosize>
                                    </div>
                                </Fieldset>
                            </div>
                        </div>

                        <div className="flex" style={{ marginBottom: '25px', minHeight: '175px' }}>
                            <div className={`flex-grow transition-width duration-300 mr-6`}>
                                <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                    <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                        <div className="font-bold text-slate-700 font-sans text-xl pl-2">Objective</div>
                                        <div className="flex justify-around">
                                            <Fieldset
                                                className="rounded p-2 cursor-pointer"
                                                onClick={() => { saveDocument('objective'); }}
                                            >
                                                Save
                                                <FontAwesomeIcon icon={faSave} className="text-indigo-700 ml-2 mr-4" />
                                            </Fieldset>
                                            {copiedObjective ? (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(objective, 'objective')}>
                                                    Copied!
                                                    <FontAwesomeIcon icon={faCheck} className="text-indigo-700 ml-2" />
                                                </Fieldset>
                                            ) : (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(objective, 'objective')}>
                                                    Copy
                                                    <FontAwesomeIcon icon={faCopy} className="text-indigo-700 ml-2" />
                                                </Fieldset>
                                            )}
                                        </div>
                                    </Fieldset>
                                    <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                        <TextareaAutosize
                                            className="w-full border-none outline-none text-slate-600 resize-none whitespace-pre-line overflow-y-auto"
                                            value={objective || ''}
                                            onChange={(e) => updateObjective(e.target.value)}
                                            onBlur={() => saveDocument('objective', objective)}>
                                        </TextareaAutosize>
                                    </div>
                                </Fieldset>
                            </div>
                        </div>

                        <div className="flex" style={{ marginBottom: '25px', minHeight: '175px' }}>
                            <div className={`flex-grow transition-width duration-300 mr-6`}>
                                <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                    <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                        <div className="font-bold text-slate-700 font-sans text-xl pl-2">Assessment and Plan</div>
                                        <div className="flex justify-around">
                                            <Fieldset
                                                className="rounded p-2 cursor-pointer"
                                                onClick={() => { saveDocument('assessment_and_plan'); }}
                                            >
                                                Save
                                                <FontAwesomeIcon icon={faSave} className="text-indigo-700 ml-2 mr-4" />
                                            </Fieldset>
                                            {copiedAssessmentAndPlan ? (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(assessmentAndPlan, 'assessment_and_plan')}>
                                                    Copied!
                                                    <FontAwesomeIcon icon={faCheck} className="text-indigo-700 ml-2" />
                                                </Fieldset>
                                            ) : (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(assessmentAndPlan, 'assessment_and_plan')}>
                                                    Copy
                                                    <FontAwesomeIcon icon={faCopy} className="text-indigo-700 ml-2" />
                                                </Fieldset>
                                            )}
                                        </div>
                                    </Fieldset>
                                    <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                        <TextareaAutosize
                                            className="w-full border-none outline-none text-slate-600 resize-none whitespace-pre-line overflow-y-auto"
                                            value={assessmentAndPlan || ''}
                                            onChange={(e) => updateAssessmentAndPlan(e.target.value)}
                                            onBlur={() => saveDocument('assessment_and_plan', assessmentAndPlan)}>
                                        </TextareaAutosize>
                                    </div>
                                </Fieldset>
                            </div>
                        </div>
                    </>
                );

            case 'DISCHARGE_SUMMARY':
                return (
                    <div className="flex" style={{ marginBottom: '25px', minHeight: '175px' }}>
                        <div className={`flex-grow transition-width duration-300 mr-6`}>
                            <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '750px', overflowY: 'auto' }}>
                                <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                    <div className="font-bold text-slate-700 font-sans text-xl pl-2">Discharge Summary</div>
                                    <div className="flex justify-around">
                                        <Fieldset
                                            className="rounded p-2 cursor-pointer"
                                            onClick={() => { saveDocument('hospitalist_discharge_summary'); }}
                                        >
                                            Save
                                            <FontAwesomeIcon icon={faSave} className="text-indigo-700 ml-2 mr-4" />
                                        </Fieldset>
                                        {copiedDischargeSummary ? (
                                            <Fieldset
                                                className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                onClick={() => copyToClipboard(dischargeSummary, 'hospitalist_discharge_summary')}>
                                                Copied!
                                                <FontAwesomeIcon icon={faCheck} className="text-indigo-700 ml-2" />
                                            </Fieldset>
                                        ) : (
                                            <Fieldset
                                                className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                onClick={() => copyToClipboard(dischargeSummary, 'hospitalist_discharge_summary')}>
                                                Copy
                                                <FontAwesomeIcon icon={faCopy} className="text-indigo-700 ml-2" />
                                            </Fieldset>
                                        )}
                                    </div>
                                </Fieldset>
                                <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                    <TextareaAutosize
                                        className="w-full border-none outline-none text-slate-600 resize-none whitespace-pre-line overflow-y-auto"
                                        value={dischargeSummary || ''}
                                        onChange={(e) => updateDischargeSummary(e.target.value)}
                                        onBlur={() => saveDocument('hospitalist_discharge_summary', dischargeSummary)}>
                                    </TextareaAutosize>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                );

            default:
                return <div>Unsupported document type</div>;
        }
    };

    const formatHospitalistDocumentType = (type) => {
        switch (type) {
            case 'ADMISSION_NOTE':
                return 'Admission Note';
            case 'PROGRESS_NOTE':
                return 'Progress Note';
            case 'DISCHARGE_SUMMARY':
                return 'Discharge Summary';
            default:
                return type;
        }
    };







    return (
        <div className="max-w-7xl mx-auto px-5">
            {showNotification && (
                <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
                    <div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-green-800">Successfully saved</p>
                            </div>
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                        onClick={() => setShowNotification(false)}
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showErrorNotification && (
                <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
                    <div className="rounded-md bg-red-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">Error</h3>
                                <div className="mt-2 text-sm text-red-700">
                                    <p>{errorMessage}</p>
                                </div>
                            </div>
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                                        onClick={() => setShowErrorNotification(false)}
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Document Details Section as a Horizontal Header */}
            <div className="header-container mb-16 mt-6 w-full">
                {/* Header Row with Go Back Button and Documentation Heading */}
                <div className="header-row flex justify-between items-center">
                    {/* Go Back Button */}
                    <button
                        onClick={goBack}
                        className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500">

                        <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                        Back to Dashboard
                    </button>
                    {/* <button
                        type="button"
                        className="justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => goBack}
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                        Back to Dashboard
                    </button> */}
                    {document && document.hospitalist_document_type === 'ADMISSION_NOTE' && (
                        <>
                            <div ref={riskScoreDropdownRef} className="relative inline-block text-left">
                                <div>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500"
                                        id="menu-button"
                                        aria-expanded="true"
                                        aria-haspopup="true"
                                        onClick={() => setShowRiskScoreDropdown(!showRiskScoreDropdown)}
                                    >
                                        Calculate Risk Score
                                        <FontAwesomeIcon icon={faChartLine} className="ml-2 mt-1" />
                                    </button>
                                </div>

                                {showRiskScoreDropdown && (
                                    <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                        <div className="py-1" role="none">
                                            {riskScores.map((score, index) => (
                                                <button
                                                    key={index}
                                                    type="button"
                                                    className="text-gray-700 block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 hover:text-indigo-700"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id={`menu-item-${index}`}
                                                    onClick={() => calculateSelectedRiskScore(score)}
                                                >
                                                    {score}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="relative inline-block text-left">
                                <div>
                                    {(isLoadingMDM || documentStatus === 'IN_PROGRESS') ? (
                                        <div className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-500 text-sm font-medium text-white">
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Generating MDM...
                                        </div>
                                    ) : isShowingMDM ? (
                                        <button
                                            type="button"
                                            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => { setIsShowingMDM(false); setMDMCodingNotificationVisible(false); }}
                                        >
                                            Back to Documentation
                                        </button>
                                    ) : mdm !== null ? (
                                        <button
                                            type="button"
                                            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => { setIsShowingMDM(true); setMDMCodingNotificationVisible(true); setRiskScoreNotificationVisible(false); setRiskAnalysisNotificationVisible(false); }}
                                        >
                                            View MDM
                                            <FontAwesomeIcon icon={faCalculator} className="ml-2 mt-1" />
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => calculateMDM()}
                                        >
                                            Calculate MDM
                                            <FontAwesomeIcon icon={faCalculator} className="ml-2 mt-1" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {/* Left Placeholder for balancing */}
                    {/* <div className="header-placeholder"></div> */}

                    {/* Info Button */}
                    <div className="button-group flex">
                        <Button type="button" color="zinc" onClick={toggleDialog} className="mr-2">
                            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
                        </Button>

                        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} size="xl">
                            <DialogTitle>Cleo-Generated Note Information</DialogTitle>
                            {/* <DialogDescription>This is a detailed description or information you want to show.</DialogDescription> */}
                            <DialogBody>
                                <p>
                                    This page contains your Cleo-generated HPI, Review of Systems, Physical Exam, and Assessment and Plan from the recorded audio of your patient encounter.
                                </p>
                                <p>&nbsp;</p> {/* Extra space between paragraphs */}
                                <p>&nbsp;</p> {/* Extra space before the final paragraph */}
                                <p>
                                    Clicking the 'Archive' button will remove the note from your dashboard.
                                </p>
                            </DialogBody>
                            <DialogActions>
                                <button plain onClick={() => setIsDialogOpen(false)} className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500">
                                    Close
                                </button>
                                {/* Add more actions if needed */}
                            </DialogActions>
                        </Dialog>

                        <button
                            color="bg-indigo-600"
                            onClick={approveDocument}
                            className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500">
                            Archive
                            <FontAwesomeIcon icon={faCheck} className="ml-2" />
                        </button>
                    </div>
                </div>


                {/* Document Details Row */}
                {document && (
                    <div className="document-details-card mt-4 bg-gray-100">
                        {/* Patient Detail */}
                        <div className="document-detail" onDoubleClick={() => setIsEditingPatientName(true)}>
                            <Text className="detail-heading">Patient Name:</Text>
                            {isEditingPatientName ? (
                                <input
                                    type="text"
                                    className="detail-content"
                                    value={editedDocument.patient_name}
                                    onChange={(e) => updatePatientName(e.target.value)}
                                    onBlur={() => {
                                        setIsEditingPatientName(false);
                                        saveDocument('patient_name');
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            saveDocument('patient_name');
                                            setIsEditingPatientName(false);
                                        }
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <>
                                    <Text className="detail-content">{patientName}</Text>
                                </>
                            )}
                        </div>

                        {/* Encounter Date Detail */}
                        <div className="document-detail">
                            <Text className="detail-heading">Created At:</Text>
                            <Text className="detail-content">{formatDate(document.created_at)} at {formatTime(document.created_at)}</Text>
                        </div>

                        {/* Visit Description Detail
                        <div className="document-detail" onDoubleClick={() => setIsEditingProcedureDescription(true)}>
                            <Text className="detail-heading">Chief Complaint:</Text>
                            {isEditingProcedureDescription ? (
                                <input
                                    type="text"
                                    className="detail-content"
                                    value={editedDocument.procedure_description}
                                    onChange={(e) => updateProcedureDescription(e.target.value)}
                                    onBlur={() => {
                                        setIsEditingProcedureDescription(false);
                                        saveDocument('procedure_description');
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            saveDocument('procedure_description');
                                            setIsEditingProcedureDescription(false);
                                        }
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <>
                                    <Text className="detail-content">{procedureDescription}</Text>
                                </>
                            )}
                        </div> */}
                        {/* Hospitalist Note Type Detail */}
                        <div className="document-detail">
                            <Text className="detail-heading">Note Type:</Text>
                            <Text className="detail-content">
                                {document && document.hospitalist_document_type
                                    ? formatHospitalistDocumentType(document.hospitalist_document_type)
                                    : 'N/A'}
                            </Text>
                        </div>
                    </div>
                )}
                <div className="header-container mb-16 mt-6 w-full">
                    <div className="header-row flex justify-between items-center px-4">
                        {/* Placeholder for alignment, visibility controlled by CSS */}
                        <div className="flex-grow">
                            {isShowingMDM && isMDMRecodeEnabled && (
                                <button onClick={codeMDM} className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500">
                                    Re-Generate MDM Scores
                                    <FontAwesomeIcon icon={faCalculator} className="ml-2" />
                                </button>
                            )}
                        </div>

                        {/* Feedback Request Section, centered */}
                        <div className="flex-grow-0 ml-32">
                            {showRatingButtons & noteFeedbackNotificationVisible ? (
                                <div className="flex flex-col items-center">
                                    <p className="text-sm font-medium">Help us improve. How did Cleo do on this note?</p>
                                    <div className="flex space-x-4">
                                        <button onClick={() => handleRatingUpdate('good')} className="text-white px-4 py-2 rounded">
                                            👍
                                        </button>
                                        <button onClick={() => handleRatingUpdate('medium')} className="text-white px-4 py-2 rounded">
                                            😐
                                        </button>
                                        <button onClick={() => handleRatingUpdate('bad')} className="text-white px-4 py-2 rounded">
                                            👎
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="relative w-full">
                                    {!feedbackSubmitted && noteFeedbackNotificationVisible ? (
                                        <>
                                            <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                                                How could this note improve?
                                            </label>

                                            <textarea
                                                rows={4}
                                                name="comment"
                                                id="comment"
                                                className="block w-[200%] ml-[-50%] mr-[-50%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                                placeholder="What would you improve about this note?"
                                                value={noteFeedback}
                                                onChange={(e) => setNoteFeedback(e.target.value)}
                                            />
                                            <div className="absolute bottom-[-50] right-[-50%] pb-2 pr-2">
                                                <button
                                                    onClick={submitNoteFeedback}
                                                    className={`inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${!noteFeedback ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                    disabled={!noteFeedback}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </>
                                    ) : noteFeedbackNotificationVisible ? (
                                        <div className="flex flex-col items-center justify-center flex-grow">
                                            <CheckCircleIcon className="h-10 w-10 text-indigo-500" />
                                            <p className="text-indigo-500 font-bold mt-2 text-center">Thank you for the feedback!</p>
                                        </div>
                                    ) : null}
                                </div>

                            )}
                        </div>


                        {/* Copy All Button, alignment controlled by flex-grow */}
                        <div className="flex-grow text-right">
                            {copiedAll ? (
                                <button className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500">
                                    Copied!
                                    <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                </button>
                            ) : (
                                <button onClick={copyAllToClipboard} className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500">
                                    Copy All
                                    <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>

            </div>

            {/* Clinical Note Section */}
            {isLoading ? (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="loading-text">{loadingMessage}</div>
                </div>
            ) : isShowingMDM ? (
                <div className="flex flex-col justify-center mt-8">
                    {/* MDM Section */}
                    <Fieldset className="flex flex-col w-full bg-white shadow-lg rounded-lg overflow-hidden mb-6">
                        <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                            <div className="font-bold text-slate-700 font-sans text-xl pl-2">Medical Decision-Making Documentation</div>
                            <div className="flex justify-around">
                                <button
                                    className={`rounded-md text-indigo-700 px-3 py-1 text-sm hover:text-indigo-500 focus:outline-none mr-2 ${isRemoved && mdm !== originalMDM ? 'cursor-not-allowed opacity-50' : ''}`}
                                    onClick={toggleBracketedText}
                                    disabled={isRemoved && mdm !== cleanedMDM}
                                >
                                    {isRemoved ? 'Undo Remove' : 'Remove Bracketed Lines'}
                                </button>
                                <Fieldset
                                    className="rounded p-2 cursor-pointer"
                                    onClick={() => { saveDocument('mdm'); }}
                                >
                                    Save
                                    <FontAwesomeIcon icon={faSave} className="text-indigo-700 ml-2 mr-4" />
                                </Fieldset>
                                {copiedMDM ? (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(mdm, 'mdm')}>
                                        Copied!
                                        <FontAwesomeIcon icon={faCheck} className="text-indigo-700 ml-2" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(mdm, 'mdm')}>
                                        Copy
                                        <FontAwesomeIcon icon={faCopy} className="text-indigo-700 ml-2" />
                                    </Fieldset>
                                )}
                            </div>
                        </Fieldset>
                        <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                            <textarea className="constant-size-textarea w-full border-none outline-none text-slate-600 whitespace-pre-line overflow-y-auto"
                                value={mdm || ''}
                                onChange={(e) => updateMDM(e.target.value)}
                                onBlur={() => saveDocument('mdm', mdm)}
                            >
                                {mdm || ''}
                            </textarea>
                        </div>
                    </Fieldset>

                    {/* ICD-10 Codes Section */}
                    <Fieldset className="flex flex-col w-full bg-white shadow-lg rounded-lg overflow-hidden mb-20">
                        <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                            <div className="font-bold text-slate-700 font-sans text-xl pl-2">ICD-10 Codes (Beta)</div>
                            <div className="flex justify-around">
                                <Fieldset
                                    className="rounded p-2 cursor-pointer"
                                    onClick={() => { saveDocument('code_suggestions'); }}
                                >
                                    Save
                                    <FontAwesomeIcon icon={faSave} className="text-indigo-700 ml-2 mr-4" />
                                </Fieldset>
                                {copiedICD10 ? (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(icd10Codes, 'icd10_codes')}>
                                        Copied!
                                        <FontAwesomeIcon icon={faCheck} className="text-indigo-700 ml-2" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(icd10Codes, 'icd10_codes')}>
                                        Copy
                                        <FontAwesomeIcon icon={faCopy} className="text-indigo-700 ml-2" />
                                    </Fieldset>
                                )}
                            </div>
                        </Fieldset>
                        <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto relative" style={{ minHeight: '100px' }}>
                            {isLoadingICD10 ? (
                                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                                    <div className="spinner"></div>
                                    <div className="ml-3 text-indigo-600 font-semibold">Generating ICD-10 Codes</div>
                                </div>
                            ) : icd10Codes ? (
                                <textarea
                                    className="constant-size-textarea w-full border-none outline-none text-slate-600 whitespace-pre-line overflow-y-auto"
                                    value={icd10Codes}
                                    onChange={(e) => updateICD10Codes(e.target.value)}
                                    onBlur={() => saveDocument('icd10_codes', icd10Codes)}
                                >
                                    {icd10Codes}
                                </textarea>
                            ) : (
                                <div className="flex justify-center items-center h-full">
                                    <button
                                        onClick={generateICD10Codes}
                                        className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Generate ICD-10 Codes
                                    </button>
                                </div>
                            )}
                        </div>
                    </Fieldset>
                </div>
            ) : (
                <div>
                    {renderDocumentContent()}
                </div>
            )
            }
            {/* Notification Card for Risk Score */}
            {
                riskScoreNotificationVisible && !mdmCodingNotificationVisible && (
                    <Fieldset className="fixed bottom-8 right-8 border border-gray-300 shadow-lg rounded-lg overflow-hidden h-[90vh] md:h-[600px] w-full md:w-[400px] flex flex-col">
                        <div className="flex justify-between items-center bg-indigo-500 text-white py-2">
                            <div className="font-bold text-lg text-center flex-grow">
                                {riskScoreLabel}
                            </div>
                            <button
                                onClick={() => setRiskScoreNotificationVisible(false)}
                                className="mr-4">
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="bg-white p-2 pt-2 px-0 flex-grow overflow-auto max-h-[calc(100vh-8rem)]">
                            {riskScoreConfigurations[currentRiskScoreKey].map((category) => (
                                <RiskScoreOptions
                                    key={category.key}
                                    label={category.label}
                                    options={category.options}
                                    selectedValue={riskScore[category.key]}
                                    onChange={(value) => handleRiskScoreChange(category.key, value)}
                                />
                            ))}
                            <div className="bg-white p-4 flex flex-col">
                                {evaluateRiskScore(riskScore, currentRiskScoreKey).isComplete ? (
                                    evaluateRiskScore(riskScore, currentRiskScoreKey).advisories.map((advisory, index) => (
                                        <p key={index} className="mt-2">{advisory}</p>
                                    ))
                                ) : (
                                    <p className="flex items-center text-yellow-500 mt-2">
                                        <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                                        Complete items above to calculate {riskScoreLabel} score
                                    </p>
                                )}
                                <div className="flex justify-center items-center mt-4">
                                    {/* Assuming you've implemented copyToClipboard function */}
                                    <button
                                        onClick={updateReevaluationWithSummary}
                                        className={`bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-2 rounded ${!evaluateRiskScore(riskScore, currentRiskScoreKey).isComplete ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={!evaluateRiskScore(riskScore, currentRiskScoreKey).isComplete}>
                                        Copy and Save to Evaluations
                                    </button>

                                    {/* <button
                                    onClick={() => setRiskScoreNotificationVisible(false)}
                                    className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-2 rounded">
                                    Close
                                </button> */}
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                )
            }
            {/* Notification Card for MDM Coding */}
            {
                isShowingMDM && !riskScoreNotificationVisible && !riskAnalysisNotificationVisible && (
                    <div className="fixed top-24 right-4">
                        {mdmCodingNotificationVisible ? (
                            <div className="h-[60vh] md:h-[300px] w-full md:w-[200px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white transition-all">
                                <div className="flex justify-between items-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white py-2 pl-4 pr-2">
                                    <div className="font-bold text-sm text-center flex-grow cursor-pointer" onClick={() => setMDMCodingNotificationVisible(true)}>
                                        Cleo MDM Assistant
                                    </div>
                                    <button onClick={() => setMDMCodingNotificationVisible(false)} className="p-1 rounded-full bg-white text-indigo-500 hover:text-indigo-600 transition-all">
                                        <XMarkIcon className="h-5 w-5" />
                                    </button>
                                </div>
                                <div className="bg-white p-2 pt-2 px-0 flex-grow overflow-auto max-h-[calc(60vh-4rem)]">
                                    <div className="mt-2 p-2 rounded-lg shadow">
                                        <ProgressBar value={copa} title="Complexity of Problems Addressed" />
                                        <ProgressBar value={complexityOfData} title="Complexity of Data" />
                                        <ProgressBar value={riskOfComplications} title="Risk of Complications" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="h-12 w-12 bg-gradient-to-r from-indigo-500 to-indigo-600 rounded-full flex items-center justify-center cursor-pointer shadow-lg hover:shadow-xl transition-all" onClick={() => setMDMCodingNotificationVisible(true)}>
                                <ShieldCheckIcon className="h-6 w-6 text-white" />
                            </div>
                        )}
                    </div>
                )
            }
        </div >
    );
}

export default HospitalistChart;
