import React from 'react';
import { useNavigate } from 'react-router-dom';

const TermsOfUse = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // This will take the user back to the previous page
    };

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <button onClick={goBack} style={{
                margin: '10px 0',
                padding: '10px 20px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                outline: 'none',
                fontSize: '16px'
            }}>
                Go Back
            </button>
            <embed src="/terms_of_use.pdf" width="100%" height="600px" style={{ display: 'block' }} />
        </div>
    );
};

export default TermsOfUse;
