import React, { useMemo } from 'react';
import ApexChart from 'react-apexcharts';

const AllBlockProgressChart = ({ blocksData }) => {
    const chartSeries = useMemo(() => {
        return Object.keys(blocksData).map((block) => blocksData[block].total);
    }, [blocksData]);

    const chartOptions = useMemo(() => {
        return {
            chart: {
                type: 'donut',
                toolbar: {
                    show: false
                }, 
            },
            labels: Object.keys(blocksData),
            colors: Object.keys(blocksData).map((_, index) => index % 2 === 0 ? '#0d324d' : '#7f5a83'), // Alternate colors for each block
            legend: {
                show: false
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    const block = blocksData[opts.w.config.labels[opts.seriesIndex]];
                    const pct = 100 * (block.progress / block.total);
                    return `${pct.toFixed(0)}%`;
                },
                style: {
                    colors: ['#fff'],
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                },
                dropShadow: {
                    enabled: false
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '40%', // Adjust the size to control the thickness of the donut
                        labels: {
                            show: true,
                            total: {
                                show: true, // Set this to false to hide the "Total:" title
                                label: 'Total',
                                formatter: function (w) {
                                    const total = Object.values(blocksData).reduce((a, b) => a + b.total, 0);
                                    const progress = Object.values(blocksData).reduce((a, b) => a + b.progress, 0);
                                    const percentage = (progress / total) * 100;
                                    return `${percentage.toFixed(0)}%`;
                                }, 
                                offsetY: -10,
                            }
                        }
                    },
                    expandOnClick: false,
                    dataLabels: {
                        offset: 0
                    }
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            tooltip: {
                enabled: true,
                y: {
                    formatter: function (val, { seriesIndex }) {
                        const blockKey = Object.keys(blocksData)[seriesIndex];
                        const block = blocksData[blockKey];
                        return `${block.progress} / ${block.total}`;
                    }
                }
            }      
            
        };
    }, [blocksData]);

    return (
        <div className="flex justify-center items-center w-full h-full">
            <ApexChart options={chartOptions} series={chartSeries} type="donut" />
        </div>
    );
};

export default AllBlockProgressChart;
