import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/catalyst/button";
import {
  Fieldset,
  Legend,
  FieldGroup,
} from "../components/catalyst/fieldset";
import { Field, Label, Switch, Description } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SignUp = () => {
  const navigate = useNavigate();
  const [agreed, setAgreed] = useState(false);
  const [deidentify, setDeidentify] = useState(false);

  const handleCheckboxChange = () => {
    setAgreed(!agreed);
  };

  const getGoogleSignUpUrl = () => {
    return deidentify
      ? "https://cleob2c.b2clogin.com/login.cleo-ai.co/oauth2/v2.0/authorize?p=B2C_1A_SIGNUPD2C_PROD_GOOGLE_DEIDENTIFY&client_id=4cb44d8b-49ff-48fb-bb64-af2469405893&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.cleohealth.io%2Fprocess-signup&scope=openid&response_type=id_token&prompt=login"
      : "https://cleob2c.b2clogin.com/login.cleo-ai.co/oauth2/v2.0/authorize?p=B2C_1A_SIGNUPD2C_PROD_GOOGLE&client_id=4cb44d8b-49ff-48fb-bb64-af2469405893&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.cleohealth.io%2Fprocess-signup&scope=openid&response_type=id_token&prompt=login";
  };

  const getMicrosoftSignUpUrl = () => {
    return deidentify
      ? "https://cleob2c.b2clogin.com/login.cleo-ai.co/oauth2/v2.0/authorize?p=B2C_1A_SIGNUPD2C_PROD_MICROSOFT_ORGANIZATION_DEIDENTIFY&client_id=4cb44d8b-49ff-48fb-bb64-af2469405893&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.cleohealth.io%2Fprocess-signup&scope=openid&response_type=id_token&prompt=login"
      : "https://cleob2c.b2clogin.com/login.cleo-ai.co/oauth2/v2.0/authorize?p=B2C_1A_SIGNUPD2C_PROD_MICROSOFT_ORGANIZATION&client_id=4cb44d8b-49ff-48fb-bb64-af2469405893&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.cleohealth.io%2Fprocess-signup&scope=openid&response_type=id_token&prompt=login";
  };

  const getAppleSignUpUrl = () => {
    return "https://cleob2c.b2clogin.com/login.cleo-ai.co/oauth2/v2.0/authorize?p=B2C_1A_SIGNUPD2C_PROD_APPLE&client_id=4cb44d8b-49ff-48fb-bb64-af2469405893&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.cleohealth.io%2Fprocess-signup&scope=openid&response_type=id_token&prompt=login";
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-screen">
        <div className="max-w-2xl w-full bg-white shadow-lg rounded-lg p-12">
          <div className="mb-12">
            <img
              src="/cleo-logo.png"
              alt="Cleo Logo"
              style={{ maxWidth: "50px", marginBottom: "30px" }}
            />
            <Fieldset>
              <Legend
                className="text-black pb-4"
                style={{ fontSize: "28px" }}
              >
                Try Cleo Health For Free
              </Legend>
              <p className="text-black text-left">
                No credit card required!
              </p>
              <FieldGroup className="flex flex-col items-center">
                <Button
                  onClick={() => window.location.href = getGoogleSignUpUrl()}
                  color="black"
                  className="self-center  w-3/4 flex items-center justify-center hover:bg-[#0B98A8]"
                  disabled={!agreed}
                  style={{ fontSize: "18px", padding: "10px 20px" }}
                >
                  <img
                    src="/Google-logo.png"
                    alt="Google Logo"
                    style={{ width: "24px", marginRight: "10px" }}
                  />
                  Sign Up with Google
                </Button>
                <Button
                  onClick={() => window.location.href = getMicrosoftSignUpUrl()}
                  color="black"
                  className="self-center mt-4 w-3/4 flex items-center justify-center hover:bg-[#0B98A8]"
                  disabled={!agreed}
                  style={{ fontSize: "18px", padding: "10px 20px" }}
                >
                  <img
                    src="/Microsoft-logo.png"
                    alt="Microsoft Logo"
                    style={{ width: "24px", marginRight: "10px" }}
                  />
                  Sign Up with Microsoft
                </Button>
                <Button
                  onClick={() => window.location.href = getAppleSignUpUrl()}
                  color="black"
                  className="self-center mt-4 w-3/4 flex items-center justify-center hover:bg-[#0B98A8]"
                  disabled={!agreed}
                  style={{ fontSize: "18px", padding: "10px 20px" }}
                >
                  <img
                    src="/Apple-logo.png"
                    alt="Apple Logo"
                    style={{ width: "24px", marginRight: "10px" }}
                  />
                  Sign Up with Apple
                </Button>
              </FieldGroup>
            </Fieldset>
          </div>
          <div className="text-left text-black mb-8 flex items-start">
            <input
              type="checkbox"
              id="terms"
              checked={agreed}
              onChange={handleCheckboxChange}
              className="h-6 w-6 rounded border-gray-300 text-indigo-900 focus:ring-indigo-900 mr-2"
            />
            <label htmlFor="terms" className="text-lg">
              I agree to Cleo Health's{" "}
              <a
                className="text-indigo-900"
                href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Terms_of_Use.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
              ,{" "}
              <a
                className="text-indigo-900"
                href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Privacy_Policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              , and{" "}
              <a
                className="text-indigo-900"
                href="https://cleopublic.blob.core.windows.net/website-public/CLEO_Technologies_Business_Associate_Agreement.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                BAA
              </a>
              .
            </label>
          </div>
          {/* <Field as="div" className="flex items-center justify-between mb-8">
            <span className="flex flex-grow flex-col">
              <Label as="span" className="text-lg font-medium leading-6 text-gray-900" passive>
                Deidentify User Data
              </Label>
              <Description as="span" className="text-sm text-gray-500">
                All PHI will be replaced with [PHI Redacted] upon transcription.
              </Description>
            </span>
            <Switch
              checked={deidentify}
              onChange={setDeidentify}
              className={classNames(
                deidentify ? 'bg-indigo-900' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-900 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  deidentify ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
          </Field> */}
        </div>
      </div>
    </>
  );
};

export default SignUp;
