import React, { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useNavigate } from 'react-router-dom';
import '../styles/NoteDashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faClipboardList, faCalendarAlt, faClock, faLightbulb, faUser, faHospital, faPlusCircle, faExchangeAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO } from 'date-fns';

import { Fieldset, Legend } from '../components/catalyst/fieldset';
import { Text } from '../components/catalyst/text';
import { Button } from '../components/catalyst/button';
import TransferPatientModal from '../components/TransferPatientModal';

function HospitalistNoteDashboard() {
    const { instance, accounts, inProgress } = useMsal();
    const [inpatientStays, setInpatientStays] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [loadingProgressNote, setLoadingProgressNote] = useState({});
    const [loadingDischargeSummary, setLoadingDischargeSummary] = useState({});
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [selectedStay, setSelectedStay] = useState(null);
    const [divisionActors, setDivisionActors] = useState([]);
    const [loadingActors, setLoadingActors] = useState(false);
    const [showDischargeAlert, setShowDischargeAlert] = useState(false);
    const [selectedStayForDischarge, setSelectedStayForDischarge] = useState(null);
    const [dischargingStays, setDischargingStays] = useState({});

    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);

    const addNewProgressNote = useCallback(async (stay) => {
        setLoadingProgressNote(prev => ({ ...prev, [stay.inpatient_id]: true }));
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiCreateProgressNote.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        is_android: false
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiCreateProgressNote.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (fetchResponse.ok) {
                    setInpatientStays(prevStays =>
                        prevStays.map(s =>
                            s.inpatient_id === stay.inpatient_id
                                ? { ...s, documents: [data, ...s.documents].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) }
                                : s
                        )
                    );
                } else {
                    console.error('Failed to create progress note:', data);
                }
            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error creating progress note:', error);
            }
        }
        setLoadingProgressNote(prev => ({ ...prev, [stay.inpatient_id]: false }));
    }, [instance, accounts, handleTokenExpiration]);

    const addNewDischargeSummary = useCallback(async (stay) => {
        setLoadingDischargeSummary(prev => ({ ...prev, [stay.inpatient_id]: true }));
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiCreateDischargeSummary.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        is_android: false
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiCreateDischargeSummary.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (fetchResponse.ok) {
                    setInpatientStays(prevStays =>
                        prevStays.map(s =>
                            s.inpatient_id === stay.inpatient_id
                                ? { ...s, documents: [data, ...s.documents].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) }
                                : s
                        )
                    );
                } else {
                    console.error('Failed to create discharge summary:', data);
                }
            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error creating discharge summary:', error);
            }
        }
        setLoadingDischargeSummary(prev => ({ ...prev, [stay.inpatient_id]: false }));
    }, [instance, accounts, handleTokenExpiration]);

    const fetchInpatientStays = useCallback(async () => {
        setIsLoading(true);
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiGetInpatientStays.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };

                const fetchResponse = await fetch(protectedResources.apiGetInpatientStays.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (data.inpatient_stays && data.inpatient_stays.length > 0) {
                    const sortedStays = data.inpatient_stays.sort((a, b) => {
                        return new Date(b.created_at) - new Date(a.created_at);
                    });
                    setInpatientStays(data.inpatient_stays);
                } else {
                    setInpatientStays([]);
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching inpatient stays:', error);
            }
        }
        setIsLoading(false);
    }, [instance, accounts, handleTokenExpiration]);

    useEffect(() => {
        const initializeAndFetch = async () => {
            if (inProgress === InteractionStatus.None) {
                await fetchInpatientStays();
            }
        };

        initializeAndFetch();
    }, [inProgress, fetchInpatientStays]);

    const handleDocumentClick = (stay, document) => {
        navigate('/hospitalist-chart', { state: { inpatientStay: stay, document: document } });
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        try {
            // Attempt to parse the date string
            const date = parseISO(dateString);
            // Check if the parsed date is valid
            if (isNaN(date.getTime())) {
                return 'Invalid Date';
            }
            return format(date, 'MMM d, yyyy h:mm a');
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid Date';
        }
    };

    const sortDocuments = (documents) => {
        return [...documents].sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB - dateA; // Sort in descending order (most recent first)
        });
    };

    const formatDocumentType = (type) => {
        return type.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ');
    };

    const openTransferPatientModal = useCallback((stay) => {
        setSelectedStay(stay);
        setShowTransferModal(true);
        fetchDivisionActors();
    }, []);

    const fetchDivisionActors = useCallback(async () => {
        setLoadingActors(true);
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiGetDivisionActors.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };

                const fetchResponse = await fetch(protectedResources.apiGetDivisionActors.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (data && data.length > 0) {
                    setDivisionActors(data);
                } else {
                    setDivisionActors([]);
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching division actors:', error);
            }
        }
        setLoadingActors(false);
    }, [instance, accounts, handleTokenExpiration]);

    const transferPatient = useCallback(async (newProviderId) => {
        if (accounts.length > 0 && selectedStay) {
            try {
                const request = {
                    scopes: protectedResources.apiTransferPatient.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        inpatient_stay_id: selectedStay.inpatient_id,
                        new_provider_id: newProviderId,
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiTransferPatient.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (data.success) {
                    // Remove the transferred stay from the list
                    setInpatientStays(prevStays => prevStays.filter(stay => stay.inpatient_id !== selectedStay.inpatient_id));
                    setShowTransferModal(false);
                } else {
                    console.error('Failed to transfer patient:', data);
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error transferring patient:', error);
            }
        }
    }, [instance, accounts, handleTokenExpiration, selectedStay]);

    const openDischargeConfirmation = useCallback((stay) => {
        setSelectedStayForDischarge(stay);
        setShowDischargeAlert(true);
    }, []);

    const dischargeAndRemovePatient = useCallback(async (stay) => {
        if (accounts.length > 0 && stay) {
            setDischargingStays(prev => ({ ...prev, [stay.inpatient_id]: true }));
            try {
                const request = {
                    scopes: protectedResources.apiDischargePatient.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiDischargePatient.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (fetchResponse.ok) {
                    setInpatientStays(prevStays => prevStays.filter(s => s.inpatient_id !== stay.inpatient_id));
                } else {
                    console.error('Failed to discharge patient:', data);
                    alert('Failed to discharge patient.');
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error discharging patient:', error);
                alert('Error discharging patient. Please try again.');
            }
            setDischargingStays(prev => ({ ...prev, [stay.inpatient_id]: false }));
        }
    }, [instance, accounts, handleTokenExpiration]);

    return (
        <div className="dashboard-container">
            <div className="w-full py-4 mb-1 rounded-md">
                <Fieldset className="flex flex-row justify-between items-center">
                    <Fieldset className="flex items-center space-x-4">
                        <FontAwesomeIcon icon={faClipboardList} className="text-indigo-600 text-3xl" />
                        <Fieldset>
                            <h1 className="text-2xl font-bold text-indigo-800">Inpatient Dashboard</h1>
                            <p className="text-md text-indigo-600">Manage your active inpatient stays</p>
                        </Fieldset>
                    </Fieldset>
                </Fieldset>
            </div>

            {isLoading ? (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="loading-text">Loading inpatient stays...</div>
                </div>
            ) : (
                inpatientStays.length > 0 ? (
                    <Fieldset className="w-full bg-white rounded-lg">
                        {inpatientStays.map((stay, index) => (
                            <Fieldset key={index} className="bg-white rounded-lg shadow-lg mb-4 p-4">
                                <Fieldset className="flex flex-col">
                                    <Fieldset className="flex justify-between items-center mb-4">
                                        <Fieldset className="flex items-center">
                                            <FontAwesomeIcon icon={faUser} className="mr-2 text-indigo-600 text-xl" />
                                            <Text className="font-bold text-xl text-indigo-800">{stay.patient_name}</Text>
                                        </Fieldset>
                                        <Fieldset className="flex items-center space-x-4">
                                            <Text className="text-sm text-gray-600">
                                                <FontAwesomeIcon icon={faComments} className="mr-1 text-indigo-600" />
                                                <span className="font-semibold">Chief Complaint:</span> {stay.original_chief_complaint}
                                            </Text>
                                            <Text className="text-sm text-gray-600">
                                                <FontAwesomeIcon icon={faClock} className="mr-1 text-indigo-600" />
                                                <span className="font-semibold">Last Updated:</span> {formatDate(stay.last_updated_at)}
                                            </Text>
                                            <Text className="text-sm text-gray-600">
                                                <FontAwesomeIcon icon={faCalendarAlt} className="mr-1 text-indigo-600" />
                                                <span className="font-semibold">Admitted:</span> {formatDate(stay.created_at)}
                                            </Text>
                                        </Fieldset>
                                    </Fieldset>
                                    <Fieldset className="mt-2">
                                        <div className="flex justify-between items-center mb-2">
                                            <Text className="font-semibold text-indigo-700">Charts:</Text>
                                            <div className="flex space-x-2">
                                                {!stay.documents.some(doc => doc.hospitalist_document_type === 'DISCHARGE_SUMMARY') && (
                                                    <>
                                                        <button
                                                            onClick={() => addNewProgressNote(stay)}
                                                            disabled={loadingProgressNote[stay.inpatient_id] || loadingDischargeSummary[stay.inpatient_id]}
                                                            className="bg-indigo-600 text-white px-3 py-1 text-sm rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                                        >
                                                            {loadingProgressNote[stay.inpatient_id] ? (
                                                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                            ) : (
                                                                <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
                                                            )}
                                                            Progress Note
                                                        </button>
                                                        <button
                                                            onClick={() => addNewDischargeSummary(stay)}
                                                            disabled={loadingProgressNote[stay.inpatient_id] || loadingDischargeSummary[stay.inpatient_id]}
                                                            className="bg-indigo-600 text-white px-3 py-1 text-sm rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                                        >
                                                            {loadingDischargeSummary[stay.inpatient_id] ? (
                                                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                            ) : (
                                                                <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
                                                            )}
                                                            Discharge Summary
                                                        </button>
                                                    </>
                                                )}
                                                <button
                                                    onClick={() => openTransferPatientModal(stay)}
                                                    className="bg-indigo-600 text-white px-3 py-1 text-sm rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    <FontAwesomeIcon icon={faExchangeAlt} className="mr-1" />
                                                    Transfer Patient
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        if (window.confirm(`Are you sure you want to discharge and remove ${stay.patient_name}?`)) {
                                                            dischargeAndRemovePatient(stay);
                                                        }
                                                    }}
                                                    className="bg-red-600 text-white px-3 py-1 text-sm rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                    disabled={dischargingStays[stay.inpatient_id]}
                                                >
                                                    {dischargingStays[stay.inpatient_id] ? (
                                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                    ) : (
                                                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
                                                    )}
                                                    Discharge and Remove Patient
                                                </button>
                                            </div>
                                        </div>
                                        <Fieldset className="border-t border-gray-200 pt-2">
                                            {sortDocuments(stay.documents).map((doc, docIndex) => (
                                                <Fieldset
                                                    key={docIndex}
                                                    className="flex justify-between items-center py-2 border-b border-gray-100 hover:bg-gray-50 cursor-pointer"
                                                    onClick={() => handleDocumentClick(stay, doc)}
                                                >
                                                    <Text className="text-sm font-medium text-gray-700">
                                                        {formatDocumentType(doc.hospitalist_document_type)}
                                                    </Text>
                                                    <Text className="text-xs text-gray-500">
                                                        {formatDate(doc.created_at)}
                                                    </Text>
                                                </Fieldset>
                                            ))}
                                        </Fieldset>
                                    </Fieldset>
                                </Fieldset>
                            </Fieldset>
                        ))}
                    </Fieldset>
                ) : (
                    <Fieldset className="w-full flex flex-col items-center justify-center h-full">
                        <Legend>No Active Inpatient Stays</Legend>
                        <Text className="mt-4 text-center">
                            There are no active inpatient stays at the moment.
                        </Text>
                    </Fieldset>
                )
            )}

            <div className="feedback-link">
                <a
                    data-canny-link
                    href="https://cleo-health.canny.io"
                    target="_blank"
                    className="inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <FontAwesomeIcon icon={faLightbulb} className="mr-2" />
                    Request a Feature
                </a>
            </div>

            <TransferPatientModal
                isOpen={showTransferModal}
                onClose={() => setShowTransferModal(false)}
                stay={selectedStay}
                divisionActors={divisionActors}
                isLoading={loadingActors}
                onTransfer={transferPatient}
            />

            {/* {showDischargeAlert && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
                    <div className="bg-white p-5 rounded-lg shadow-lg">
                        <p className="mb-4">Are you sure you want to discharge and remove {selectedStayForDischarge?.patient_name}?</p>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setShowDischargeAlert(false)}
                                className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
                                disabled={isDischarging}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={dischargeAndRemovePatient}
                                className="px-4 py-2 bg-red-600 text-white rounded"
                                disabled={isDischarging}
                            >
                                {isDischarging ? 'Discharging...' : 'Confirm'}
                            </button>
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    );
}

export default HospitalistNoteDashboard;



