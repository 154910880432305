import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function TransferPatientModal({ isOpen, onClose, stay, divisionActors, isLoading, onTransfer }) {
    const [selectedActor, setSelectedActor] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Transfer {stay?.patient_name}</h3>
                    <div className="mt-2 px-7 py-3">
                        {isLoading ? (
                            <p>Loading Providers...</p>
                        ) : divisionActors.length === 0 ? (
                            <p className="text-red-500">No other providers available</p>
                        ) : (
                            <div className="mt-4">
                                <p className="text-sm text-gray-500 mb-4">Tap on a provider to transfer the patient</p>
                                {divisionActors.map((actor) => (
                                    <button
                                        key={actor.actor_id}
                                        className="w-full text-left p-2 hover:bg-gray-100 rounded flex justify-between items-center"
                                        onClick={() => {
                                            setSelectedActor(actor);
                                            setShowConfirmation(true);
                                        }}
                                    >
                                        <div>
                                            <p className="font-semibold">{actor.full_name}</p>
                                            <p className="text-sm text-gray-500">{actor.email}</p>
                                        </div>
                                        <FontAwesomeIcon icon={faChevronRight} className="text-blue-500" />
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="items-center px-4 py-3">
                        <button
                            id="ok-btn"
                            className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-60">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Transfer</h3>
                        <p className="mb-4">Are you sure you want to transfer {stay?.patient_name} to {selectedActor?.full_name}?</p>
                        <div className="flex justify-end space-x-2">
                            <button
                                className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
                                onClick={() => setShowConfirmation(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-4 py-2 bg-blue-500 text-white rounded"
                                onClick={() => {
                                    onTransfer(selectedActor.actor_id);
                                    setShowConfirmation(false);
                                }}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TransferPatientModal;