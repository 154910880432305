'use client'

import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom';

import { subDays, parseISO } from 'date-fns';
import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, Switch, Listbox, Transition, DialogBackdrop, DialogTitle } from '@headlessui/react'
import {
    ArrowDownCircleIcon,
    ArrowPathIcon,
    ArrowUpCircleIcon,
    Bars3Icon,
    EllipsisHorizontalIcon,
    PlusSmallIcon,
    ChartBarIcon,
    ExclamationTriangleIcon,
    CogIcon,
    DocumentTextIcon,
    ChartPieIcon,
    PresentationChartLineIcon,
    ChartBarSquareIcon,
    TableCellsIcon,
    CurrencyDollarIcon,
    ReceiptPercentIcon,
    BanknotesIcon,
    CalculatorIcon,
    FaceSmileIcon,
    PencilIcon,
    TrashIcon,
    MagnifyingGlassIcon
} from '@heroicons/react/20/solid'
import { UserGroupIcon, UserIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { protectedResources } from '../authConfig'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon, ChevronRightIcon, EllipsisVerticalIcon, PaperClipIcon } from '@heroicons/react/20/solid';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';


const mockDocumentsData = [
    {
        division: 'Division A',
        users: [
            {
                actor_id: 1,
                full_name: 'Dr. John Doe',
                email: 'john.doe@example.com',
                actor_status: 'ACTIVE',
                actor_specialty: 'EMERGENCY_PHYSICIAN',
                documents: [
                    { document_id: 1, created_at: '2024-08-01', chief_complaint: 'Chest pain', has_heart_score: true, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: false },
                    { document_id: 2, created_at: '2024-08-02', chief_complaint: 'Back pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: true },
                    { document_id: 3, created_at: '2024-08-02', chief_complaint: 'Dizziness', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: false },
                    { document_id: 10, created_at: '2024-08-02', chief_complaint: 'Abdominal pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: true, has_nihss: false, has_sepsis_alert: true, has_critical_care_alert: false },
                    { document_id: 11, created_at: '2024-08-03', chief_complaint: 'Chest pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: true },
                    { document_id: 12, created_at: '2024-08-03', chief_complaint: 'Abdominal pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: true, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: false },
                    { document_id: 13, created_at: '2024-08-04', chief_complaint: 'Chest pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: true },
                ]
            },
            {
                actor_id: 2,
                full_name: 'Dr. Jane Smith',
                email: 'jane.smith@example.com',
                actor_status: 'INACTIVE',
                actor_specialty: 'ADVANCED_PRACTICE_PROVIDER',
                documents: [
                    { document_id: 4, created_at: '2024-08-01', chief_complaint: 'Chest pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: true, has_critical_care_alert: false },
                    { document_id: 5, created_at: '2024-08-02', chief_complaint: 'Back pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: true },
                    { document_id: 6, created_at: '2024-08-03', chief_complaint: 'Dizziness', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: true, has_sepsis_alert: false, has_critical_care_alert: false },
                    { document_id: 7, created_at: '2024-08-03', chief_complaint: 'Chest pain', has_heart_score: true, has_ekg: true, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: false },
                    { document_id: 13, created_at: '2024-08-04', chief_complaint: 'Back pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: true },
                    { document_id: 14, created_at: '2024-08-05', chief_complaint: 'Dizziness', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: false },
                    { document_id: 15, created_at: '2024-08-06', chief_complaint: 'Dizziness', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: false },
                ]
            }
        ]
    },
    {
        division: 'Division B',
        users: [
            {
                actor_id: 3,
                full_name: 'Dr. Alice Brown',
                email: 'alice.brown@example.com',
                actor_status: 'ACTIVE',
                actor_specialty: 'EMERGENCY_PHYSICIAN',
                documents: [
                    { document_id: 8, created_at: '2024-08-01', chief_complaint: 'Chest pain', has_heart_score: true, has_ekg: true, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: true, has_critical_care_alert: false },
                    { document_id: 9, created_at: '2024-08-02', chief_complaint: 'Dizziness', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: true, has_sepsis_alert: false, has_critical_care_alert: false },
                    { document_id: 16, created_at: '2024-08-03', chief_complaint: 'Abdominal pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: true, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: false },
                    { document_id: 17, created_at: '2024-08-04', chief_complaint: 'Back pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: true },
                    { document_id: 18, created_at: '2024-08-05', chief_complaint: 'Chest pain', has_heart_score: true, has_ekg: true, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: true },
                    { document_id: 19, created_at: '2024-08-06', chief_complaint: 'Dizziness', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: true, has_sepsis_alert: false, has_critical_care_alert: false },
                ]
            },
            {
                actor_id: 4,
                full_name: 'Dr. Bob Green',
                email: 'bob.green@example.com',
                actor_status: 'ACTIVE',
                actor_specialty: 'ADVANCED_PRACTICE_PROVIDER',
                documents: [
                    { document_id: 20, created_at: '2024-08-01', chief_complaint: 'Abdominal pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: true, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: false },
                    { document_id: 21, created_at: '2024-08-02', chief_complaint: 'Back pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: false, has_sepsis_alert: true, has_critical_care_alert: true },
                    { document_id: 22, created_at: '2024-08-03', chief_complaint: 'Dizziness', has_heart_score: false, has_ekg: false, has_pregnancy_test: false, has_nihss: true, has_sepsis_alert: false, has_critical_care_alert: false },
                    { document_id: 23, created_at: '2024-08-04', chief_complaint: 'Abdominal pain', has_heart_score: false, has_ekg: false, has_pregnancy_test: true, has_nihss: false, has_sepsis_alert: false, has_critical_care_alert: false },
                ]
            }
        ]
    }
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function OrganizationAdminDashboard() {
    const { state } = useLocation();
    const org_id = state?.org_id;
    const [isLoading, setIsLoading] = useState(true);
    const { instance, accounts } = useMsal();
    const [activeTab, setActiveTab] = useState('userManagement')
    const [allOrgData, setAllOrgData] = useState(null);

    const fetchAllOrgData = async () => {
        setIsLoading(true);
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiFetchAllOrgData.scopes,
                account: accounts[0],
            };

            const tokenResponse = await instance.acquireTokenSilent(request);
            const token = tokenResponse.accessToken;

            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };

            // Assuming protectedResources.apiFetchAllOrgData.endpoint is the base URL
            const baseUrl = protectedResources.apiFetchAllOrgData.endpoint;

            // Check if org_id is present
            const endpoint = org_id 
                ? `${baseUrl}?org_id=${org_id}` 
                : baseUrl;

            if (org_id) {
                console.log(org_id);
            }

            const fetchResponse = await fetch(
                endpoint,
                requestOptions
            );
            const data = await fetchResponse.json();
            const sortedData = data.org_data.sort((a, b) => {
                // First, sort by division name
                if (a.division < b.division) return -1;
                if (a.division > b.division) return 1;

                // If divisions are the same, sort by user's full name
                return 0; // Divisions are equal, maintain original order of users
            }).map(division => ({
                ...division,
                users: division.users.sort((a, b) => a.full_name.localeCompare(b.full_name))
            }));

            setAllOrgData(sortedData);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAllOrgData();
    }, []);

    const tabs = [
        { name: 'Stats & Users', value: 'userManagement', icon: ChartPieIcon },
        { name: 'Risk Notifications', value: 'riskNotifications', icon: ExclamationTriangleIcon },
        { name: 'MDM Options', value: 'mdmOptions', icon: CalculatorIcon },
        { name: 'Patient Experience', value: 'patientExperience', icon: FaceSmileIcon },
    ]

    return (
        isLoading ? (
            <div className="flex min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
            </div>
        ) : (
            <div className="flex min-h-screen">
                <aside className="w-64 flex-shrink-0 bg-white border-r border-gray-200">
                    <div className="h-16 flex items-center px-6 border-b border-gray-200">
                        <h1 className="text-lg font-semibold text-gray-900">Organization Dashboard</h1>
                    </div>
                    <nav className="h-full overflow-y-auto py-6 px-4">
                        <ul role="list" className="space-y-1">
                            {tabs.map((tab) => (
                                <li key={tab.value}>
                                    <button
                                        onClick={() => setActiveTab(tab.value)}
                                        className={classNames(
                                            activeTab === tab.value ? 'bg-gray-50 text-indigo-800' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-800',
                                            'group flex w-full items-center gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6'
                                        )}
                                    >
                                        <tab.icon
                                            className={classNames(
                                                activeTab === tab.value ? 'text-indigo-800' : 'text-gray-400 group-hover:text-indigo-800',
                                                'h-6 w-6 shrink-0'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {tab.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </aside>

                <main className="flex-grow flex justify-center">
                    {activeTab === 'userManagement' && <UserManagementContent organizationData={allOrgData} />}
                    {activeTab === 'riskNotifications' && <RiskNotificationsContent organizationData={allOrgData} />}
                    {activeTab === 'mdmOptions' && <MDMOptionsContent organizationData={allOrgData} />}
                    {activeTab === 'patientExperience' && <PatientExperienceContent organizationData={allOrgData} />}
                </main>
            </div>
        )
    );
}






function MDMOptionsContent({ organizationData }) {
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [selectedSpecialties, setSelectedSpecialties] = useState(['EMERGENCY_PHYSICIAN', 'ADVANCED_PRACTICE_PROVIDER']);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [isLoadingDivisions, setIsLoadingDivisions] = useState(true);

    // Fetch users and divisions from the mock data
    const fetchUsers = useCallback(async () => {
        setIsLoadingDivisions(true);
        try {
            // Flatten the mock data and extract users
            const data = organizationData.flatMap((division) =>
                division.users.map((user) => ({
                    division: division.division,
                    actor_id: user.actor_id,
                    full_name: user.full_name,
                    email: user.email,
                    documents: user.documents,
                }))
            );

            setUsers(data);
            setFilteredUsers(data);

            // Extract unique divisions from the user data
            const uniqueDivisions = Array.from(
                new Set(data.map((user) => user.division))
            ).map((division) => ({ id: division, name: division }));
            setDivisions(uniqueDivisions);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoadingDivisions(false);
        }
    }, [organizationData]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = users.filter(
                (user) =>
                    user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filtered);
            setSelectedDivisions([]); // Clear selected divisions when searching
        } else if (selectedDivisions.length > 0) {
            const filtered = users.filter((user) =>
                selectedDivisions.includes(user.division)
            );
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users);
        }
    }, [searchTerm, selectedDivisions, users]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            setSelectedDivisions([]);
        }
    };

    const handleDivisionToggle = (division) => {
        setSelectedDivisions((prevSelected) =>
            prevSelected.includes(division.id)
                ? prevSelected.filter((d) => d !== division.id)
                : [...prevSelected, division.id]
        );
        setSearchTerm('');
        setSelectedUser(null);
    };

    const specialties = [
        { id: 'EMERGENCY_PHYSICIAN', name: 'Physician' },
        { id: 'ADVANCED_PRACTICE_PROVIDER', name: 'APP' },
    ];

    const handleSpecialtyToggle = (specialty) => {
        setSelectedSpecialties((prevSelected) =>
            prevSelected.includes(specialty.id)
                ? prevSelected.filter((s) => s !== specialty)
                : [...prevSelected, specialty]
        );
        setSearchTerm('');
        setSelectedUser(null);
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };

    // MDM Options and descriptions
    const mdmOptions = [
        'Full DDx',
        'Verbalized and conservative DDx',
        'Only verbalized DDx',
        'No DDx',
    ];

    const mdmDescriptions = {
        'Full DDx':
            "Cleo will generate a comprehensive differential diagnosis based on the patient's presentation and provider's assessment.",
        'Verbalized and conservative DDx':
            'Cleo will include mentioned diagnoses and a few closely related conditions.',
        'Only verbalized DDx':
            'Cleo will only include diagnoses explicitly mentioned by the provider.',
        'No DDx': 'Cleo will not generate any differential diagnosis.',
    };

    const [mdmSettings, setMdmSettings] = useState({
        'Full DDx': false,
        'Verbalized and conservative DDx': false,
        'Only verbalized DDx': true,
        'No DDx': false,
    });

    const handleMdmSettingChange = (option) => {
        setMdmSettings((prevSettings) => ({
            ...prevSettings,
            [option]: !prevSettings[option],
        }));
    };

    return (
        <div className="flex flex-col w-full bg-gray-100 h-screen">
            <div className="bg-white">
                <div className="px-4 sm:px-6 lg:px-8 py-6">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                        MDM Configuration
                    </h1>
                </div>
            </div>
            <div className="bg-white border-b border-gray-200 px-4 pb-2">
                <div className="max-w-md w-full lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                        Search
                    </label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MagnifyingGlassIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Search for users"
                            type="search"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-1 overflow-hidden">
                {/* Specialties column */}
                {/* <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">
                        Specialties
                    </h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className="p-4 cursor-pointer hover:bg-gray-50 text-gray-700"
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        // onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                        className={`${selectedSpecialties.includes(specialty.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id) ? 'translate-x-5' : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div> */}
                <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Specialties</h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className={`p-4 cursor-pointer hover:bg-gray-50 text-gray-700`}
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                        className={`${selectedSpecialties.includes(specialty.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id) ? 'translate-x-5' : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                {/*Example*/}
                {/* <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Specialties</h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className={`p-4 cursor-pointer hover:bg-gray-50 text-gray-700`}
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                        className={`${selectedSpecialties.includes(specialty.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id) ? 'translate-x-5' : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div> */}

                {/* Divisions column */}
                <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">
                        Divisions
                    </h2>
                    {isLoadingDivisions ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
                        </div>
                    ) : divisions.length > 0 ? (
                        <ul className="divide-y divide-gray-200">
                            {divisions.map((division) => (
                                <li
                                    key={division.id}
                                    className="p-4 cursor-pointer hover:bg-gray-50 text-gray-700"
                                    onClick={() => handleDivisionToggle(division)}
                                >
                                    <div className="flex items-center">
                                        <Switch
                                            checked={selectedDivisions.includes(division.id)}
                                            onChange={() => handleDivisionToggle(division)}
                                            onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                            className={classNames(
                                                selectedDivisions.includes(division.id)
                                                    ? 'bg-indigo-600'
                                                    : 'bg-gray-200',
                                                'relative inline-flex h-6 w-11 items-center rounded-full'
                                            )}
                                        >
                                            <span
                                                className={classNames(
                                                    selectedDivisions.includes(division.id)
                                                        ? 'translate-x-6'
                                                        : 'translate-x-1',
                                                    'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
                                                )}
                                            />
                                        </Switch>
                                        <BuildingOfficeIcon className="h-5 w-5 ml-3 text-gray-400" />
                                        <span className="ml-3 text-sm font-medium">
                                            {division.name}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="h-12 w-12 mb-4 text-gray-400" />
                            <p>No divisions found</p>
                        </div>
                    )}
                </div>

                {/* Users/Devices column */}
                <div className="w-80 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">
                        Users
                    </h2>
                    {searchTerm ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={classNames(
                                            'flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50',
                                            selectedUser?.actor_id === user.actor_id
                                                ? 'bg-indigo-50 text-indigo-600'
                                                : 'text-gray-700'
                                        )}
                                        onClick={() => handleUserSelect(user)}
                                    >
                                        <div className="flex items-center gap-x-3">
                                            <UserIcon className="h-5 w-5 text-gray-400" />
                                            <div>
                                                <p className="text-sm font-medium">{user.full_name}</p>
                                                <p className="text-xs text-gray-500">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                                <UserGroupIcon className="h-12 w-12 mb-4 text-gray-400" />
                                <p>No users found matching your search</p>
                            </div>
                        )
                    ) : selectedDivisions.length > 0 ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={classNames(
                                            'flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50',
                                            selectedUser?.actor_id === user.actor_id
                                                ? 'bg-indigo-50 text-indigo-600'
                                                : 'text-gray-700'
                                        )}
                                        onClick={() => handleUserSelect(user)}
                                    >
                                        <div className="flex items-center gap-x-3">
                                            <UserIcon className="h-5 w-5 text-gray-400" />
                                            <div>
                                                <p className="text-sm font-medium">{user.full_name}</p>
                                                <p className="text-xs text-gray-500">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                                <UserGroupIcon className="h-12 w-12 mb-4 text-gray-400" />
                                <p>No users found in the selected divisions</p>
                            </div>
                        )
                    ) : (
                        <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="h-12 w-12 mb-4 text-gray-400" />
                            <p>Select divisions to view users</p>
                        </div>
                    )}
                </div>

                {/* MDM Settings Details column */}
                <div className="flex-1 bg-white overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">
                        MDM Configuration
                    </h2>
                    <div className="p-6 space-y-6">
                        {mdmOptions.map((option) => (
                            <div key={option} className="flex items-start">
                                <Switch
                                    checked={mdmSettings[option]}
                                    onChange={() => handleMdmSettingChange(option)}
                                    className={classNames(
                                        mdmSettings[option] ? 'bg-indigo-600' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 items-center rounded-full'
                                    )}
                                >
                                    <span
                                        className={classNames(
                                            mdmSettings[option] ? 'translate-x-6' : 'translate-x-1',
                                            'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
                                        )}
                                    />
                                </Switch>
                                <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">{option}</p>
                                    <p className="text-sm text-gray-500">
                                        {mdmDescriptions[option]}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}













function RiskNotificationsContent({ organizationData }) {
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedSpecialties, setSelectedSpecialties] = useState(['EMERGENCY_PHYSICIAN', 'ADVANCED_PRACTICE_PROVIDER']);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [users, setUsers] = useState([]);
    const [isLoadingDivisions, setIsLoadingDivisions] = useState(true);
    const [selectedTab, setSelectedTab] = useState('chest_pain'); // State for selected switchFlag tab


    const fetchUsers = useCallback(async () => {
        setIsLoadingDivisions(true);
        try {
            // Simulate fetching data by using the mock data
            const data = organizationData
                .flatMap(division =>
                    division.users
                        .filter(user => selectedSpecialties.includes(user.actor_specialty))
                        .map(user => ({
                            division: division.division,
                    actor_id: user.actor_id,
                    full_name: user.full_name,
                    email: user.email,
                    documents: user.documents,
                }))
            );

            setUsers(data);
            setFilteredUsers(data);

            // Extract unique divisions from user data
            const uniqueDivisions = Array.from(new Set(data.map(user => user.division)))
                .map(division => ({ id: division, name: division }));
            setDivisions(uniqueDivisions);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoadingDivisions(false);
        }
    }, [selectedSpecialties, selectedDivisions]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = users.filter(user =>
                user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filtered);
            setSelectedDivisions([]); // Clear selected divisions when searching
        } else if (selectedDivisions.length > 0) {
            const filtered = users.filter(user => selectedDivisions.includes(user.division));
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users);
        }
    }, [searchTerm, selectedDivisions, users]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            setSelectedDivisions([]);
        }
    };


    const handleDivisionToggle = (division) => {
        setSelectedDivisions(prevSelected => {
            if (prevSelected.includes(division.id)) {
                // Deselect the division if it's already selected
                return [];
            } else {
                // Select only the clicked division
                return [division.id];
            }
        });
        setSearchTerm('');
        setSelectedUser(null);
    };

    const handleSpecialtyToggle = (specialty) => {
        setSelectedSpecialties(prevSelected => {
            if (prevSelected.includes(specialty.id)) {
                return prevSelected.filter(s => s !== specialty.id);
            } else {
                return [...prevSelected, specialty.id];
            }
        });
        setSearchTerm('');
        setSelectedUser(null);
    };

    const specialties = [
        { id: 'EMERGENCY_PHYSICIAN', name: 'Physician' },
        { id: 'ADVANCED_PRACTICE_PROVIDER', name: 'APP' },
    ];


    const tabs = [
        { id: 'sepsis', label: 'Sepsis' },
        { id: 'critical_care', label: 'Critical Care' },
        { id: 'chest_pain', label: 'Chest Pain' },
        { id: 'back_pain', label: 'Back Pain' },
        { id: 'dizziness', label: 'Dizziness' },
        { id: 'abdominal_pain', label: 'Abdominal Pain' },
    ];

    const selectedDivision = selectedDivisions.length === 1
        ? organizationData.find(div => div.division === selectedDivisions[0])
        : null;

    return (
        <div className="flex flex-col w-full bg-gray-100">
            <div className="bg-white">
                <div className="px-4 sm:px-6 lg:px-8 py-6">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Risk Analyst Configuration</h1>
                </div>
            </div>
            {/* Tab Navigation */}
            <div className="bg-white">
                <div className="mb-4 px-4 sm:px-6 lg:px-8">
                    <div className="flex space-x-4">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`px-3 py-2 font-medium text-sm rounded-md ${selectedTab === tab.id
                                    ? 'bg-indigo-100 text-indigo-700'
                                    : 'text-gray-600 hover:text-gray-800 hover:bg-gray-100'
                                    }`}
                                onClick={() => setSelectedTab(tab.id)}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="bg-white border-b border-gray-200 px-4 pb-2">
                <div className="max-w-md w-full lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Search for users"
                            type="search"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-1 overflow-hidden">
                {/* Specialties column */}
                <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Specialties</h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className={`p-4 cursor-pointer hover:bg-gray-50 text-gray-700`}
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                        className={`${selectedSpecialties.includes(specialty.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id) ? 'translate-x-5' : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* Divisions column */}
                <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Divisions</h2>
                    {isLoadingDivisions ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
                        </div>
                    ) : divisions.length > 0 ? (
                        <ul className="divide-y divide-gray-200">
                            {divisions.map((division) => (
                                <li
                                    key={division.id}
                                    className={`p-4 cursor-pointer hover:bg-gray-50 text-gray-700`}
                                    onClick={() => handleDivisionToggle(division)}
                                >
                                    <div className="flex items-center">
                                        <Switch
                                            checked={selectedDivisions.includes(division.id)}
                                            onChange={() => handleDivisionToggle(division)}
                                            onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                            className={`${selectedDivisions.includes(division.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={`${selectedDivisions.includes(division.id) ? 'translate-x-5' : 'translate-x-0'
                                                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                            />
                                        </Switch>
                                        <BuildingOfficeIcon className="h-5 w-5 ml-2 text-gray-400" />
                                        <span className="ml-2">{division.name}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="h-12 w-12 mb-4 text-gray-400" />
                            <p>No divisions found</p>
                        </div>
                    )}
                </div>

                {/* Users column */}
                <div className="w-80 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Users</h2>
                    {searchTerm ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={`flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50 ${selectedUser?.actor_id === user.actor_id ? 'bg-indigo-50 text-indigo-600' : 'text-gray-700'}`}
                                        onClick={() => setSelectedUser(user)}
                                    >
                                        <div className="min-w-0 flex-grow">
                                            <div className="flex items-start gap-x-3">
                                                <UserIcon className="h-5 w-5 text-gray-400" />
                                                <p className="text-sm font-semibold leading-6">{user.full_name}</p>
                                            </div>
                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                <p className="truncate">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className={"h-5 w-5 text-gray-400"}
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                                <UserGroupIcon className="h-12 w-12 mb-4 text-gray-400" />
                                <p>No users found matching your search</p>
                            </div>
                        )
                    ) : selectedDivisions.length > 0 ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={`flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50 ${selectedUser?.actor_id === user.actor_id ? 'bg-indigo-50 text-indigo-600' : 'text-gray-700'}`}
                                        onClick={() => setSelectedUser(user)}
                                    >
                                        <div className="min-w-0 flex-grow">
                                            <div className="flex items-start gap-x-3">
                                                <UserIcon className="h-5 w-5 text-gray-400" />
                                                <p className="text-sm font-semibold leading-6">{user.full_name}</p>
                                            </div>
                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                <p className="truncate">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className={"h-5 w-5 text-gray-400"}
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                                <UserGroupIcon className="h-12 w-12 mb-4 text-gray-400" />
                                <p>No users found in the selected divisions</p>
                            </div>
                        )
                    ) : (
                        <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="h-12 w-12 mb-4 text-gray-400" />
                            <p>Select divisions to view users</p>
                        </div>
                    )}
                </div>

                {/* Details column */}
                {selectedUser ? (
                    <RiskNotificationDetailsColumn
                        selectedEntity={selectedUser}
                        entityType={"user"}
                        switchFlag={selectedTab}
                        organizationData={organizationData}
                    />
                ) : selectedDivision ? (
                    <RiskNotificationDetailsColumn
                        selectedEntity={selectedDivision}
                        entityType={"division"}
                        switchFlag={selectedTab}
                        organizationData={organizationData}
                    />
                ) : selectedSpecialties.length === 1 ? (
                    <RiskNotificationDetailsColumn
                        selectedEntity={organizationData}
                        entityType={"specialty"}
                        switchFlag={selectedTab}
                        organizationData={organizationData}
                    />
                ) : (
                    <RiskNotificationDetailsColumn
                        selectedEntity={organizationData}
                        entityType={"organization"}
                        switchFlag={selectedTab}
                        organizationData={organizationData}
                    />
                )}
            </div>
        </div>
    );
}




function RiskNotificationDetailsColumn({ selectedEntity, switchFlag, entityType, organizationData }) {
    const [entityUsageStats, setEntityUsageStats] = useState([]);
    const [showWarnings, setShowWarnings] = useState(true);
    const [forceResolve, setForceResolve] = useState(false);
    const [autoAudit, setAutoAudit] = useState(true);
    const [allDocs, setAllDocs] = useState([]);
    const [filteredDocs, setFilteredDocs] = useState([]);
    const [maternalAgedFemaleDocs, setMaternalAgedFemaleDocs] = useState([]);
    const [specialty, setSpecialty] = useState(null);
    const [division, setDivision] = useState(null);
    const [specialtyType, setSpecialtyType] = useState("Specialty");
    const [recentStats, setRecentStats] = useState([]);

    const [alertingStats, setAlertingStats] = useState([]);
    const beginningOfStructure = new Date("2024-08-23");
    const beginningOfAlerting = new Date("2024-09-04");

    const chartData = useMemo(() => {
        return switchFlag === 'sepsis' || switchFlag === 'critical_care' ? alertingStats : recentStats;
    }, [switchFlag, alertingStats, recentStats]);

    const mapSwitchFlagToName = (switchFlag) => {
        const flagMap = {
            sepsis: "Sepsis",
            critical_care: "Critical Care",
            chest_pain: "Chest Pain",
            back_pain: "Back Pain",
            dizziness: "Dizziness",
            abdominal_pain: "Abdominal Pain",
        };
        return flagMap[switchFlag] || "Unknown";
    };

    const calculateDocs = () => {
        let allDocs;
        if (entityType === 'user') {
            allDocs = selectedEntity.documents;
        } else if (entityType === 'division') {
            allDocs = selectedEntity.users.flatMap(user => user.documents);
        } else if (entityType === 'specialty') {
            allDocs = selectedEntity.flatMap(division =>
                division.users
                    .filter(user => user.actor_specialty.toLowerCase() === specialty.toLowerCase())
                    .flatMap(user => user.documents)
            );
        } else if (entityType === 'organization') {
            allDocs = selectedEntity.flatMap(division =>
                division.users.flatMap(user => user.documents)
            );
        } else {
            console.error('Unknown entity type:', entityType);
            allDocs = [];
        }
        setAllDocs(allDocs);
        return allDocs;
    };

    const filterDocs = (docs, switchFlag) => {
        const filterMap = {
            sepsis: (doc) => doc.has_sepsis_alert,
            critical_care: (doc) => doc.has_critical_care_alert,
            chest_pain: (doc) => doc.chief_complaint?.toLowerCase() === "chest pain",
            back_pain: (doc) => doc.chief_complaint?.toLowerCase() === "back pain",
            dizziness: (doc) => doc.chief_complaint?.toLowerCase() === "dizziness",
            abdominal_pain: (doc) => doc.chief_complaint?.toLowerCase() === "abdominal pain",
        };
        return docs.filter(filterMap[switchFlag]);
    };


    useEffect(() => {
        if (selectedEntity && switchFlag) {
            setAlertingStats([]);
            setFilteredDocs([]);
            setEntityUsageStats([]);
            setRecentStats([]);
            const allDocsResponse = calculateDocs();

            const docs = filterDocs(allDocsResponse, switchFlag);
            setFilteredDocs(docs);

            if (switchFlag === 'abdominal_pain') {
                const maternalDocs = docs.filter(doc => doc.patient_sex === "FEMALE" && parseInt(doc.patient_age) >= 12 && parseInt(doc.patient_age) <= 50);
                setMaternalAgedFemaleDocs(maternalDocs);
            }

            const usageStats = docs.reduce((acc, doc) => {
                const date = doc.created_at;
                const existingEntry = acc.find(entry => entry.date === date);
                if (existingEntry) {
                    existingEntry.document_count += 1;
                    if (doc.has_ekg) existingEntry.ekg_count += 1;
                    if (doc.has_heart_score) existingEntry.heart_score_count += 1;
                    if (doc.has_nihss) existingEntry.nihss_count += 1;
                } else {
                    acc.push({
                        date,
                        document_count: 1,
                        ekg_count: doc.has_ekg ? 1 : 0,
                        heart_score_count: doc.has_heart_score ? 1 : 0,
                        nihss_count: doc.has_nihss ? 1 : 0,
                    });
                }
                return acc;
            }, []).sort((a, b) => new Date(a.date) - new Date(b.date));
            

            const alertingStats = allDocs.reduce((acc, doc) => {
                const date = doc.created_at;
                const existingEntry = acc.find(entry => entry.date === date);
                if (existingEntry) {
                    existingEntry.document_count += 1;
                    existingEntry.sepsis_count += doc.has_sepsis_alert ? 1 : 0;
                    existingEntry.critical_care_count += doc.has_critical_care_alert ? 1 : 0;
                } else {
                    acc.push({
                        date,
                        document_count: 1,
                        sepsis_count: doc.has_sepsis_alert ? 1 : 0,
                        critical_care_count: doc.has_critical_care_alert ? 1 : 0
                    });
                }
                return acc;
            }, []).sort((a, b) => new Date(a.date) - new Date(b.date));

            // add sepsis and critical care alert percentages to the alerting stats
            const alertingStatsWithPercentages = alertingStats.map(stat => {
                const totalDocuments = stat.document_count;
                const sepsisCount = stat.sepsis_count || 0;
                const criticalCareCount = stat.critical_care_count || 0;
            
                const sepsisAlertPercentage = totalDocuments > 0 ? (sepsisCount / totalDocuments) * 100 : 0;
                const ccAlertPercentage = totalDocuments > 0 ? (criticalCareCount / totalDocuments) * 100 : 0;
            
                return {
                    ...stat,
                    sepsis_alert_percentage: parseFloat(sepsisAlertPercentage.toFixed(2)),
                    critical_care_alert_percentage: parseFloat(ccAlertPercentage.toFixed(2))
                };
            });            

            const sevenDaysAgo = subDays(new Date(), 7);

            const recentStats = usageStats.filter(stat =>
                parseISO(stat.date) >= sevenDaysAgo
            );
            const recentAlertingStats = alertingStatsWithPercentages.filter(stat => 
                parseISO(stat.date) >= beginningOfAlerting
            );

            setEntityUsageStats(usageStats);
            setRecentStats(recentStats);
            setAlertingStats(recentAlertingStats);
        }
    }, [selectedEntity, switchFlag]);

    const sortByDate = (data) => {
        return [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
    };
    const sortedRecentStats = sortByDate(recentStats);


    return (
        <div className="flex-1 bg-white overflow-y-auto">
            <h1 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">
                {entityType === 'user' && selectedEntity.full_name}
                {entityType === 'division' && selectedEntity.division}
                {entityType === 'specialty' && specialtyType}
                {entityType === 'organization' && 'Organization'}
                {' '}{mapSwitchFlagToName(switchFlag)} Analysis
            </h1>
            <div className="p-4">
                {switchFlag === 'sepsis' && (
                    <div className="px-4 py-2 sm:px-6">
                        <p className="text-sm text-gray-500">
                            Our AI analyzes encounter data for potential sepsis cases based on two main criteria:
                        </p>
                        <ol className="mt-2 text-sm text-gray-500 list-decimal list-inside">
                            <li className="mb-2">
                                <strong>SIRS indicators secondary to suspected infection:</strong>
                                <ul className="ml-4 mt-1 list-disc list-inside">
                                    <li>At least 2 SIRS criteria: temperature &gt;100.4°F or &lt;96.8°F, heart rate &gt;90 bpm, respiratory rate &gt;20/min</li>
                                    <li>AND suspected infection (e.g., pneumonia, UTI, cellulitis)</li>
                                </ul>
                            </li>
                            <li>
                                <strong>High-risk patients with high-risk conditions:</strong>
                                <ul className="ml-4 mt-1 list-disc list-inside">
                                    <li>High-risk factors: elderly, dialysis, active cancer, immunocompromised, nursing home resident, cirrhosis</li>
                                    <li>AND presenting with: altered mental status, shortness of breath, weakness, or abdominal pain</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                )}
                {switchFlag === 'critical_care' && (
                    <div className="px-4 py-2 sm:px-6">
                        <p className="text-sm text-gray-500">
                            Our AI analyzes encounter data for potential critical care cases based on two essential criteria:
                        </p>
                        <ol className="mt-2 text-sm text-gray-500 list-decimal list-inside">
                            <li className="mb-2">
                                <strong>Clinical Condition:</strong> High probability of sudden, clinically significant, or life-threatening deterioration requiring urgent intervention.
                                <ul className="ml-4 mt-1 list-disc list-inside">
                                    <li>Examples: Shock, acute circulatory failure, severe CNS deterioration, acute respiratory failure, metabolic crises</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Treatment Requirements:</strong> Need for direct physician management and life/organ supporting interventions.
                                <ul className="ml-4 mt-1 list-disc list-inside">
                                    <li>Examples: Administration of critical medications, advanced airway management, defibrillation, cardioversion</li>
                                </ul>
                            </li>
                        </ol>
                        <p className="mt-3 text-sm text-gray-500">
                            The AI considers various indicators such as vital signs, EKG changes, neurological status, and required interventions to determine if both criteria are met.
                        </p>
                    </div>
                )}

                <h2 className="text-lg py-4 font-semibold text-gray-700 border-b border-gray-200">Risk Analyst Configuration</h2>
                <div className="flex justify-start items-left mb-4 space-x-6 mt-4">
                    {/* Show warnings toggle */}
                    <div className="flex items-center space-x-4">
                        <Switch
                            checked={showWarnings}
                            onChange={setShowWarnings}
                            className={`${showWarnings ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-8 w-16 items-center rounded-full`}
                        >
                            <span className={`${showWarnings ? 'translate-x-8' : 'translate-x-1'} inline-block h-6 w-6 transform rounded-full bg-white transition`} />
                        </Switch>
                        <span className="text-base font-semibold text-gray-700">Show Warnings</span>
                    </div>

                    {/* Force resolve toggle */}
                    <div className="flex items-center space-x-4">
                        <Switch
                            checked={forceResolve}
                            onChange={setForceResolve}
                            className={`${forceResolve ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-8 w-16 items-center rounded-full`}
                        >
                            <span className={`${forceResolve ? 'translate-x-8' : 'translate-x-1'} inline-block h-6 w-6 transform rounded-full bg-white transition`} />
                        </Switch>
                        <span className="text-base font-semibold text-gray-700">Force Resolve</span>
                    </div>

                    {/* Auto-audit toggle */}
                    <div className="flex items-center space-x-4">
                        <Switch
                            checked={autoAudit}
                            onChange={setAutoAudit}
                            className={`${autoAudit ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-8 w-16 items-center rounded-full`}
                        >
                            <span className={`${autoAudit ? 'translate-x-8' : 'translate-x-1'} inline-block h-6 w-6 transform rounded-full bg-white transition`} />
                        </Switch>
                        <span className="text-base font-semibold text-gray-700">Push Notify</span>
                    </div>
                </div>

                <h2 className="text-lg py-4 mt-8 mb-2 font-semibold text-gray-700 border-b border-gray-200">{mapSwitchFlagToName(switchFlag)} Statistics</h2>
                <dl>
    {[
        {
            label: `Total ${mapSwitchFlagToName(switchFlag)} Encounters`,
            value: filteredDocs.length,
        },
        ...(
            switchFlag === 'chest_pain'
                ? [
                    {
                        label: `% of Encounters with EKG`,
                        value: (() => {
                            const validDocs = filteredDocs.filter(doc => new Date(doc.created_at) >= beginningOfStructure);
                            const docsWithEKG = validDocs.filter(doc => doc.has_ekg);
                            const percentage = validDocs.length > 0
                                ? (docsWithEKG.length / validDocs.length) * 100
                                : 0;
                            return `${percentage.toFixed(0)}%`;
                        })(),
                    },
                    // {
                    //     label: `% of Encounters with HEART Score`,
                    //     value: (() => {
                    //         const validDocs = filteredDocs.filter(doc => new Date(doc.created_at) >= beginningOfStructure);
                    //         const docsWithHeartScore = validDocs.filter(doc => doc.has_heart_score);
                    //         const percentage = validDocs.length > 0
                    //             ? (docsWithHeartScore.length / validDocs.length) * 100
                    //             : 0;
                    //         return `${percentage.toFixed(0)}%`;
                    //     })(),
                    // },
                ]
                : switchFlag === 'abdominal_pain'
                    ? [
                        // {
                        //     label: `% of Encounters Maternal-Aged Female and Pregnancy Test Ordered`,
                        //     value: (() => {
                        //         const validDocs = maternalAgedFemaleDocs.filter(doc => new Date(doc.created_at) >= beginningOfStructure);
                        //         const docsWithPregnancyTest = validDocs.filter(doc => doc.has_pregnancy_test);
                        //         const percentage = validDocs.length > 0
                        //             ? (docsWithPregnancyTest.length / validDocs.length) * 100
                        //             : 0;
                        //         return `${percentage.toFixed(0)}%`;
                        //     })(),
                        // },
                    ]
                    : switchFlag === 'dizziness'
                        ? [
                            // {
                            //     label: `% of Encounters with NIHSS`,
                            //     value: (() => {
                            //         const validDocs = filteredDocs.filter(doc => new Date(doc.created_at) >= beginningOfStructure);
                            //         const docsWithNIHSS = validDocs.filter(doc => doc.has_nihss);
                            //         const percentage = validDocs.length > 0
                            //             ? (docsWithNIHSS.length / validDocs.length) * 100
                            //             : 0;
                            //         return `${percentage.toFixed(0)}%`;
                            //     })(),
                            // },
                        ]
                        : switchFlag === 'sepsis'
                            ? [
                                {
                                    label: `% of Encounters with Sepsis Alert`,
                                    value: (() => {
                                        const recentDocs = allDocs.filter(doc => new Date(doc.created_at) >= beginningOfAlerting);
                                        const docsWithSepsis = recentDocs.filter(doc => doc.has_sepsis_alert);
                                        const percentage = recentDocs.length > 0
                                            ? (docsWithSepsis.length / recentDocs.length) * 100
                                            : 0;
                                        return `${percentage.toFixed(0)}%`;
                                    })(),
                                },
                            ]
                        : switchFlag === 'critical_care'
                            ? [
                                {
                                    label: `% of Encounters with Critical Care Alert`,
                                    value: (() => {
                                        const recentDocs = allDocs.filter(doc =>
                                            new Date(doc.created_at) >= beginningOfAlerting);
                                        const docsWithCriticalCare = recentDocs.filter(doc => doc.has_critical_care_alert);
                                        const percentage = recentDocs.length > 0
                                            ? (docsWithCriticalCare.length / recentDocs.length) * 100
                                            : 0;
                                        return `${percentage.toFixed(0)}%`;
                                    })(),
                                },
                            ]
                            : []
        )
    ].map(item => (
        <div key={item.label} className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
            <dd className="text-sm font-bold text-gray-900 sm:col-span-2 sm:mt-0">
                {item.value}
            </dd>
        </div>
    ))}
</dl>
        {/* CHART SHOWING PERCENTAGES */} 
        <div className="mt-8">
            <h2 className="text-lg font-medium text-gray-900 mb-4">
                {mapSwitchFlagToName(switchFlag)} Recent Activity
            </h2>
            {((switchFlag === 'sepsis' || switchFlag === 'critical_care') ? alertingStats : recentStats).length === 0 ? (
                <div className="flex justify-center items-center h-48">
                    <p className="text-sm text-gray-500">No documents available</p>
                </div>
            ) : (
                <ResponsiveContainer width="100%" height={200}>
                    <LineChart data={chartData}>
                        <XAxis dataKey="date" />
                        {/* Primary Y-Axis for document counts and similar metrics */}
                        <YAxis yAxisId="left"/>
                        {/* Secondary Y-Axis for percentages (right side) */}
                        <YAxis 
                            yAxisId="right" 
                            orientation="right" 
                            tickFormatter={(tick) => `${tick.toFixed(0)}%`} 
                            domain={[0, 50]} // Dynamically set domain based on data
                            allowDecimals={false} 
                        />

                        <Tooltip />
                        <Legend
                            verticalAlign="bottom"
                            align="right"
                            wrapperStyle={{ paddingTop: '20px' }}
                        />
                        {/* Line components for metrics aligned to the left Y-axis */}
                        <Line 
                            yAxisId="left"
                            type="monotone" 
                            dataKey="document_count" 
                            stroke="#4f46e5" 
                            strokeWidth={2} 
                            name="Total Encounters" 
                        />
                        {switchFlag === 'chest_pain' && (
                            <>
                                <Line 
                                    yAxisId="left"
                                    type="monotone" 
                                    dataKey="ekg_count" 
                                    stroke="#82ca9d" 
                                    strokeWidth={2} 
                                    name="Encounters with EKG" 
                                />
                                {/* <Line 
                                    yAxisId="left"
                                    type="monotone" 
                                    dataKey="heart_score_count" 
                                    stroke="#33cfff" 
                                    strokeWidth={2} 
                                    name="Encounters with HEART Score" 
                                /> */}
                            </>
                        )}
                        {/* {switchFlag === 'dizziness' && (
                            <Line 
                                yAxisId="left"
                                type="monotone" 
                                dataKey="nihss_count" 
                                stroke="#82ca9d" 
                                strokeWidth={2} 
                                name="Encounters with NIHSS" 
                            />
                        )} */}
                        {/* {switchFlag === 'abdominal_pain' && (
                            <Line 
                                yAxisId="left"
                                type="monotone" 
                                dataKey="pregnancy_test_count" 
                                stroke="#82ca9d" 
                                strokeWidth={2} 
                                name="Encounters with Pregnancy Test" 
                            />
                        )} */}
                        {/* Line components for percentages aligned to the right Y-axis */}
                        {switchFlag === 'sepsis' && (
                            <Line 
                                yAxisId="right"
                                type="monotone" 
                                dataKey="sepsis_alert_percentage" 
                                stroke="#33cfff" 
                                strokeWidth={2} 
                                name="Sepsis Alert %" 
                            />
                        )}
                        {switchFlag === 'critical_care' && (
                            <Line 
                                yAxisId="right"
                                type="monotone" 
                                dataKey="critical_care_alert_percentage" 
                                stroke="#33cfff" 
                                strokeWidth={2} 
                                name="Critical Care Alert %" 
                            />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            )}
        </div>
    </div>
        </div>
    );
}


















function UserManagementContent({ organizationData }) {
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedSpecialties, setSelectedSpecialties] = useState(['EMERGENCY_PHYSICIAN', 'ADVANCED_PRACTICE_PROVIDER']);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [users, setUsers] = useState([]);
    const [isLoadingDivisions, setIsLoadingDivisions] = useState(true);

    const fetchUsers = useCallback(async () => {
        setIsLoadingDivisions(true);
        try {
            const data = organizationData.flatMap(division =>
                division.users
                    .filter(user => selectedSpecialties.includes(user.actor_specialty))
                    .map(user => ({
                    division: division.division,
                    actor_id: user.actor_id,
                    full_name: user.full_name,
                    email: user.email,
                    actor_specialty: user.actor_specialty,
                    actor_status: user.actor_status,
                    documents: user.documents,
                    is_beta: user.is_beta,
                }))
            );

            setUsers(data);
            setFilteredUsers(data);

            // Extract unique divisions from user data
            const uniqueDivisions = Array.from(new Set(data.map(user => user.division)))
                .map(division => ({ id: division, name: division }));
            setDivisions(uniqueDivisions);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoadingDivisions(false);
        }
    }, [selectedSpecialties, selectedDivisions]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = users.filter(user =>
                user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filtered);
            setSelectedDivisions([]); // Clear selected divisions when searching
        } else if (selectedDivisions.length > 0) {
            const filtered = users.filter(user => selectedDivisions.includes(user.division));
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users);
        }
    }, [searchTerm, selectedDivisions, users]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            setSelectedDivisions([]);
        }
    };

    const handleDivisionToggle = (division) => {
        setSelectedDivisions(prevSelected => {
            if (prevSelected.includes(division.id)) {
                // Deselect the division if it's already selected
                return [];
            } else {
                // Select only the clicked division
                return [division.id];
            }
        });
        setSearchTerm('');
        setSelectedUser(null);
    };

    const handleSpecialtyToggle = (specialty) => {
        setSelectedSpecialties(prevSelected => {
            if (prevSelected.includes(specialty.id)) {
                return prevSelected.filter(s => s !== specialty.id);
            } else {
                return [...prevSelected, specialty.id];
            }
        });
        setSearchTerm('');
        setSelectedUser(null);
    };

    const handleUpdateUser = async (updatedUser) => {
        // Implement user update logic here
        console.log('Updating user:', updatedUser);
        setUsers(prevUsers => prevUsers.map(user =>
            user.actor_id === updatedUser.actor_id ? updatedUser : user
        ));
        setFilteredUsers(prevUsers => prevUsers.map(user =>
            user.actor_id === updatedUser.actor_id ? updatedUser : user
        ));
        setSelectedUser(updatedUser);
    };

    const handleDeleteUser = async (userId) => {
        // Implement user deletion logic here
        console.log('Deleting user:', userId);
    };

    const specialties = [
        { id: 'EMERGENCY_PHYSICIAN', name: 'Physician' },
        { id: 'ADVANCED_PRACTICE_PROVIDER', name: 'APP' },
    ];


    const selectedDivision = selectedDivisions.length === 1
        ? organizationData.find(div => div.division === selectedDivisions[0])
        : null;

    return (
        <div className="flex flex-col w-full bg-gray-100">
            <div className="bg-white">
                <div className="px-4 sm:px-6 lg:px-8 py-6">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Statistics and User Management</h1>
                </div>
            </div>
            <div className="bg-white border-b border-gray-200 px-4 pb-2">
                <div className="max-w-md w-full lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Search for users"
                            type="search"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-1 overflow-hidden">
                {/* Specialties column */}
                <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Specialties</h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className={`p-4 cursor-pointer hover:bg-gray-50 text-gray-700`}
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                        className={`${selectedSpecialties.includes(specialty.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id) ? 'translate-x-5' : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* Divisions column */}
                <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Divisions</h2>
                    {isLoadingDivisions ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
                        </div>
                    ) : divisions.length > 0 ? (
                        <ul className="divide-y divide-gray-200">
                            {divisions.map((division) => (
                                <li
                                    key={division.id}
                                    className={`p-4 cursor-pointer hover:bg-gray-50 text-gray-700`}
                                    onClick={() => handleDivisionToggle(division)}
                                >
                                    <div className="flex items-center">
                                        <Switch
                                            checked={selectedDivisions.includes(division.id)}
                                            onChange={() => handleDivisionToggle(division)}
                                            onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                            className={`${selectedDivisions.includes(division.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={`${selectedDivisions.includes(division.id) ? 'translate-x-5' : 'translate-x-0'
                                                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                            />
                                        </Switch>
                                        <BuildingOfficeIcon className="h-5 w-5 ml-2 text-gray-400" />
                                        <span className="ml-2">{division.name}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="h-12 w-12 mb-4 text-gray-400" />
                            <p>No divisions found</p>
                        </div>
                    )}
                </div>

                {/* Users column */}
                <div className="w-80 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Users</h2>
                    {searchTerm ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={`flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50 ${selectedUser?.actor_id === user.actor_id ? 'bg-indigo-50 text-indigo-600' : 'text-gray-700'}`}
                                        onClick={() => setSelectedUser(user)}
                                    >
                                        <div className="min-w-0 flex-grow">
                                            <div className="flex items-start gap-x-3">
                                                <UserIcon className="h-5 w-5 text-gray-400" />
                                                <p className="text-sm font-semibold leading-6">{user.full_name}</p>
                                            </div>
                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                <p className="truncate">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className={"h-5 w-5 text-gray-400"}
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                                <UserGroupIcon className="h-12 w-12 mb-4 text-gray-400" />
                                <p>No users found matching your search</p>
                            </div>
                        )
                    ) : selectedDivisions.length > 0 ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={`flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50 ${selectedUser?.actor_id === user.actor_id ? 'bg-indigo-50 text-indigo-600' : 'text-gray-700'}`}
                                        onClick={() => setSelectedUser(user)}
                                    >
                                        <div className="min-w-0 flex-grow">
                                            <div className="flex items-start gap-x-3">
                                                <UserIcon className="h-5 w-5 text-gray-400" />
                                                <p className="text-sm font-semibold leading-6">{user.full_name}</p>
                                            </div>
                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                <p className="truncate">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className={"h-5 w-5 text-gray-400"}
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                                <UserGroupIcon className="h-12 w-12 mb-4 text-gray-400" />
                                <p>No users found in the selected divisions</p>
                            </div>
                        )
                    ) : (
                        <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="h-12 w-12 mb-4 text-gray-400" />
                            <p>Select divisions to view users</p>
                        </div>
                    )}
                </div>

                {/* Details column */}
                {selectedUser ? (
                    <UserDetailsColumn
                        selectedUser={selectedUser}
                        divisions={divisions}
                        onUpdateUser={handleUpdateUser}
                        onDeleteUser={handleDeleteUser}
                    />
                ) : selectedDivision ? (
                    <DivisionDetailsColumn
                        selectedDivision={selectedDivision}
                        selectedSpecialties={selectedSpecialties}
                        onDivisionUpdate={() => console.log('Division updated')}
                    />
                ) : selectedSpecialties.length === 1 ? (
                    <SpecialtyDetailsColumn
                        specialty={selectedSpecialties[0]}
                        organizationData={organizationData}
                    />
                ) : (
                    <OrganizationDetailsColumn
                        organizationData={organizationData}
                    />
                )}
            </div>
        </div>
    );
}



function SpecialtyDetailsColumn({ specialty, organizationData }) {
    const [specialtyStats, setSpecialtyStats] = useState([]);
    const sortByDate = (data) => {
        return [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
    };
    const sortedSpecialtyStats = sortByDate(specialtyStats);


    useEffect(() => {
        if (specialty && organizationData) {
            // Filter users by specialty
            const filteredData = organizationData.flatMap(division =>
                division.users
                    .filter(user => user.actor_specialty.toLowerCase() === specialty.toLowerCase())
                    .flatMap(user =>
                        user.documents.map(doc => ({
                            date: doc.created_at,
                            actor_id: user.actor_id,
                            chief_complaint: doc.chief_complaint,
                        }))
                    )
            );

            const aggregatedData = filteredData.reduce((acc, { date, actor_id, chief_complaint }) => {
                let existingEntry = acc.find(entry => entry.date === date);

                if (existingEntry) {
                    existingEntry.patients += 1;
                    existingEntry.providers.add(actor_id);
                    existingEntry.chiefComplaints[chief_complaint] =
                        (existingEntry.chiefComplaints[chief_complaint] || 0) + 1;
                } else {
                    acc.push({
                        date,
                        patients: 1,
                        providers: new Set([actor_id]),
                        chiefComplaints: { [chief_complaint]: 1 },
                    });
                }
                return acc;
            }, []);

            // Convert the Set of providers to a count and aggregate chief complaints
            const specialtyStatsFormatted = aggregatedData.map(entry => ({
                date: entry.date,
                patients: entry.patients,
                providers: entry.providers.size, // Count of unique providers
                chiefComplaints: entry.chiefComplaints,
            }));

            setSpecialtyStats(specialtyStatsFormatted);
        }
    }, [specialty, organizationData]);

    // Aggregate top chief complaints across all days
    const topChiefComplaints = specialtyStats.reduce((acc, day) => {
        Object.entries(day.chiefComplaints).forEach(([complaint, count]) => {
            acc[complaint] = (acc[complaint] || 0) + count;
        });
        return acc;
    }, {});

    const topSpecialtyChiefComplaints = Object.entries(topChiefComplaints)
        .map(([complaint, count]) => ({ complaint, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);

    return (
        <div className="flex-1 bg-white overflow-y-auto">
            <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">
                {specialty} Details
            </h2>
            <div className="p-4">
                <dl className="divide-y divide-gray-200">
                    {[
                        { label: 'Number of Divisions', value: organizationData.length },
                        {
                            label: 'Total Number of Users',
                            value: organizationData
                                .flatMap(division => division.users)
                                .filter(user => user.actor_specialty.toLowerCase() === specialty.toLowerCase())
                                .filter(user => user.actor_status === 'ACTIVE')
                                .length,
                        },
                        {
                            label: 'Total Documents',
                            value: specialtyStats.reduce((total, day) => total + day.patients, 0),
                        },
                    ].map(item => (
                        <div key={item.label} className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex justify-between items-center">
                                {item.value}
                            </dd>
                        </div>
                    ))}
                </dl>

                <div className="border-t border-gray-200 px-4 py-5">
                    <h4 className="text-md font-medium text-gray-900 mb-2">Daily Count of Patients and Providers</h4>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={sortedSpecialtyStats}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis yAxisId="left" />
                            <YAxis yAxisId="right" orientation="right" />
                            <Tooltip />
                            <Legend />
                            <Line yAxisId="left" type="monotone" dataKey="patients" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line yAxisId="right" type="monotone" dataKey="providers" stroke="#82ca9d" activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                <div className="border-t border-gray-200 px-4 py-5">
                    <h4 className="text-md font-medium text-gray-900 mb-2">Top 5 Chief Complaints</h4>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={topSpecialtyChiefComplaints}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="complaint" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="count" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}


function UserDetailsColumn({ selectedUser, onUpdateUser, onDeleteUser }) {
    const [userUsageStats, setUserUsageStats] = useState([]);
    const [topChiefComplaints, setTopChiefComplaints] = useState([]);
    const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
    const sortByDate = (data) => {
        return [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
    };
    const sortedUserUsageStats = sortByDate(userUsageStats);

    useEffect(() => {
        if (selectedUser) {
            // Prepare the data for the line chart (document count by date)
            const usageStats = selectedUser.documents.reduce((acc, doc) => {
                const date = doc.created_at;
                const existingEntry = acc.find(entry => entry.date === date);
                if (existingEntry) {
                    existingEntry.document_count += 1;
                } else {
                    acc.push({ date, document_count: 1 });
                }
                return acc;
            }, []).sort((a, b) => new Date(a.date) - new Date(b.date));

            setUserUsageStats(usageStats);

            // Prepare the data for the bar chart (top 5 chief complaints)
            const complaintCounts = selectedUser.documents.reduce((acc, doc) => {
                acc[doc.chief_complaint] = (acc[doc.chief_complaint] || 0) + 1;
                return acc;
            }, {});

            const topComplaints = Object.entries(complaintCounts)
                .map(([complaint, count]) => ({ complaint, count }))
                .sort((a, b) => b.count - a.count)
                .slice(0, 5);

            setTopChiefComplaints(topComplaints);
        }
    }, [selectedUser]);

    const handleToggleActive = () => {
        if (selectedUser.actor_status === 'ACTIVE') {
            setIsDeactivateDialogOpen(true);
        } else {
            updateUserStatus('ACTIVE');
        }
    };

    const confirmDeactivate = () => {
        updateUserStatus('INACTIVE');
        setIsDeactivateDialogOpen(false);
    };

    const updateUserStatus = (newStatus) => {
        const updatedUser = {
            ...selectedUser,
            actor_status: newStatus
        };
        onUpdateUser(updatedUser);
    };

    const formatRole = (role) => {
        if (!role) return 'N/A';
        return role === 'EMERGENCY_PHYSICIAN' ? 'Physician' : 'APP';
    };

    return (
        <div className="flex-1 bg-white overflow-y-auto">
            <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">User Details</h2>
            {selectedUser ? (
                <div className="p-4">
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center">
                            <span className="mr-2 text-sm font-medium text-gray-500">Active</span>
                            <Switch
                                checked={selectedUser.actor_status === 'ACTIVE'}
                                onChange={handleToggleActive}
                                className={`${selectedUser.actor_status === 'ACTIVE' ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                                <span className={`${selectedUser.actor_status === 'ACTIVE' ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                            </Switch>
                        </div>
                    </div>
                    <dl className="divide-y divide-gray-200">
                        {[
                            { label: 'Full name', value: selectedUser.full_name },
                            { label: 'Email address', value: selectedUser.email },
                            { label: 'Role', value: formatRole(selectedUser.actor_specialty) },
                            { label: 'Division', value: selectedUser.division },
                            { label: 'Total Patients Seen', value: selectedUser.documents.length },
                        ].map((item) => (
                            <div key={item.label} className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex justify-between items-center">
                                    {item.value}
                                </dd>
                            </div>
                        ))}
                    </dl>
                    <div className="mt-8">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Charts Created</h3>
                        {userUsageStats.length === 0 ? (
                            <div className="flex justify-center items-center h-48">
                                <p className="text-sm text-gray-500">No charts available</p>
                            </div>
                        ) : (
                            <ResponsiveContainer width="100%" height={200}>
                                <LineChart data={sortedUserUsageStats}>
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="document_count" stroke="#4f46e5" strokeWidth={2} />
                                </LineChart>
                            </ResponsiveContainer>
                        )}
                    </div>

                    <div className="border-t border-gray-200 px-4 py-5">
                        <h4 className="text-md font-medium text-gray-900 mb-2">Top 5 Chief Complaints</h4>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={topChiefComplaints}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="complaint" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="count" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                    <UserIcon className="h-12 w-12 mb-4 text-gray-400" />
                    <p>Select a user to view details</p>
                </div>
            )}

            <Dialog open={isDeactivateDialogOpen} onClose={() => setIsDeactivateDialogOpen(false)} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                                </div>
                                <div className="text-leading sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        Deactivate user
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to deactivate this user? They will no longer be able to access their Cleo account.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={confirmDeactivate}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:mt-0 sm:w-auto"
                                >
                                    Deactivate
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setIsDeactivateDialogOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}


function DivisionDetailsColumn({ selectedDivision, selectedSpecialties, onDivisionUpdate }) {
    const [dailyStats, setDailyStats] = useState([]);
    const sortByDate = (data) => {
        return [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
    };
    const sortedDailyStats = sortByDate(dailyStats);

    useEffect(() => {
        if (selectedDivision) {
            // Aggregate daily patient and provider counts, and chief complaints
            const dailyData = selectedDivision.users.filter(user => selectedSpecialties.includes(user.actor_specialty)).reduce((acc, user) => {
                user.documents.forEach(doc => {
                    const date = doc.created_at;
                    let existingEntry = acc.find(entry => entry.date === date);

                    if (existingEntry) {
                        existingEntry.patients += 1;
                        existingEntry.providers.add(user.actor_id);
                        existingEntry.chiefComplaints[doc.chief_complaint] =
                            (existingEntry.chiefComplaints[doc.chief_complaint] || 0) + 1;
                    } else {
                        acc.push({
                            date,
                            patients: 1,
                            providers: new Set([user.actor_id]),
                            chiefComplaints: { [doc.chief_complaint]: 1 }
                        });
                    }
                });
                return acc;
            }, []).sort((a, b) => new Date(a.date) - new Date(b.date));

            // Convert the Set of providers to a count and aggregate chief complaints
            const dailyStatsFormatted = dailyData.map(entry => ({
                date: entry.date,
                patients: entry.patients,
                providers: entry.providers.size, // Count of unique providers
                chiefComplaints: entry.chiefComplaints,
            }));

            setDailyStats(dailyStatsFormatted);

        }
    }, [selectedDivision, selectedSpecialties]);

    // Aggregate top chief complaints across all days
    const topChiefComplaints = dailyStats.reduce((acc, day) => {
        Object.entries(day.chiefComplaints).forEach(([complaint, count]) => {
            acc[complaint] = (acc[complaint] || 0) + count;
        });
        return acc;
    }, {});

    const topDivisionChiefComplaints = Object.entries(topChiefComplaints)
        .map(([complaint, count]) => ({ complaint, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);

    return (
        <div className="flex-1 bg-white overflow-y-auto">
            <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Division Details</h2>
            {selectedDivision ? (
                <div className="p-4">
                    <dl className="divide-y divide-gray-200">
                        {[
                            { label: 'Division Name', value: selectedDivision.division },
                            { label: 'Number of Users', value: 
                                selectedDivision.users
                                .filter(user => user.actor_status === 'ACTIVE')
                                .filter(user => selectedSpecialties.includes(user.actor_specialty))
                                .length
                            },
                            { label: 'Total Documents', value: 
                                selectedDivision.users
                                .filter(user => user.actor_status === 'ACTIVE')
                                .filter(user => selectedSpecialties.includes(user.actor_specialty))
                                .flatMap(user => user.documents)
                                .length
                            },  
                        ].map((item) => (
                            <div key={item.label} className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex justify-between items-center">
                                    {item.value}
                                </dd>
                            </div>
                        ))}
                    </dl>

                    <div className="border-t border-gray-200 px-4 py-5">
                        <h4 className="text-md font-medium text-gray-900 mb-2">Daily Count of Patients and Providers</h4>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={sortedDailyStats}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis yAxisId="left" />
                                <YAxis yAxisId="right" orientation="right" />
                                <Tooltip />
                                <Legend />
                                <Line yAxisId="left" type="monotone" dataKey="patients" stroke="#8884d8" activeDot={{ r: 8 }} />
                                <Line yAxisId="right" type="monotone" dataKey="providers" stroke="#82ca9d" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-5">
                        <h4 className="text-md font-medium text-gray-900 mb-2">Top 5 Chief Complaints</h4>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={topDivisionChiefComplaints}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="complaint" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="count" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                </div>
            ) : (
                <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                    <BuildingOfficeIcon className="h-12 w-12 mb-4 text-gray-400" />
                    <p>Select a division to view details</p>
                </div>
            )}
        </div>
    );
}



function OrganizationDetailsColumn({ organizationData }) {
    const [organizationStats, setOrganizationStats] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);

    const sortByDate = (data) => {
        return [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
    };
    const sortedOrganizationStats = sortByDate(organizationStats);

    // Function to handle CSV export
    const handleExportCSV = (month) => {
        // Convert the selected month to a number (0-based for JS Date)
        const monthIndex = parseInt(month, 10) - 1;

        // First, filter the documents for the selected month, setting default to 1/1/24 for null created_at
        const filteredData = organizationData.flatMap(division =>
            division.users.map(user => ({
                ...user,
                documents: user.documents.filter(doc => {
                    // Handle null created_at, set default to 1/1/24
                    const createdAt = doc.created_at ? new Date(doc.created_at) : new Date('2024-01-01');
                    return createdAt.getMonth() === monthIndex && createdAt.getFullYear() === 2024;
                })
            }))
        );

        // Then, create the CSV data
        const csvData = filteredData.flatMap(user => {
            console.log(`User ${user.full_name} signed_on_at:`, user.signed_on_at);

            // Handle potential nulls for signed_on_at
            const signedOnAt = user.signed_on_at ? new Date(user.signed_on_at).toISOString().split('T')[0] : '';

            return {
                fullName: user.full_name,
                email: user.email,
                division: user.division, // Assuming division is a property of the user object
                specialty: user.actor_specialty,
                signed_on_at: signedOnAt,
                documentCount: user.documents.length,
            };
        });

        // Manually create CSV string
        const csvRows = [];
        const headers = ['Full Name', 'Email', 'Division', 'Specialty', 'Signed On At', 'Document Count'];
        csvRows.push(headers.join(','));

        // Add each user row data
        csvData.forEach(row => {
            const values = [
                row.fullName,
                row.email,
                row.division,
                row.specialty,
                row.signed_on_at,
                row.documentCount
            ];
            csvRows.push(values.join(','));
        });

        // Convert array of rows into CSV string
        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'user_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Handle the export modal visibility
    const handleExportClick = () => {
        setIsExportModalOpen(true);
    };

    const handleExportConfirm = () => {
        if (selectedMonth) {
            handleExportCSV(selectedMonth);
            setIsExportModalOpen(false);
        } else {
            alert("Please select a month to export.");
        }
    };

    useEffect(() => {
        if (organizationData) {
            // Aggregate daily patient and provider counts, and chief complaints across all divisions
            const orgData = organizationData.flatMap(division =>
                division.users.flatMap(user =>
                    user.documents.map(doc => ({
                        date: doc.created_at,
                        actor_id: user.actor_id,
                        chief_complaint: doc.chief_complaint
                    }))
                )
            );

            const aggregatedData = orgData.reduce((acc, { date, actor_id, chief_complaint }) => {
                let existingEntry = acc.find(entry => entry.date === date);

                if (existingEntry) {
                    existingEntry.patients += 1;
                    existingEntry.providers.add(actor_id);
                    existingEntry.chiefComplaints[chief_complaint] =
                        (existingEntry.chiefComplaints[chief_complaint] || 0) + 1;
                } else {
                    acc.push({
                        date,
                        patients: 1,
                        providers: new Set([actor_id]),
                        chiefComplaints: { [chief_complaint]: 1 }
                    });
                }
                return acc;
            }, []).sort((a, b) => new Date(a.date) - new Date(b.date));

            // Convert the Set of providers to a count and aggregate chief complaints
            const organizationStatsFormatted = aggregatedData.map(entry => ({
                date: entry.date,
                patients: entry.patients,
                providers: entry.providers.size, // Count of unique providers
                chiefComplaints: entry.chiefComplaints,
            }));

            setOrganizationStats(organizationStatsFormatted);
        }
    }, [organizationData]);

    // Aggregate top chief complaints across all days
    const topChiefComplaints = organizationStats.reduce((acc, day) => {
        Object.entries(day.chiefComplaints).forEach(([complaint, count]) => {
            acc[complaint] = (acc[complaint] || 0) + count;
        });
        return acc;
    }, {});

    const topOrganizationChiefComplaints = Object.entries(topChiefComplaints)
        .map(([complaint, count]) => ({ complaint, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);

    return (
        <div className="flex-1 bg-white overflow-y-auto">
            <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Organization Details</h2>
            <div className="bg-white">
                <div className="px-4 sm:px-6 lg:px-8 py-6 flex justify-between items-center">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">User Management Dashboard</h1>
                    <button
                        className="bg-indigo-600 text-white py-2 px-4 rounded-md"
                        onClick={handleExportClick}
                    >
                        Export CSV
                    </button>
                </div>
            </div>
            {isExportModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-md shadow-md">
                        <h2 className="text-lg font-semibold mb-4">Select Month to Export</h2>
                        <select
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                            className="border border-gray-300 p-2 rounded-md mb-4"
                        >
                            <option value="">Select a month</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <div className="flex justify-end">
                            <button
                                className="bg-gray-400 text-white py-2 px-4 rounded-md mr-2"
                                onClick={() => setIsExportModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-indigo-600 text-white py-2 px-4 rounded-md"
                                onClick={handleExportConfirm}
                            >
                                Export
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {organizationData ? (
                <div className="p-4">
                    <dl className="divide-y divide-gray-200">
                        {[
                            { label: 'Number of Divisions', value: organizationData.length },
                            { label: 'Total Number of Users', value: 
                                organizationData
                                .flatMap(division => division.users)
                                .filter(user => user.actor_status === 'ACTIVE')
                                .length
                             },
                            { label: 'Total Documents', value: organizationData.flatMap(division => division.users.flatMap(user => user.documents)).length },
                        ].map((item) => (
                            <div key={item.label} className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex justify-between items-center">
                                    {item.value}
                                </dd>
                            </div>
                        ))}
                    </dl>

                    <div className="border-t border-gray-200 px-4 py-5">
                        <h4 className="text-md font-medium text-gray-900 mb-2">Daily Count of Patients and Providers</h4>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={sortedOrganizationStats}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis yAxisId="left" />
                                <YAxis yAxisId="right" orientation="right" />
                                <Tooltip />
                                <Legend />
                                <Line yAxisId="left" type="monotone" dataKey="patients" stroke="#8884d8" activeDot={{ r: 8 }} />
                                <Line yAxisId="right" type="monotone" dataKey="providers" stroke="#82ca9d" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-5">
                        <h4 className="text-md font-medium text-gray-900 mb-2">Top 5 Chief Complaints</h4>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={topOrganizationChiefComplaints}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="complaint" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="count" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                </div>
            ) : (
                <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                    <BuildingOfficeIcon className="h-12 w-12 mb-4 text-gray-400" />
                    <p>Select an organization to view details</p>
                </div>
            )}
        </div>
    );
}






























function PatientExperienceContent({ organizationData }) {
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedSpecialties, setSelectedSpecialties] = useState(['EMERGENCY_PHYSICIAN', 'ADVANCED_PRACTICE_PROVIDER']);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [users, setUsers] = useState([]);
    const [isLoadingDivisions, setIsLoadingDivisions] = useState(true);
    const beginningOfStructure = new Date("2024-08-28");


    const fetchUsers = useCallback(async () => {
        setIsLoadingDivisions(true);
        try {
            // Simulate fetching data by using the mock data
            const data = organizationData
                .flatMap(division =>
                    division.users
                        .filter(user => selectedSpecialties.includes(user.actor_specialty))
                        .map(user => ({
                            division: division.division,
                    actor_id: user.actor_id,
                    full_name: user.full_name,
                    email: user.email,
                    documents: user.documents,
                }))
            );

            setUsers(data);
            setFilteredUsers(data);

            // Extract unique divisions from user data
            const uniqueDivisions = Array.from(new Set(data.map(user => user.division)))
                .map(division => ({ id: division, name: division }));
            setDivisions(uniqueDivisions);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoadingDivisions(false);
        }
    }, [selectedSpecialties, selectedDivisions]);



    // Function to handle CSV export
    const handleExportCSV = () => {
        const providerData = users.map(user => {
            const filteredDocuments = user.documents.filter(doc => new Date(doc.created_at) >= beginningOfStructure);

            const documentCount = filteredDocuments.length;

            const averages = calculateComponentAverages(filteredDocuments);

            return {
                providerName: user.full_name,
                documentCount: documentCount,
                patientExperienceScore: calculatePatientExperienceScore(filteredDocuments),
                ...averages // Spread the averages into the row
            };
        });

        // Manually create CSV string
        const csvRows = [];
        const headers = [
            'Provider Name',
            'Number of Documents',
            'Patient Experience Score',
            'Average Acknowledged Patient',
            'Average Introduced Themselves',
            'Average Provided Duration',
            'Average Provided Explanation',
            'Average Thanked Patient'
        ];
        csvRows.push(headers.join(','));

        providerData.forEach(row => {
            const values = [
                row.providerName,
                row.documentCount,
                row.patientExperienceScore,
                row.averageAcknowledgedPatient,
                row.averageIntroducedThemselves,
                row.averageProvidedDuration,
                row.averageProvidedExplanation,
                row.averageThankedPatient
            ];
            csvRows.push(values.join(','));
        });

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'provider_patient_experience.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Function to calculate patient experience score
    const calculatePatientExperienceScore = (documents) => {
        if (documents.length === 0) return 0;

        const totalScore = documents.reduce((sum, doc) => {
            const score = (
                (doc.acknowledged_patient ? 1 : 0) +
                (doc.introduced_themselves ? 1 : 0) +
                (doc.provided_duration ? 1 : 0) +
                (doc.provided_explanation ? 1 : 0) +
                (doc.thanked_patient ? 1 : 0)
            ) / 5;
            return sum + score;
        }, 0);

        return (totalScore / documents.length).toFixed(2);
    };

    // Function to calculate averages for each component of patient experience score
    const calculateComponentAverages = (documents) => {
        const totalAcknowledgedPatient = documents.reduce((sum, doc) => sum + (doc.acknowledged_patient ? 1 : 0), 0);
        const totalIntroducedThemselves = documents.reduce((sum, doc) => sum + (doc.introduced_themselves ? 1 : 0), 0);
        const totalProvidedDuration = documents.reduce((sum, doc) => sum + (doc.provided_duration ? 1 : 0), 0);
        const totalProvidedExplanation = documents.reduce((sum, doc) => sum + (doc.provided_explanation ? 1 : 0), 0);
        const totalThankedPatient = documents.reduce((sum, doc) => sum + (doc.thanked_patient ? 1 : 0), 0);

        const documentCount = documents.length;

        return {
            averageAcknowledgedPatient: (totalAcknowledgedPatient / documentCount).toFixed(2),
            averageIntroducedThemselves: (totalIntroducedThemselves / documentCount).toFixed(2),
            averageProvidedDuration: (totalProvidedDuration / documentCount).toFixed(2),
            averageProvidedExplanation: (totalProvidedExplanation / documentCount).toFixed(2),
            averageThankedPatient: (totalThankedPatient / documentCount).toFixed(2)
        };
    };



    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = users.filter(user =>
                user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filtered);
            setSelectedDivisions([]); // Clear selected divisions when searching
        } else if (selectedDivisions.length > 0) {
            const filtered = users.filter(user => selectedDivisions.includes(user.division));
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users);
        }
    }, [searchTerm, selectedDivisions, users]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            setSelectedDivisions([]);
        }
    };


    const handleDivisionToggle = (division) => {
        setSelectedDivisions(prevSelected => {
            if (prevSelected.includes(division.id)) {
                // Deselect the division if it's already selected
                return [];
            } else {
                // Select only the clicked division
                return [division.id];
            }
        });
        setSearchTerm('');
        setSelectedUser(null);
    };

    const handleSpecialtyToggle = (specialty) => {
        setSelectedSpecialties(prevSelected => {
            if (prevSelected.includes(specialty.id)) {
                return prevSelected.filter(s => s !== specialty.id);
            } else {
                return [...prevSelected, specialty.id];
            }
        });
        setSearchTerm('');
        setSelectedUser(null);
    };

    const specialties = [
        { id: 'EMERGENCY_PHYSICIAN', name: 'Physician' },
        { id: 'ADVANCED_PRACTICE_PROVIDER', name: 'APP' },
    ];

    const selectedDivision = selectedDivisions.length === 1
        ? organizationData.find(div => div.division === selectedDivisions[0])
        : null;

    return (
        <div className="flex flex-col w-full bg-gray-100">
            <div className="bg-white">
                <div className="px-4 sm:px-6 lg:px-8 py-6 flex justify-between items-center">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Patient Experience Dashboard</h1>
                    <button
                        className="bg-indigo-600 text-white py-2 px-4 rounded-md"
                        onClick={handleExportCSV}
                    >
                        Export CSV
                    </button>
                </div>
            </div>
            <div className="bg-white border-b border-gray-200 px-4 pb-2">
                <div className="max-w-md w-full lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Search for users"
                            type="search"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-1 overflow-hidden">
                {/* Specialties column */}
                <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Specialties</h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className={`p-4 cursor-pointer hover:bg-gray-50 text-gray-700`}
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                        className={`${selectedSpecialties.includes(specialty.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id) ? 'translate-x-5' : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* Divisions column */}
                <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Divisions</h2>
                    {isLoadingDivisions ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
                        </div>
                    ) : divisions.length > 0 ? (
                        <ul className="divide-y divide-gray-200">
                            {divisions.map((division) => (
                                <li
                                    key={division.id}
                                    className={`p-4 cursor-pointer hover:bg-gray-50 text-gray-700`}
                                    onClick={() => handleDivisionToggle(division)}
                                >
                                    <div className="flex items-center">
                                        <Switch
                                            checked={selectedDivisions.includes(division.id)}
                                            onChange={() => handleDivisionToggle(division)}
                                            onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                            className={`${selectedDivisions.includes(division.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={`${selectedDivisions.includes(division.id) ? 'translate-x-5' : 'translate-x-0'
                                                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                            />
                                        </Switch>
                                        <BuildingOfficeIcon className="h-5 w-5 ml-2 text-gray-400" />
                                        <span className="ml-2">{division.name}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="h-12 w-12 mb-4 text-gray-400" />
                            <p>No divisions found</p>
                        </div>
                    )}
                </div>

                {/* Users column */}
                <div className="w-80 bg-white border-r border-gray-200 overflow-y-auto">
                    <h2 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">Users</h2>
                    {searchTerm ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={`flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50 ${selectedUser?.actor_id === user.actor_id ? 'bg-indigo-50 text-indigo-600' : 'text-gray-700'}`}
                                        onClick={() => setSelectedUser(user)}
                                    >
                                        <div className="min-w-0 flex-grow">
                                            <div className="flex items-start gap-x-3">
                                                <UserIcon className="h-5 w-5 text-gray-400" />
                                                <p className="text-sm font-semibold leading-6">{user.full_name}</p>
                                            </div>
                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                <p className="truncate">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className={"h-5 w-5 text-gray-400"}
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                                <UserGroupIcon className="h-12 w-12 mb-4 text-gray-400" />
                                <p>No users found matching your search</p>
                            </div>
                        )
                    ) : selectedDivisions.length > 0 ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={`flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50 ${selectedUser?.actor_id === user.actor_id ? 'bg-indigo-50 text-indigo-600' : 'text-gray-700'}`}
                                        onClick={() => setSelectedUser(user)}
                                    >
                                        <div className="min-w-0 flex-grow">
                                            <div className="flex items-start gap-x-3">
                                                <UserIcon className="h-5 w-5 text-gray-400" />
                                                <p className="text-sm font-semibold leading-6">{user.full_name}</p>
                                            </div>
                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                <p className="truncate">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className={"h-5 w-5 text-gray-400"}
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                                <UserGroupIcon className="h-12 w-12 mb-4 text-gray-400" />
                                <p>No users found in the selected divisions</p>
                            </div>
                        )
                    ) : (
                        <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="h-12 w-12 mb-4 text-gray-400" />
                            <p>Select divisions to view users</p>
                        </div>
                    )}
                </div>

                {/* Details column */}
                {selectedUser ? (
                    <PatientExperienceDetailsColumn
                        selectedEntity={selectedUser}
                        entityType={"user"}
                    />
                ) : selectedDivision ? (
                    <PatientExperienceDetailsColumn
                        selectedEntity={selectedDivision}
                        entityType={"division"}
                    />
                ) : selectedSpecialties.length === 1 ? (
                    <PatientExperienceDetailsColumn
                        selectedEntity={organizationData}
                        entityType={"specialty"}
                    />
                ) : (
                    <PatientExperienceDetailsColumn
                        selectedEntity={organizationData}
                        entityType={"organization"}
                    />
                )}
            </div>
        </div>
    );
}




function PatientExperienceDetailsColumn({ selectedEntity, entityType }) {
    const [entityUsageStats, setEntityUsageStats] = useState([]);
    const [showWarnings, setShowWarnings] = useState(true);
    const [forceResolve, setForceResolve] = useState(false);
    const [autoAudit, setAutoAudit] = useState(true);
    const [allDocs, setAllDocs] = useState([]);
    const [filteredDocs, setFilteredDocs] = useState([]);
    const [maternalAgedFemaleDocs, setMaternalAgedFemaleDocs] = useState([]);
    const [specialty, setSpecialty] = useState(null);
    const [division, setDivision] = useState(null);
    const [specialtyType, setSpecialtyType] = useState("Specialty");
    const [recentStats, setRecentStats] = useState([]);
    const beginningOfStructure = new Date("2024-08-28");

    const calculateDocs = () => {
        let allDocs;
        if (entityType === 'user') {
            allDocs = selectedEntity.documents;
        } else if (entityType === 'division') {
            allDocs = selectedEntity.users.flatMap(user => user.documents);
        } else if (entityType === 'specialty') {
            allDocs = selectedEntity.flatMap(division =>
                division.users
                    .filter(user => user.actor_specialty.toLowerCase() === specialty.toLowerCase())
                    .flatMap(user => user.documents)
            );
        } else if (entityType === 'organization') {
            allDocs = selectedEntity.flatMap(division =>
                division.users.flatMap(user => user.documents)
            );
        } else {
            console.error('Unknown entity type:', entityType);
            allDocs = [];
        }
        setAllDocs(allDocs);
        return allDocs;
    };

    const filterDocs = (docs) => {
        return docs.filter((doc) => new Date(doc.created_at) >= beginningOfStructure);
    };

    useEffect(() => {
        if (selectedEntity) {
            const allDocsResponse = calculateDocs();

            const docs = filterDocs(allDocsResponse);
            setFilteredDocs(docs);

            const usageStats = docs.reduce((acc, doc) => {
                const date = doc.created_at;
                const existingEntry = acc.find(entry => entry.date === date);
                
                const acknowledgedPatient = doc.acknowledged_patient ? 1 : 0;
                const introducedThemselves = doc.introduced_themselves ? 1 : 0;
                const askedOpenEndedQuestions = doc.asked_open_ended_questions ? 1 : 0;
                const providedExplanation = doc.provided_explanation ? 1 : 0;
                const providedDuration = doc.provided_duration ? 1 : 0;
                const usedWhiteboard = doc.used_whiteboard ? 1 : 0;
                const reflectivelyListened = doc.reflectively_listened ? 1 : 0;
                const transcriptionTime = parseFloat(doc.transcription_time || 0); 
                const askedPatientOpinion = doc.asked_patient_opinion ? 1 : 0;
                const askedHowElseCanBeHelped = doc.asked_how_else_can_be_helped ? 1 : 0;
                const askedPatientIfQuestions = doc.asked_patient_if_questions ? 1 : 0;
                const thankedPatient = doc.thanked_patient ? 1 : 0;
                const managedUpCareTeam = doc.managed_up_care_team ? 1 : 0;
                const patientExperienceScore = (
                    (acknowledgedPatient + introducedThemselves + providedDuration + providedExplanation + thankedPatient) / 5
                );
                
                if (existingEntry) {
                    existingEntry.document_count += 1;
                    existingEntry.acknowledged_patient += acknowledgedPatient;
                    existingEntry.introduced_themselves += introducedThemselves;
                    existingEntry.asked_open_ended_questions += askedOpenEndedQuestions;
                    existingEntry.provided_explanation += providedExplanation;
                    existingEntry.provided_duration += providedDuration;
                    existingEntry.used_whiteboard += usedWhiteboard;
                    existingEntry.transcription_time += transcriptionTime;
                    existingEntry.reflectively_listened += reflectivelyListened;
                    existingEntry.total_patient_experience_score += parseFloat(patientExperienceScore);
                } else {
                    acc.push({
                        date,
                        document_count: 1,
                        introduced_themselves: introducedThemselves,
                        asked_open_ended_questions: askedOpenEndedQuestions,
                        provided_explanation: providedExplanation,
                        provided_duration: providedDuration,
                        used_whiteboard: usedWhiteboard,
                        reflectively_listened: reflectivelyListened,
                        transcription_time: transcriptionTime,
                        asked_patient_opinion: askedPatientOpinion,
                        asked_how_else_can_be_helped: askedHowElseCanBeHelped,
                        asked_patient_if_questions: askedPatientIfQuestions,
                        thanked_patient: thankedPatient,
                        managed_up_care_team: managedUpCareTeam,
                        total_patient_experience_score: parseFloat(patientExperienceScore),
                    });
                }
                return acc;
            }, []).sort((a, b) => new Date(a.date) - new Date(b.date));

            const processedStats = usageStats.map(stat => ({
                ...stat,
                avg_patient_experience_score: parseFloat((stat.total_patient_experience_score / stat.document_count).toFixed(2)),
                avg_encounter_time_minutes: parseFloat((stat.transcription_time / stat.document_count) / 60).toFixed(2),
            }));

            const sevenDaysAgo = subDays(new Date(), 7);
            const recentStats = processedStats.filter(stat =>
                parseISO(stat.date) >= sevenDaysAgo
            );

            setEntityUsageStats(processedStats);
            setRecentStats(recentStats);
        }
    }, [selectedEntity]);

    const aggregateData = (data) => {
        return data.reduce((acc, current) => {
            acc.acknowledged_patient += current.acknowledged_patient;
            acc.introduced_themselves += current.introduced_themselves;
            acc.provided_explanation += current.provided_explanation;
            acc.asked_open_ended_questions += current.asked_open_ended_questions;
            acc.provided_duration += current.provided_duration;
            acc.used_whiteboard += current.used_whiteboard;
            acc.reflectively_listened += current.reflectively_listened;
            acc.transcription_time += current.transcription_time;
            acc.patient_experience_score += current.patient_experience_score;
            acc.asked_patient_opinion += current.asked_patient_opinion;
            acc.asked_how_else_can_be_helped += current.asked_how_else_can_be_helped;
            acc.asked_patient_if_questions += current.asked_patient_if_questions;
            acc.thanked_patient += current.thanked_patient;
            acc.managed_up_care_team += current.managed_up_care_team;
            acc.used_whiteboard += current.used_whiteboard;
            return acc;
        }, {
            acknowledged_patient: 0,
            introduced_themselves: 0,
            provided_explanation: 0,
            asked_open_ended_questions: 0,
            provided_duration: 0,
            used_whiteboard: 0,
            reflectively_listened: 0,
            transcription_time: 0,
            patient_experience_score: 0,
            asked_patient_opinion: 0,
            asked_how_else_can_be_helped: 0,
            asked_patient_if_questions: 0,
            thanked_patient: 0,
            managed_up_care_team: 0,
        });
    };

    const averagePatientExperienceScore = useMemo(() => {
        if (filteredDocs.length === 0) return 0;
        const totalScore = filteredDocs.reduce((sum, doc) => {
            const score = (
                (doc.acknowledged_patient ? 1 : 0) +
                (doc.introduced_themselves ? 1 : 0) +
                // (doc.asked_open_ended_questions ? 1 : 0) +
                (doc.provided_duration ? 1 : 0) +
                (doc.provided_explanation ? 1 : 0) +
                // (doc.reflectively_listened ? 1 : 0) +
                (doc.thanked_patient ? 1 : 0)
                // (doc.asked_patient_if_questions ? 1 : 0) +
                // (doc.asked_how_else_can_be_helped ? 1 : 0) +
                // (doc.asked_patient_opinion ? 1 : 0) +
                // (doc.managed_up_care_team ? 1 : 0) +
                // (doc.used_whiteboard ? 1 : 0)
            ) / 5;
            return sum + score;
        }, 0);
        return (totalScore / filteredDocs.length).toFixed(2);
    }, [filteredDocs]);

    
    return (
        <div className="flex-1 bg-white overflow-y-auto">
            <h1 className="text-lg font-semibold p-4 text-gray-700 border-b border-gray-200">
                {entityType === 'user' && selectedEntity.full_name}
                {entityType === 'division' && selectedEntity.division}
                {entityType === 'specialty' && specialtyType}
                {entityType === 'organization' && 'Organization'}
                {' '}Patient Experience Analysis
            </h1>
            <div className="p-4"> 
                <div className="text-center my-8">
                    <h2 className="text-2xl font-semibold text-gray-700 mb-2">Patient Satisfaction Score</h2>
                    <div className="text-6xl font-bold text-[#82ca9d]">
                        {averagePatientExperienceScore}
                    </div>
                </div>

                <div className="mt-12">
                    <h2 className="text-2xl font-medium text-gray-900 mb-4">
                        Patients Seen vs Encounter Time
                    </h2>
                    {entityUsageStats.length === 0 ? (
                        <div className="flex justify-center items-center h-48">
                            <p className="text-sm text-gray-500">No data available</p>
                        </div>
                    ) : (
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={entityUsageStats}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis yAxisId="left" />
                                <YAxis 
                                    yAxisId="right" 
                                    orientation="right" 
                                    tickFormatter={(value) => `${value.toFixed(0)}min`}
                                />
                                <Tooltip
                                    formatter={(value, name) => {
                                        if (name === "avg_encounter_time_minutes") {
                                            return [typeof value === 'number' ? `${value.toFixed(1)} min` : value, "Avg Encounter Time"];
                                        }
                                        return [value, name];
                                    }}
                                />
                                <Legend />
                                <Line yAxisId="left" type="monotone" dataKey="document_count" stroke="#8884d8" activeDot={{ r: 8 }} />
                                <Line yAxisId="right" type="monotone" dataKey="avg_encounter_time_minutes" stroke="#82ca9d" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>

                    )}
                </div>
                <div className="mt-12">
                    <h1 className="text-xl font-medium text-gray-900 mb-4">
                        Patient Experience Score Breakdown
                    </h1>

                    {/* <h2 className="text-lg py-4 font-semibold text-gray-700 border-b border-gray-200">Messaging Configuration</h2> */}
                    <div className="flex justify-start items-left space-x-6 mt-4">
                        {/* Show warnings toggle */}
                        <div className="flex items-center space-x-4">
                            <Switch
                                checked={showWarnings}
                                onChange={setShowWarnings}
                                className={`${showWarnings ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-8 w-16 items-center rounded-full`}
                            >
                                <span className={`${showWarnings ? 'translate-x-8' : 'translate-x-1'} inline-block h-6 w-6 transform rounded-full bg-white transition`} />
                            </Switch>
                            <span className="text-base font-semibold text-gray-700">
                                Message In-App
                            </span>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Switch
                                checked={forceResolve}
                                onChange={setForceResolve}
                                className={`${forceResolve ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-8 w-16 items-center rounded-full`}
                            >
                                <span className={`${forceResolve ? 'translate-x-8' : 'translate-x-1'} inline-block h-6 w-6 transform rounded-full bg-white transition`} />
                            </Switch>
                            <span className="text-base font-semibold text-gray-700">
                                Push Notify
                            </span>
                        </div>
                    </div>

                    <h3 className="text-xl py-4 mt-8 mb-2 font-semibold text-gray-700">Statistics</h3>









    <dl className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-x-4 gap-y-8 mb-8 border-t border-gray-300 pt-8">
    {[
        {
            label: "Acknowledge",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.acknowledged_patient);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "Introduce",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.introduced_themselves);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "Duration",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.provided_duration);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "Explanation",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.provided_explanation);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "Thank You",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.thanked_patient);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "Questions?",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.asked_patient_if_questions);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "How Else to Help?",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.asked_how_else_can_be_helped);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "Patient Opinion",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.asked_patient_opinion);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "Managed-Up Care Team",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.managed_up_care_team);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "Used Whiteboard",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.used_whiteboard);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "Reflective Listening",
            value: (() => {
                const subset = filteredDocs.filter(doc => doc.reflectively_listened);
                return `${((subset.length / filteredDocs.length) * 100).toFixed(0)}%`;
            })(),
        },
        {
            label: "Avg Encounter Time",
            value: (() => {
                const totalTime = filteredDocs.reduce((sum, doc) => sum + (doc.transcription_time || 0), 0);
                const averageTime = filteredDocs.length > 0 ? totalTime / filteredDocs.length : 0;
                const minutes = Math.floor(averageTime / 60);
                const seconds = Math.round(averageTime % 60);
                return `${minutes}:${seconds.toString().padStart(2, '0')}`;
            })(),
        },
    ].map((item) => (
        <div key={item.label} className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow duration-200">
            <dt className="text-sm font-medium text-gray-500 truncate">{item.label}</dt>
            <dd className="mt-1 text-2xl font-semibold text-indigo-600">{item.value}</dd>
        </div>
    ))}
</dl>






                    {entityUsageStats.length === 0 ? (
                        <div className="flex justify-center items-center h-48">
                            <p className="text-sm text-gray-500">No data available</p>
                        </div>
                    ) : (
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={[aggregateData(entityUsageStats)]} barCategoryGap="20%">
                                <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                                <YAxis tick={{ fill: '#6b7280' }} />
                                <Tooltip cursor={{ fill: 'rgba(156, 163, 175, 0.1)' }} />
                                <Legend 
                                    wrapperStyle={{ 
                                        color: '#4f46e5', 
                                        fontSize: '14px', 
                                        fontWeight: '500',
                                        backgroundColor: '#f9fafb',
                                        borderRadius: '8px',
                                        padding: '8px',
                                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
                                    }} 
                                />
                                <Bar dataKey="acknowledged_patient" fill="#6a0dad" name="Acknowledged Patient" />
                                <Bar dataKey="introduced_themselves" fill="#4c51bf" name="Made Introduction" />
                                <Bar dataKey="provided_duration" fill="#60a5fa" name="" />
                                <Bar dataKey="provided_explanation" fill="#34d399" name="Summarize Encounter" />
                                <Bar dataKey="thanked_patient" fill="#34d399" name="Thanked Patient" />
                                <Bar dataKey="asked_patient_if_questions" fill="#f59e0b" name="Asked Patient If Questions" />
                                <Bar dataKey="asked_how_else_can_be_helped" fill="#f59e0b" name="Asked How Else Can Be Helped" />
                                <Bar dataKey="asked_patient_opinion" fill="#f59e0b" name="Asked Patient Opinion" />
                                <Bar dataKey="asked_open_ended_questions" fill="#f59e0b" name="Asked Open-Ended Questions" />
                                <Bar dataKey="managed_up_care_team" fill="#f59e0b" name="Up-Managed Care Team" />
                                <Bar dataKey="used_whiteboard" fill="#8b5cf6" name="Used Whiteboard" />
                                <Bar dataKey="reflectively_listened" fill="#10b981" name="Used Reflective Listening" />
                            </BarChart>
                        </ResponsiveContainer>

                    )}
                </div>
            </div>
        </div>
    );
}



