import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useLocation, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit, faCopy, faCheck, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'

import { Fieldset } from '../components/catalyst/fieldset';
import { Text } from '../components/catalyst/text';
import { Button } from '../components/catalyst/button';
import '../styles/ViewReports.css';

function FlightInfo() {
    const [reports, setReports] = useState('');
    const { instance, accounts } = useMsal();
    const [copied, setCopied] = useState(false);
    const [isEditingReports, setIsEditingReports] = useState(false);
    const location = useLocation();
    const document = location.state?.document;
    const navigate = useNavigate();
    const [editedDocument, setEditedDocument] = useState(document);


    const handleTokenExpiration = (error) => {
        if (error instanceof InteractionRequiredAuthError) {
            // Token expired, log the user out
            instance.logoutRedirect(); // or instance.logoutPopup()
        } else {
            console.error('Error:', error);
        }
    };

    const updateReports = (newReports) => {
        setReports(newReports);
        setEditedDocument(prev => ({ ...prev, document_content: newReports }));
    };

    const saveDocument = async () => {
        // Check if there are changes
        const hasChanges = (
            document.document_content !== reports
        );

        if (!hasChanges) {
            console.log("No changes to save.");
            return; // Exit if no changes
        }

        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({"document_id": document.document_id, "field_to_update": "document_content", "new_value": reports})
            };

            const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
            const data = await fetchResponse.json();

            // Handle response. For example, you might want to update the document state
            if (data.updatedDocument) {
                // Update your document state here if necessary
            }
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }
    };

    const approveDocument = async () => {
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({"document_id": document.document_id, "field_to_update": "document_status", "new_value": "FINALIZED"})
            };

            const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
            const data = await fetchResponse.json();

            navigate('/aviation-dashboard');
            
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }  
    };

    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text);
        if (type === 'reports') {
            setCopied(true);
            setTimeout(() => setCopied(false), 3000);
        } 
    };
    const goBack = () => {
        console.log('Navigating back to dashboard');
        navigate('/aviation-dashboard'); // Adjust the path as needed for your dashboard route
    };

    useEffect(() => {
        if (document) {
            setReports(document.document_content);
        }
    }, [document]);

    const formatTime = (utcString) => {

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const utcDate = new Date(utcString);
        const formatter = new Intl.DateTimeFormat(undefined, options);
        return formatter.format(utcDate);
    };

    const formatDate = (utcString) => {
        const date = new Date(utcString);

        const options = { month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    };



    return (
        <div className="max-w-7xl mx-auto px-5">
            {/* Document Details Section as a Horizontal Header */}
            <div className="header-container mb-16 mt-6 w-full">
                {/* Header Row with Go Back Button and Documentation Heading */}
                <div className="header-row flex justify-between items-center">
                    {/* Go Back Button */}
                    <Button color="indigo" onClick={goBack} className="go-back-button">
                        <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                        Note Dashboard
                    </Button>
                    {/* Left Placeholder for balancing */}
                    <div className="header-placeholder"></div>

                    <Button color="indigo" onClick={approveDocument} className="go-back-button">
                        <FontAwesomeIcon icon={faCheck} className="mr-0" />
                        Finalize
                    </Button>


                </div>

                {/* Document Details Row */}
                {document && (
                    <div className="document-details-card mt-4 bg-gray-100">
                        {/* Encounter Date Detail */}
                        <div className="document-detail">
                            <Text className="detail-heading">Flight Date:</Text>
                            <Text className="detail-content">{formatDate(document.created_at)} at {formatTime(document.created_at)}</Text>
                        </div>

                        {/* Visit Description Detail */}
                        <div className="document-detail">
                            <Text className="detail-heading">Flight Name:</Text>
                            <Text className="detail-content">{document.procedure_description}</Text>
                        </div>
                    </div>
                )}
            </div>

            <div style={{ display: 'flex', height: '80vh', gap: '50px', marginBottom: '50px' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden">
                        <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                            <div className="font-bold text-slate-700 font-sans text-xl pl-2">Debrief Summary</div>
                            <div className="flex justify-around">
                                {isEditingReports ? (
                                    <Fieldset
                                        className="rounded p-2 cursor-pointer"
                                        onClick={() => { setIsEditingReports(false); saveDocument(); }}>
                                        Save
                                        <FontAwesomeIcon icon={faSave} className="text-indigo-900 ml-2 mr-4" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded p-2 cursor-pointer"
                                        onClick={() => setIsEditingReports(true)}>
                                        Edit
                                        <FontAwesomeIcon icon={faEdit} className="text-indigo-900 ml-2 mr-4" />
                                    </Fieldset>
                                )}
                                {copied ? (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(reports, 'reports')}>
                                        Copied!
                                        <FontAwesomeIcon icon={faCheck} className="text-indigo-900 ml-2" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(reports, 'reports')}>
                                        Copy
                                        <FontAwesomeIcon icon={faCopy} className="text-indigo-900 ml-2" />
                                    </Fieldset>
                                )}
                            </div>
                        </Fieldset>
                        <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-hidden">
                            {isEditingReports ? (
                                <textarea
                                    className="constant-size-textarea w-full border-none outline-none resize-none bg-gray-100 text-slate-700"
                                    value={reports}
                                    onChange={(e) => updateReports(e.target.value)}
                                />
                            ) : (
                                <div className="constant-size-textarea w-full border-none outline-none text-slate-600 whitespace-pre-line overflow-y-auto">
                                    {reports || 'No content available'}
                                </div>
                            )}
                        </div>
                    </Fieldset>
                </div>
            </div>
        </div>
    );
}

export default FlightInfo;
