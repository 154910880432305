import React, { useState, useEffect, useCallback } from 'react';
import { MdPerson, MdReportProblem, MdDateRange } from 'react-icons/md';
import { FiClipboard, FiCheck, FiEdit, FiSave, FiArrowLeft } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { protectedResources } from '../authConfig';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";


function ViewERPatientInfo() {
    const location = useLocation();
    const document = location.state?.document;
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();

    const [clinicalNote, setClinicalNote] = useState('');
    const [orderSet, setOrderSet] = useState('');
    const [dischargeReport, setDischargeReport] = useState('');
    const [differentialDiagnosis, setDifferentialDiagnosis] = useState('');
    const [presentingHistoryOfIllness, setPresentingHistoryOfIllness] = useState('');

    const [isEditingClinicalNote, setIsEditingClinicalNote] = useState(false);
    //const [isEditingOrderSet, setIsEditingOrderSet] = useState(false);
    const [isEditingDischargeReport, setIsEditingDischargeReport] = useState(false);
    //const [isEditingDifferentialDiagnosis, setIsEditingDifferentialDiagnosis] = useState(false);
    //const [isEditingPresentingHistoryOfIllness, setIsEditingPresentingHistoryOfIllness] = useState(false);

    const [copiedClinicalNote, setCopiedClinicalNote] = useState(false);
    // const [copiedOrderSet, setCopiedOrderSet] = useState(false);
    const [copiedDischargeReport, setCopiedDischargeReport] = useState(false);
    // const [copiedDifferentialDiagnosis, setCopiedDifferentialDiagnosis] = useState(false);
    // const [copiedPresentingHistoryOfIllness, setCopiedPresentingHistoryOfIllness] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);


    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text);
        if (type === 'clinicalNote') {
            setCopiedClinicalNote(true);
            setTimeout(() => setCopiedClinicalNote(false), 3000);
        }
        // if (type === 'orderSet') {
        //     setCopiedOrderSet(true);
        //     setTimeout(() => setCopiedOrderSet(false), 3000);
        // }
        if (type === 'dischargeReport') {
            setCopiedDischargeReport(true);
            setTimeout(() => setCopiedDischargeReport(false), 3000);
        }
        // if (type === 'differentialDiagnosis') {
        //     setCopiedDifferentialDiagnosis(true);
        //     setTimeout(() => setCopiedDifferentialDiagnosis(false), 3000);
        // }
        // if (type === 'presentingHistoryOfIllness') {
        //     setCopiedPresentingHistoryOfIllness(true);
        //     setTimeout(() => setCopiedPresentingHistoryOfIllness(false), 3000);
        // }
    };
    const goBack = () => {
        console.log('Navigating back to dashboard');
        navigate('/er-dashboard'); // Adjust the path as needed for your dashboard route
    };

    useEffect(() => {
        if (document) {
            setClinicalNote(document.document_content || '');
            setOrderSet(document.code_suggestions || '');
            setDischargeReport(document.discharge_report || '');
            setDifferentialDiagnosis(document.differential_diagnosis || '');
            setPresentingHistoryOfIllness(document.presenting_history || '');
        }
    }, [document]);

    const approveDoc = useCallback(async () => {
        setIsLoading(true);
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiApproveDocument.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ document_id: document.document_id })
                };

                const fetchResponse = await fetch(protectedResources.apiApproveDocument.endpoint, requestOptions);
                const data = await fetchResponse.json();
                console.log(data)

                navigate('/er-dashboard');

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error approving document:', error);
            }
        } setIsLoading(false);
    }, [accounts, instance, navigate, handleTokenExpiration, document]);

    return (

        isLoading ? (
            <div className="loading-overlay">
                <div className="spinner"></div>
            </div>
        ) : (
            <div style={{ maxWidth: '1200px', margin: 'auto', padding: '20px' }}>
                <div>
                    <button onClick={() => {
                        approveDoc();
                    }} style={{ cursor: 'pointer', background: 'none', border: 'none', display: 'flex', alignItems: 'center', color: '#6C8EBF', fontSize: '18px' }}>
                        Approve
                    </button>
                </div>
                {/* Stylish Back Button */}
                <div style={{ marginBottom: '20px' }}>
                    <button onClick={goBack} style={{ cursor: 'pointer', background: 'none', border: 'none', display: 'flex', alignItems: 'center', color: '#6C8EBF', fontSize: '18px' }}>
                        <FiArrowLeft style={{ marginRight: '10px' }} />
                        Back to Dashboard
                    </button>
                </div>
                {/* Document Details Section as a Horizontal Header */}
                {document && (
                    <div style={{ marginBottom: '30px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <MdPerson style={{ fontSize: '24px' }} />
                            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{document.patient_name}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <MdReportProblem style={{ fontSize: '24px' }} />
                            <span style={{ fontSize: '18px' }}>{document.chief_complaint}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <MdDateRange style={{ fontSize: '24px' }} />
                            <span style={{ fontSize: '18px' }}>{new Date(document.created_at).toLocaleDateString()}</span>
                        </div>
                    </div>
                )}

                <h1 style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', paddingBottom: '40px', paddingTop: '20px' }}>Triage Context</h1>

                {/* TRIAGE-based Section for vitals, orderSet, and presentingHistoryOfIllness */}
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', marginBottom: '20px' }}>
                    <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '20px', backgroundColor: 'white', height: '400px', overflowY: 'auto' }}>
                        <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>Vitals</h2>
                        <p style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>
                            {document.vitals || 'No vitals available'}
                        </p>
                    </div>
                    <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '20px', backgroundColor: 'white', height: '400px', overflowY: 'auto' }}>
                        <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>Presenting History of Illness</h2>
                        <p style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>
                            {presentingHistoryOfIllness || 'No presenting history of illness available'}
                        </p>
                    </div>
                </div>

                <h1 style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', paddingBottom: '40px', paddingTop: '40px' }}>CLEO's Interpretation</h1>

                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', marginBottom: '20px' }}>
                    <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '20px', backgroundColor: 'white', height: '400px', overflowY: 'auto' }}>
                        <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>Suggested Differential Diagnosis</h2>
                        <p style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>
                            {differentialDiagnosis || 'No presenting history of illness available'}
                        </p>
                    </div>
                    <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '20px', backgroundColor: 'white', height: '400px', overflowY: 'auto' }}>
                        <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>Suggested Order Set</h2>
                        <p style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>
                            {orderSet || 'No order set available'}
                        </p>
                    </div>
                </div>

                <h1 style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', paddingBottom: '40px', paddingTop: '40px' }}>Documentation Assistance</h1>

                {/* Document Sections */}
                <div style={{ display: 'flex', height: '80vh', gap: '20px', marginBottom: '40px' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Clinical Note</span>
                            <button
                                onClick={() => setIsEditingClinicalNote(!isEditingClinicalNote)}
                                style={{ alignItems: 'center', display: 'flex', gap: '5px', cursor: 'pointer', color: '#6C8EBF', background: 'none', border: 'none', padding: '0' }}
                            >
                                {isEditingClinicalNote ? <><FiSave /> Save Edits</> : <><FiEdit /> Edit</>}
                            </button>
                            {copiedClinicalNote ? (
                                <span style={{ alignItems: 'center', color: '#6C8EBF', display: 'flex', gap: '5px', cursor: 'pointer' }}>
                                    <FiCheck /> Copied!
                                </span>
                            ) : (
                                <span onClick={() => copyToClipboard(clinicalNote, 'clinicalNote')} style={{ alignItems: 'center', color: '#6C8EBF', display: 'flex', gap: '5px', cursor: 'pointer' }}>
                                    <FiClipboard /> Copy Note
                                </span>
                            )}
                        </div>
                        <div style={{ border: '1px solid #ccc', borderRadius: '10px', backgroundColor: 'white', color: '#2C6975', height: '100%', overflow: 'hidden' }}>
                            <div style={{ padding: '30px', height: '100%', overflowY: 'auto' }}>
                                {isEditingClinicalNote ? (
                                    <textarea
                                        value={clinicalNote}
                                        onChange={(e) => setClinicalNote(e.target.value)}
                                        style={{ width: '100%', height: '100%', border: 'none', outline: 'none', resize: 'none', backgroundColor: '#f4e8e1', color: '#2C6975', boxSizing: 'border-box', margin: 0 }}
                                    />
                                ) : (
                                    <div style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>
                                        {clinicalNote || 'No clinical note available'}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>


                <div style={{ display: 'flex', height: '80vh', gap: '20px' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Discharge Report</span>
                            <button
                                onClick={() => setIsEditingDischargeReport(!isEditingDischargeReport)}
                                style={{ alignItems: 'center', display: 'flex', gap: '5px', cursor: 'pointer', color: '#6C8EBF', background: 'none', border: 'none', padding: '0' }}
                            >
                                {isEditingDischargeReport ? <><FiSave /> Save Edits</> : <><FiEdit /> Edit</>}
                            </button>
                            {copiedDischargeReport ? (
                                <span style={{ alignItems: 'center', color: '#6C8EBF', display: 'flex', gap: '5px', cursor: 'pointer' }}>
                                    <FiCheck /> Copied!
                                </span>
                            ) : (
                                <span onClick={() => copyToClipboard(dischargeReport, 'dischargeReport')} style={{ alignItems: 'center', color: '#6C8EBF', display: 'flex', gap: '5px', cursor: 'pointer' }}>
                                    <FiClipboard /> Copy DD
                                </span>
                            )}
                        </div>
                        <div style={{ border: '1px solid #ccc', borderRadius: '10px', backgroundColor: 'white', color: '#2C6975', height: '100%', overflow: 'hidden' }}>
                            <div style={{ padding: '30px', height: '100%', overflowY: 'auto' }}>
                                {isEditingDischargeReport ? (
                                    <textarea
                                        value={dischargeReport}
                                        onChange={(e) => setDischargeReport(e.target.value)}
                                        style={{ width: '100%', height: '100%', border: 'none', outline: 'none', resize: 'none', backgroundColor: '#f4e8e1', color: '#2C6975', boxSizing: 'border-box', margin: 0 }}
                                    />
                                ) : (
                                    <div style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>
                                        {dischargeReport || 'No discharge report available'}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    );
}

export default ViewERPatientInfo;
