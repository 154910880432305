import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useLocation, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave, faEdit, faChartLine, faCopy, faCheck,
    faArrowCircleLeft, faQuestionCircle, faFileCode,
    faCalculator, faChevronLeft, faChevronRight,
    faInfoCircle, faExclamationTriangle, faExclamationCircle,
    faSearch, faShield, faSync
} from '@fortawesome/free-solid-svg-icons'

import { Fieldset } from '../components/catalyst/fieldset';
import { Text, Strong } from '../components/catalyst/text';
//import { Textarea } from '../components/catalyst/textarea';
import { Button } from '../components/catalyst/button';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/catalyst/dialog';
import '../styles/ViewReports.css';

import {
    CheckCircleIcon, XMarkIcon, XCircleIcon, ExclamationCircleIcon,
    ShieldCheckIcon, InformationCircleIcon, ExclamationTriangleIcon, ChatBubbleLeftIcon,
    CalculatorIcon
} from '@heroicons/react/20/solid';

import { useFeatureFlagEnabled } from 'posthog-js/react'

import TextareaAutosize from 'react-textarea-autosize';
import { PlusIcon } from '@heroicons/react/20/solid';

import ProgressBar from '../components/PatientViewer/ProgressBars';




function PatientInfoCarepoint() {
    const [hpi, setHPI] = useState('');
    const [ros, setROS] = useState('');
    const [exam, setExam] = useState('');
    const [reeval, setReeval] = useState('');
    const [mdm, setMDM] = useState('');
    const [riskAnalysis, setRiskAnalysis] = useState('');
    const [copa, setCopa] = useState(-1);
    const [complexityOfData, setComplexityOfData] = useState(-1);
    const [riskOfComplications, setRiskOfComplications] = useState(-1);
    const [riskScoreLabel, setRiskScoreLabel] = useState('');
    // const riskScores = ["HEART", "Wells", "CURB-65", "GCS", "PERC Rule"];
    const [currentRiskScoreKey, setCurrentRiskScoreKey] = useState("");
    const [showRiskScoreDropdown, setShowRiskScoreDropdown] = useState(false);
    const [showMacroDropdown, setShowMacroDropdown] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading Notes...');
    const [patientName, setPatientName] = useState('');
    const [documentStatus, setDocumentStatus] = useState('');
    const [procedureDescription, setProcedureDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMDM, setIsLoadingMDM] = useState(false);
    const { instance, accounts, inProgress } = useMsal();
    const [copiedHPI, setCopiedHPI] = useState(false);
    const [copiedROS, setCopiedROS] = useState(false);
    const [copiedExam, setCopiedExam] = useState(false);
    const [copiedReeval, setCopiedReeval] = useState(false);
    const [copiedMDM, setCopiedMDM] = useState(false);
    const [copiedAll, setCopiedAll] = useState(false);
    const [isEditingPatientName, setIsEditingPatientName] = useState(false);
    const [isEditingProcedureDescription, setIsEditingProcedureDescription] = useState(false);
    const [riskScoreNotificationVisible, setRiskScoreNotificationVisible] = useState(false);
    const [mdmCodingNotificationVisible, setMDMCodingNotificationVisible] = useState(false);
    const location = useLocation();
    const document = location.state?.document;
    const providedDate = location.state?.providedDate;
    const showMDM = location.state?.showMDM;
    const navigate = useNavigate();
    const [editedDocument, setEditedDocument] = useState(document);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
    const [showNotification, setShowNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [riskScore, setRiskScore] = useState(false);
    const [saveTriggered, setSaveTriggered] = useState(false);
    const [macro_list, setMacroList] = useState({});
    const riskScoreDropdownRef = useRef(null);
    const macroDropdownRef = useRef(null);
    const [isShowingMDM, setIsShowingMDM] = useState(showMDM || false);
    const [noteFeedbackNotificationVisible, setNoteFeedbackNotificationVisible] = useState(!document.note_rating);
    const [noteFeedback, setNoteFeedback] = useState('');
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [macroSearchQuery, setMacroSearchQuery] = useState('');
    const [originalMDM, setOriginalMDM] = useState(mdm);
    const [cleanedMDM, setCleanedMDM] = useState(mdm);
    const [isRemoved, setIsRemoved] = useState(false);
    const [auditChartMessages, setAuditChartMessages] = useState([]);
    const [hasAuditChartMessages, setHasAuditChartMessages] = useState(false);
    const [isShowingAuditChartMessages, setIsShowingAuditChartMessages] = useState(false);
    const [currentAuditChartMessageIndex, setCurrentAuditChartMessageIndex] = useState(0);

    const [riskMessages, setRiskMessages] = useState([]);
    const [hasRiskMessages, setHasRiskMessages] = useState(false);
    const [isShowingRiskMessages, setIsShowingRiskMessages] = useState(false);
    const [currentRiskMessageIndex, setCurrentRiskMessageIndex] = useState(0);
    const [resolvingRiskItem, setResolvingRiskItem] = useState(null);
    const [resolvedRiskItem, setResolvedRiskItem] = useState(null);

    const [riskScores, setRiskScores] = useState([]);
    const [currentRiskScoreId, setCurrentRiskScoreId] = useState(null);
    const [currentRiskScoreState, setCurrentRiskScoreState] = useState([]);

    const isMDMRecodeEnabled = useFeatureFlagEnabled('mdm_recode');
    const isBetaPosthog = useFeatureFlagEnabled('beta_user');
    const isCarepointPosthog = useFeatureFlagEnabled('carepoint');







    // RISK SCORE NOTIFICATION
    const RenderRiskScoreNotification = ({ riskScoreId }) => {
        const [currentRiskScoreState, setCurrentRiskScoreState] = useState([]);

        // Get the selected risk score based on the ID
        const currentRiskScore = riskScores.find(score => score.risk_score_id === riskScoreId);

        useEffect(() => {
            if (currentRiskScore) {
                // Initialize the risk score state with component names and initial null values
                const initialState = currentRiskScore.components.map(component => ({
                    component_name: component.component_name,
                    component_value: null // Set to null initially to indicate no selection
                }));
                setCurrentRiskScoreState(initialState);
            }
        }, [currentRiskScoreId, currentRiskScore]);

        // Handle changes in risk score options
        const handleRiskScoreChange = (componentName, value) => {
            setCurrentRiskScoreState(prevState =>
                prevState.map(item =>
                    item.component_name === componentName ? { ...item, component_value: value } : item
                )
            );
        };

        // Evaluate whether the score is complete and calculate the total score
        const evaluateRiskScore = () => {
            const scoreValues = currentRiskScoreState.map(item => item.component_value);
            const totalScore = scoreValues.reduce((total, current) => (current !== null ? total + current : total), 0);
            const isComplete = currentRiskScoreState.every(item => item.component_value !== null);

            return { totalScore, isComplete };
        };

        // Update the reevaluation summary and close the notification
        const updateReevaluationWithSummary = () => {
            // Calculate total score and completion status
            var totalScore = 0;

            // Generate the inputs summary
            const inputsSummary = currentRiskScore.components.map(component => {
                // Find the selected option by comparing component_value with risk_score_component_option_id
                const selectedValue = currentRiskScoreState.find(item => item.component_name === component.component_name)?.component_value;

                // Find the selected option and extract its actual option_value
                const selectedOption = component.options.find(option => option.risk_score_component_option_id === selectedValue);
                const optionValue = selectedOption ? selectedOption.option_value : 'Not selected';  // Get the value or 'Not selected'
                totalScore += optionValue;

                return `${component.component_label} (${optionValue}): ${selectedOption ? selectedOption.option_name : 'Not selected'}`;
            }).join('\n');

            // Include the total score at the top of the summary
            const summary = `${currentRiskScore.score_name} Calculation (Total Score = ${totalScore})\n\n${inputsSummary}`;

            // Update the reevaluation with the new summary
            const newReeval = `${reeval}\n\n${summary}`;

            updateReeval(newReeval);
            setSaveTriggered(true);
            setRiskScoreNotificationVisible(false);
        };




        return (
            currentRiskScore && (
                <Fieldset className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="border border-gray-300 shadow-lg rounded-lg overflow-hidden h-[85vh] w-[95vw] md:h-[75vh] md:w-[900px] flex flex-col bg-white">
                        <div className="flex justify-between items-center bg-indigo-900 text-white py-4 px-6">
                            <div className="font-bold text-xl text-center flex-grow">
                                {currentRiskScore.score_name}
                            </div>
                            <button onClick={() => setRiskScoreNotificationVisible(false)} className="ml-auto">
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="p-4 flex-grow overflow-auto max-h-[calc(100%-8rem)] space-y-4">
                            {currentRiskScore.components.map(component => (
                                <div className="w-full bg-gray-100 p-4 rounded-lg flex flex-col">
                                    <RenderRiskScoreOptions
                                        key={component.component_name}
                                        label={component.component_label}
                                        options={component.options}
                                        selectedValue={currentRiskScoreState.find(item => item.component_name === component.component_name)?.component_value}
                                        onChange={(value) => handleRiskScoreChange(component.component_name, value)}
                                        className="break-words whitespace-normal w-full" // Added class to handle long text wrapping
                                    />
                                </div>
                            ))}
                            <div className="bg-white p-4 flex flex-col">
                                {evaluateRiskScore().isComplete ? (
                                    <p className="mt-2">Score complete.</p>
                                ) : (
                                    <p className="flex items-center text-yellow-500 mt-2">
                                        <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                                        Complete items above to calculate {currentRiskScore.score_name} score
                                    </p>
                                )}
                                <div className="flex justify-center items-center mt-4">
                                    <button
                                        onClick={updateReevaluationWithSummary}
                                        className={`bg-indigo-900 hover:bg-indigo-900 text-white font-bold py-3 px-4 rounded ${!evaluateRiskScore().isComplete ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={!evaluateRiskScore().isComplete}
                                    >
                                        Copy and Save to Evaluations
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fieldset>
            )
        );

    };


    const RenderRiskScoreOptions = ({ label, options, selectedValue, onChange }) => {
        return (
            <div className="flex justify-between items-center w-full my-4 border-b border-gray-200 pb-3 px-3">
                <div className="text-sm font-semibold flex items-center">
                    {selectedValue === null && (
                        <span className="mr-2 h-3 w-3 bg-red-500 rounded-full inline-block"></span>
                    )}
                    {label}:
                </div>
                <div className="flex flex-col items-end w-[75%] space-y-2">
                    {options.map((option, index) => {
                        let borderRadiusClass = '';
                        if (index === 0) {
                            borderRadiusClass = 'rounded-t-lg';
                        } else if (index === options.length - 1) {
                            borderRadiusClass = 'rounded-b-lg';
                        }

                        return (
                            <button
                                key={option.risk_score_component_option_id}
                                type="button"
                                className={`relative inline-flex justify-between items-center w-full px-5 py-3 text-sm font-medium ${selectedValue === option.risk_score_component_option_id ? 'bg-indigo-600 text-white' : 'bg-white text-gray-800 hover:bg-indigo-50'} shadow-md focus:ring-2 focus:ring-indigo-500 focus:outline-none transition-colors duration-150 ease-in-out ${borderRadiusClass}`}
                                onClick={() => onChange(option.risk_score_component_option_id)}
                            >
                                <span>{option.option_name}</span>
                                <span>{option.option_value}</span>
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    };

    const handleMDMNotificationClose = () => {
        setMDMCodingNotificationVisible(false);
    };

    useEffect(() => {
        if (auditChartMessages.length > 0) {
            setHasAuditChartMessages(true);
            setCurrentAuditChartMessageIndex(0);
        }
    }, [auditChartMessages]);

    useEffect(() => {
        if (riskMessages.length > 0) {
            setHasRiskMessages(true);
            setCurrentRiskMessageIndex(0); // Reset to 0 when messages are loaded
        }
    }, [riskMessages]);


    const fetchRiskMessages = async () => {
        setIsLoading(true);
        try {
            const request = {
                scopes: protectedResources.apiFetchRiskMessages.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiFetchRiskMessages.endpoint, requestOptions);
            const data = await fetchResponse.json();
            setRiskMessages(data.risk_items);

            if (data.risk_items.length > 0 && data.risk_items.some(item => !item.resolved)) {
                setHasRiskMessages(true);

                const hasCriticalCareOrSepsisAlert = data.risk_items.some(item =>
                    (item.risk_category === "CRITICAL_CARE" || item.risk_category === "SEPSIS") && !item.resolved
                );

                const hasNIHSS = data.risk_items.some(item => item.risk_name.toUpperCase() === "NIHSS" && !item.resolved);

                if (hasCriticalCareOrSepsisAlert || hasNIHSS) {
                    setIsShowingRiskMessages(true);
                    setIsShowingAuditChartMessages(false);
                    setMDMCodingNotificationVisible(false);
                }
            } else {
                setHasRiskMessages(false);
            }
        } catch (error) {
            console.error('Error fetching audit chart messages:', error);
        }
        setIsLoading(false);
    };


    const fetchRiskScores = async () => {
        setIsLoading(true);
        try {
            const request = {
                scopes: protectedResources.apiFetchRiskScores.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            };

            const fetchResponse = await fetch(protectedResources.apiFetchRiskScores.endpoint, requestOptions);
            const data = await fetchResponse.json();
            setRiskScores(data.risk_scores);
        } catch (error) {
            console.error('Error fetching risk scores:', error);
        }
        setIsLoading(false);
    };




    const renderRiskMessagesContent = () => {
        if (!Array.isArray(riskMessages) || riskMessages.length === 0) return null;

        // Filter out resolved messages and sort by priority
        const filteredAndSortedMessages = riskMessages
            .filter(message => !message.resolved)
            .sort((a, b) => {
                const priorityOrder = ["CRITICAL_CARE", "SEPSIS"];
                const aPriority = priorityOrder.indexOf(a.risk_category);
                const bPriority = priorityOrder.indexOf(b.risk_category);

                if (aPriority !== bPriority) {
                    return aPriority - bPriority;
                }

                // If categories are the same, prioritize items with linked_risk_score_id
                if (a.linked_risk_score_id && !b.linked_risk_score_id) {
                    return -1;
                }
                if (!a.linked_risk_score_id && b.linked_risk_score_id) {
                    return 1;
                }

                return 0;
            });

        if (filteredAndSortedMessages.length === 0) return null;

        const currentMessage = filteredAndSortedMessages[currentRiskMessageIndex];

        const nextMessage = () => {
            setCurrentRiskMessageIndex(prevIndex =>
                prevIndex === filteredAndSortedMessages.length - 1 ? 0 : prevIndex + 1
            );
        };

        const prevMessage = () => {
            setCurrentRiskMessageIndex(prevIndex =>
                prevIndex === 0 ? filteredAndSortedMessages.length - 1 : prevIndex - 1
            );
        };

        const getHeaderTitle = (riskCategory) => {
            switch (riskCategory) {
                case "CRITICAL_CARE":
                    return "Critical Care Alert";
                case "SEPSIS":
                    return "Sepsis Alert";
                default:
                    return null;
            }
        };

        const handleActionClick = () => {
            if (currentMessage.linked_macro_id) {
                applyMacroToDocument(currentMessage.risk_item_id, currentMessage.linked_macro_id);
            } else if (currentMessage.linked_risk_score_id) {
                setCurrentRiskScoreId(currentMessage.linked_risk_score_id);
                setRiskScoreNotificationVisible(true);
                setIsShowingRiskMessages(false);
                resolveRiskItem(currentMessage.risk_item_id);
            }
        };

        const renderActionButton = () => {
            if (resolvingRiskItem === currentMessage.risk_item_id) {
                return (
                    <svg className="animate-spin h-5 w-5 text-indigo-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                );
            }
            if (resolvedRiskItem === currentMessage.risk_item_id) {
                return (
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-green-500">
                        <FontAwesomeIcon icon={faCheck} className="h-6 w-6 text-white" />
                    </div>
                );
            }

            let buttonText;
            if (currentMessage.linked_macro_id) {
                buttonText = currentMessage.risk_category === "CRITICAL_CARE"
                    ? "Apply Critical Care Macro"
                    : currentMessage.risk_category === "SEPSIS"
                        ? "Apply Sepsis Macro"
                        : "Apply Macro";
            } else if (currentMessage.linked_risk_score_id) {
                buttonText = currentMessage.risk_name.toUpperCase() === "HEART"
                    ? "Calculate HEART Score"
                    : currentMessage.risk_name.toUpperCase() === "NIHSS"
                        ? "Calculate NIHSS"
                        : "Calculate Risk Score";
            } else {
                buttonText = "Resolve";
            }

            return (
                <div className="bg-indigo-900 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors duration-200">
                    {buttonText}
                </div>
            );
        };

        return (
            <div className="relative w-full h-full max-w-md mx-auto flex flex-col justify-center items-center p-4">
                {filteredAndSortedMessages.length > 1 && (
                    <div className="flex items-center justify-between w-full absolute inset-x-0 top-1/2 transform -translate-y-1/2">
                        <button onClick={prevMessage} className="text-indigo-900 hover:text-indigo-700 transition-colors duration-200 focus:outline-none absolute left-0">
                            <FontAwesomeIcon icon={faChevronLeft} className="h-8 w-8" />
                        </button>
                        <button onClick={nextMessage} className="text-indigo-900 hover:text-indigo-700 transition-colors duration-200 focus:outline-none absolute right-0">
                            <FontAwesomeIcon icon={faChevronRight} className="h-8 w-8" />
                        </button>
                    </div>
                )}

                {getHeaderTitle(currentMessage.risk_category) && (
                    <h2 className="text-xl font-bold text-red-500 mb-2">
                        {getHeaderTitle(currentMessage.risk_category)}
                    </h2>
                )}

                <p className="text-lg text-center mb-4 px-4">{currentMessage.message}</p>

                <button
                    onClick={handleActionClick}
                    className="mb-2 px-4 py-2 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 relative"
                    disabled={resolvingRiskItem === currentMessage.risk_item_id || resolvedRiskItem === currentMessage.risk_item_id}
                >
                    {renderActionButton()}
                </button>
            </div>
        );
    };


    const fetchAuditChartMessages = async () => {
        setIsLoading(true);
        try {
            const request = {
                scopes: protectedResources.apiFetchAuditChartMessages.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiFetchAuditChartMessages.endpoint, requestOptions);
            const data = await fetchResponse.json();
            setAuditChartMessages(data.messages);
        } catch (error) {
            console.error('Error fetching audit chart messages:', error);
        }
        setIsLoading(false);
    };

    const renderAuditChartMessagesContent = () => {
        if (!Array.isArray(auditChartMessages) || auditChartMessages.length === 0) return null;

        const currentMessage = auditChartMessages[currentAuditChartMessageIndex];

        const nextMessage = () => {
            setCurrentAuditChartMessageIndex((prevIndex) =>
                prevIndex === auditChartMessages.length - 1 ? 0 : prevIndex + 1
            );
        };

        const prevMessage = () => {
            setCurrentAuditChartMessageIndex((prevIndex) =>
                prevIndex === 0 ? auditChartMessages.length - 1 : prevIndex - 1
            );
        };

        return (
            <div className="relative w-full h-full max-w-md mx-auto flex flex-col justify-center items-center p-6">
                <p className="text-lg text-center mb-6 px-2 pt-2">
                    {currentMessage.message}
                </p>
                <div className="flex items-center justify-between w-full px-4">
                    <button
                        onClick={prevMessage}
                        className="text-indigo-900 hover:text-indigo-700 transition-colors duration-200 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="h-8 w-8" />
                    </button>
                    <span className="text-sm text-gray-500">
                        {currentAuditChartMessageIndex + 1} / {auditChartMessages.length}
                    </span>
                    <button
                        onClick={nextMessage}
                        className="text-indigo-900 hover:text-indigo-700 transition-colors duration-200 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={faChevronRight} className="h-8 w-8" />
                    </button>
                </div>
            </div>
        );
    };


    const handleRiskScoreChange = (field, value) => {
        setRiskScore((prevScores) => {
            // Check if the field is being unselected (the new value is the same as the current value)
            const isUnselecting = prevScores[field] === value;

            return {
                ...prevScores,
                // If unselecting, set the field's value to null, otherwise, update it with the new value
                [field]: isUnselecting ? null : value,
            };
        });
    };

    const insertSelectedMacro = async (macro) => {
        const macroSection = macro.section;
        const sectionMapping = {
            "hpi": hpi,
            "ros": ros,
            "physical_exam": exam,
            "reevaluation": reeval
        }
        const sectionToUpdate = sectionMapping[macroSection];
        const updatedSection = sectionToUpdate ? sectionToUpdate + "\n\n" + macro.content : macro.content;
        const setterMapping = {
            "hpi": setHPI,
            "ros": setROS,
            "physical_exam": setExam,
            "reevaluation": setReeval
        };
        // Update the section with the new macro content
        setterMapping[macroSection](updatedSection);
        // Save the updated section to the database
        saveDocument(macroSection, updatedSection);

        // Update macro_manually_inserted flag in backend
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            // Prepare the API call
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    document_id: document.document_id,
                    field_to_update: 'macro_manually_inserted',
                    new_value: true
                })
            };

            // Make the API call to update the macro_manually_inserted flag
            await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);

        } catch (error) {
            console.error('Error updating macro_manually_inserted flag:', error);
            // Handle any errors here
        }
    };


    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);

    const copyAllToClipboard = () => {
        let allText = `HISTORY OF PRESENT ILLNESS: \n${hpi}\n\nREVIEW OF SYSTEMS: \n${ros}\n\nPHYSICAL EXAM: \n${exam}\n\nEVALUATIONS: \n${reeval}`;

        if (mdm) {
            allText += `\n\nMEDICAL DECISION-MAKING: \n${mdm}`;
        }
        navigator.clipboard.writeText(allText).then(() => {
            setCopiedAll(true);
            updateDocument(document.document_id, 'copied_all', true);
            setTimeout(() => {
                setCopiedAll(false);
            }, 3000); // Reset copy state after 3 seconds
        });
    };

    const copyMDMToClipboard = () => {
        navigator.clipboard.writeText(mdm).then(() => {
            setCopiedAll(true);
            setTimeout(() => {
                setCopiedAll(false);
            }, 3000); // Reset copy state after 3 seconds
        });
    };

    const updateSection = (sectionName, sectionContent) => {
        switch (sectionName) {
            case 'hpi':
                setHPI(sectionContent);
                setEditedDocument(prev => ({ ...prev, hpi: sectionContent }));
                break;
            case 'ros':
                setROS(sectionContent);
                setEditedDocument(prev => ({ ...prev, ros: sectionContent }));
                break;
            case 'physical_exam':
                setExam(sectionContent);
                setEditedDocument(prev => ({ ...prev, physical_exam: sectionContent }));
                break;
            case 'reevaluation':
                setReeval(sectionContent);
                setEditedDocument(prev => ({ ...prev, reevaluation: sectionContent }));
                break;
            case 'mdm':
                setMDM(sectionContent);
                setEditedDocument(prev => ({ ...prev, mdm: sectionContent }));
                break;
        }
    };

    const updateHPI = (newHPI) => {
        setHPI(newHPI);
        setEditedDocument(prev => ({ ...prev, hpi: newHPI }));
    };

    const updateROS = (newROS) => {
        setROS(newROS);
        setEditedDocument(prev => ({ ...prev, ros: newROS }));
    };

    const updateExam = (newExam) => {
        setExam(newExam);
        setEditedDocument(prev => ({ ...prev, physical_exam: newExam }));
    };

    const updateReeval = (newReeval) => {
        setReeval(newReeval);
        setEditedDocument(prev => ({ ...prev, reevaluation: newReeval }));
    };

    const updateMDM = (newMDM) => {
        setMDM(newMDM);
        setEditedDocument(prev => ({ ...prev, mdm: newMDM }));
    };

    const updateProcedureDescription = (newProcedureDescription) => {
        setProcedureDescription(newProcedureDescription);
        setEditedDocument(prev => ({ ...prev, procedure_description: newProcedureDescription }));
    };

    const updatePatientName = (newPatientName) => {
        setPatientName(newPatientName);
        setEditedDocument(prev => ({ ...prev, patient_name: newPatientName }));
    };

    const saveDocument = async (fieldToSave, newValue) => {
        let hasChanges = false;

        if (newValue === undefined) {
            switch (fieldToSave) {
                case 'hpi':
                    hasChanges = document.hpi !== hpi;
                    newValue = hpi;
                    break;
                case 'ros':
                    hasChanges = document.ros !== ros;
                    newValue = ros;
                    break;
                case 'physical_exam':
                    hasChanges = document.physical_exam !== exam;
                    newValue = exam;
                    break;
                case 'reeval':
                    hasChanges = document.reevaluation !== reeval;
                    newValue = reeval;
                    break;
                case 'mdm':
                    hasChanges = document.mdm !== mdm;
                    newValue = mdm;
                    break;
                case 'procedure_description':
                    hasChanges = document.procedure_description !== procedureDescription;
                    newValue = procedureDescription;
                    break;
                case 'patient_name':
                    hasChanges = document.patient_name !== patientName;
                    newValue = patientName;
                    break;
                case 'note_feedback':
                    hasChanges = document.note_feedback !== noteFeedback;
                    newValue = noteFeedback;
                    break;
                default:
                    console.log('Invalid field to save');
                    return;
            }
        } else {
            // Directly check changes against the document's existing value
            hasChanges = document[fieldToSave] !== newValue;
        }

        if (!hasChanges) {
            console.log("No changes to save.");
            return; // Exit if no changes
        }

        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id, "field_to_update": fieldToSave, "new_value": newValue })
            };

            const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (data.updated_document) {
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 3000);
            }
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            setErrorMessage("An error occurred while updating the document."); // Customize this message based on the error if needed
            setShowErrorNotification(true);
            setTimeout(() => setShowErrorNotification(false), 5000); // Hide the notification after 5 seconds
        }

    };

    const parseMDMCoding = (mdmCoding) => {
        // Initialize default values
        let copa = -1;
        let complexityOfData = -1;
        let riskOfComplications = -1;

        // Split the mdmCoding string by commas to get individual parts
        const parts = mdmCoding.split(', ');

        // Parse each part to extract the integer values
        parts.forEach(part => {
            const [key, value] = part.split(': ');
            const intValue = parseInt(value.trim());
            if (key.trim() === 'copa') {
                copa = intValue;
            } else if (key.trim() === 'complexity_of_data') {
                complexityOfData = intValue;
            } else if (key.trim() === 'risk_of_complications') {
                riskOfComplications = intValue;
            }
        });

        setCopa(copa);
        setComplexityOfData(complexityOfData);
        setRiskOfComplications(riskOfComplications);
    };

    const approveDocument = async () => {
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id, "field_to_update": "document_status", "new_value": "FINALIZED" })
            };

            const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);

            console.log('providedDate:', providedDate);

            navigate('/er-dashboard', { state: { providedDate: providedDate } });

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }
    };

    const resolveRiskItem = async (riskItemId) => {
        setResolvingRiskItem(riskItemId);
        try {
            const request = {
                scopes: protectedResources.apiResolveRiskItem.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "risk_item_id": riskItemId })
            };

            await fetch(protectedResources.apiResolveRiskItem.endpoint, requestOptions);
            setResolvedRiskItem(riskItemId);
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        } finally {
            setResolvingRiskItem(null);
        }
    };


    const applyMacroToDocument = async (riskItemId, macroId) => {
        setResolvingRiskItem(riskItemId);
        try {
            const request = {
                scopes: protectedResources.apiApplyMacroToDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id, "macro_id": macroId, "risk_item_id": riskItemId })
            };

            const apiResponse = await fetch(protectedResources.apiApplyMacroToDocument.endpoint, requestOptions);
            const data = await apiResponse.json();
            const updatedSectionName = data.updated_section_name;
            const updatedSectionContent = data.updated_section_content;
            updateSection(updatedSectionName, updatedSectionContent);
            setResolvedRiskItem(riskItemId);
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        } finally {
            setResolvingRiskItem(null);
        }
    };

    const auditChart = async () => {
        setIsLoading(true);
        try {
            const request = {
                scopes: protectedResources.apiAuditChart.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiAuditChart.endpoint, requestOptions);
            await fetchAuditChartMessages();

            setHasAuditChartMessages(true);
            setIsShowingAuditChartMessages(true);
            setMDMCodingNotificationVisible(false);
            setIsShowingRiskMessages(false);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }
        setIsLoading(false);
    };

    const calculateMDM = async () => {
        setIsLoadingMDM(true);
        try {

            const request = {
                scopes: protectedResources.apiCalculateMDM.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiCalculateMDM.endpoint, requestOptions);

            const data = await fetchResponse.json();
            setMDM(data.mdm_content);
            parseMDMCoding(data.mdm_coding);

            await fetchDocument();

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error calculating MDM:', error);
            // Handle any errors here
        }
        setIsLoadingMDM(false);
    };

    const codeMDM = async () => {
        setIsLoading(true);
        try {

            const request = {
                scopes: protectedResources.apiCodeMDM.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiCodeMDM.endpoint, requestOptions);

            const data = await fetchResponse.json();
            parseMDMCoding(data.mdm_coding);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error coding MDM:', error);
            // Handle any errors here
        }
        setIsLoading(false);
    };

    const fetchDocument = useCallback(async () => {
        setLoadingMessage("Fetching Document...");
        setIsLoading(true);
        if (accounts.length > 0) {
            try {
                const documentRequest = {
                    scopes: protectedResources.apiGetDocument.scopes,
                    account: accounts[0]
                };
                const macrosRequest = {
                    scopes: protectedResources.apiGetMacros.scopes,
                    account: accounts[0]
                };

                // Acquire tokens for both requests
                const [documentToken, macrosToken] = await Promise.all([
                    instance.acquireTokenSilent(documentRequest),
                    instance.acquireTokenSilent(macrosRequest)
                ]);

                // Fetch document and macros in parallel
                const documentFetchOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${documentToken.accessToken}`
                    },
                    body: JSON.stringify({ document_id: document.document_id })
                };

                const macrosFetchOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${macrosToken.accessToken}`
                    }
                };


                const [documentResponse, macrosResponse] = await Promise.all([
                    fetch(protectedResources.apiGetDocument.endpoint, documentFetchOptions),
                    fetch(protectedResources.apiGetMacros.endpoint, macrosFetchOptions)
                ]);

                const documentData = await documentResponse.json();
                const macrosData = await macrosResponse.json();

                if (documentData.document) {
                    setHPI(documentData.document.hpi);
                    setROS(documentData.document.ros);
                    setExam(documentData.document.physical_exam);
                    setReeval(documentData.document.reevaluation);
                    setMDM(documentData.document.mdm);
                    setPatientName(documentData.document.patient_name);
                    setDocumentStatus(documentData.document.document_status);
                    setProcedureDescription(documentData.document.procedure_description);
                    if (documentData.document.mdm_coding) {
                        parseMDMCoding(documentData.document.mdm_coding);
                    }
                } else {
                    setHPI("");
                    setROS("");
                    setExam("");
                    setReeval("");
                    setMDM('');
                    setPatientName("");
                    setProcedureDescription("");
                }

                if (macrosData.macros && typeof macrosData.macros === 'object') {
                    const newMacros = Object.values(macrosData.macros).map(macro => ({
                        macro_id: macro.macro_id,
                        title: macro.title,
                        command: macro.command,
                        content: macro.content,
                        section: macro.section,
                        is_shareable_within_org: macro.is_shareable_within_org,
                        created_at: macro.created_at,
                        updated_at: macro.updated_at
                    }));
                    setMacroList(newMacros);
                } else {
                    console.error('Unexpected macros data structure:', macrosData);
                    setMacroList([]);
                }

                await fetchAuditChartMessages();
                await fetchRiskMessages();
                await fetchRiskScores();

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching reports:', error);
            }
        } setIsLoading(false);
    }, [instance, accounts, document, handleTokenExpiration]);

    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text);
        switch (type) {
            case 'hpi':
                setCopiedHPI(true);
                updateDocument(document.document_id, 'copied_hpi', true);
                setTimeout(() => setCopiedHPI(false), 3000);
                break;
            case 'ros':
                setCopiedROS(true);
                updateDocument(document.document_id, 'copied_ros', true);
                setTimeout(() => setCopiedROS(false), 3000);
                break;
            case 'physical_exam':
                setCopiedExam(true);
                updateDocument(document.document_id, 'copied_physical_exam', true);
                setTimeout(() => setCopiedExam(false), 3000);
                break;
            case 'reeval':
                setCopiedReeval(true);
                updateDocument(document.document_id, 'copied_reevaluation', true);
                setTimeout(() => setCopiedReeval(false), 3000);
                break;
            case 'mdm':
                setCopiedMDM(true);
                updateDocument(document.document_id, 'copied_mdm', true);
                setTimeout(() => setCopiedMDM(false), 3000);
                break;
            default:
                console.error('Invalid copy type:', type);
                break;
        }
    };

    const updateDocument = async (documentId, fieldToUpdate, newValue) => {
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    document_id: documentId,
                    field_to_update: fieldToUpdate,
                    new_value: newValue
                })
            };

            await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
            console.log('Updated', fieldToUpdate, 'to', newValue, 'for document', documentId);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    useEffect(() => {
        const loading = inProgress === InteractionStatus.Startup ||
            inProgress === InteractionStatus.HandleRedirect ||
            inProgress === InteractionStatus.Login ||
            inProgress === InteractionStatus.Logout;

        setIsLoading(loading);
        const timeoutId = setTimeout(() => {
            fetchDocument();
        }, 250); // Delay of 250 milliseconds
    }, [fetchDocument, inProgress]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (riskScoreDropdownRef.current && !riskScoreDropdownRef.current.contains(event.target)) {
                setShowRiskScoreDropdown(false);
            }
            if (macroDropdownRef.current && !macroDropdownRef.current.contains(event.target)) {
                setShowMacroDropdown(false);
            }
        };

        // Attach the listener if either dropdown is visible
        if (showRiskScoreDropdown || showMacroDropdown) {
            window.addEventListener('click', handleClickOutside);
        }

        // Cleanup the listener
        return () => window.removeEventListener('click', handleClickOutside);
    }, [showRiskScoreDropdown, showMacroDropdown]);


    const goBack = () => {
        navigate('/er-dashboard', { state: { providedDate: providedDate } });
    };

    const formatTime = (utcString) => {

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const utcDate = new Date(utcString);
        const formatter = new Intl.DateTimeFormat(undefined, options);
        return formatter.format(utcDate);
    };

    const formatDate = (utcString) => {
        const date = new Date(utcString);

        const options = { month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    };

    useEffect(() => {
        if (saveTriggered) {
            // Now inside this effect, you know reeval has been updated
            saveDocument('reeval');
            setSaveTriggered(false); // Reset trigger to avoid unintended saves
        }
    }, [reeval, saveTriggered]); // Reacts to changes in reeval and the save trigger

    const filteredMacros = Object.values(macro_list).filter(macro =>
        macro.title.toLowerCase().includes(macroSearchQuery.toLowerCase())
    );

    const sectionMapping = {
        hpi: 'HPI',
        ros: 'ROS',
        physical_exam: 'Physical Exam',
        reevaluation: 'Evaluations'
    };

    const [showHpiMacros, setShowHpiMacros] = useState(false);

    const toggleHpiMacros = () => {
        setShowHpiMacros(!showHpiMacros);
    };

    const [showExamMacros, setShowExamMacros] = useState(false);

    const toggleExamMacros = () => {
        setShowExamMacros(!showExamMacros);
    };

    const [showRosMacros, setShowRosMacros] = useState(false);

    const toggleRosMacros = () => {
        setShowRosMacros(!showRosMacros);
    };

    const [showEvaluationsMacros, setShowEvaluationsMacros] = useState(false);

    const toggleEvaluationsMacros = () => {
        setShowEvaluationsMacros(!showEvaluationsMacros);
    };

    const submitNoteFeedback = async () => {
        if (!noteFeedback) return;

        try {
            setFeedbackSubmitted(true);
            setTimeout(() => {
                setNoteFeedbackNotificationVisible(false);
            }, 3000);
            await updateDocument(document.document_id, 'note_feedback', noteFeedback);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };

    const median = (values) => {
        const sorted = [...values].sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);

        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2;
        }

        return sorted[middle];
    };


    const toggleBracketedText = () => {
        if (!isRemoved) {
            const bracketedPattern = /^[^\S\r\n]*.*?\[\*\*\*.*?\*\*\*\].*$(\r?\n|\r)?/gm;
            const cleanedMDM = mdm.replace(bracketedPattern, '').trim();
            setOriginalMDM(mdm); // Save the current state before changing
            setCleanedMDM(cleanedMDM);
            setMDM(cleanedMDM);
            setIsRemoved(true); // Update flag to indicate the text is removed
        } else if (mdm === cleanedMDM) {
            setMDM(originalMDM);
            setIsRemoved(false);
        } else {
            alert("MDM has been edited since bracketremoval; undo is not available.");
        }
    };



    const [showRatingButtons, setShowRatingButtons] = useState(true);

    const handleRatingUpdate = async (rating) => {
        setShowRatingButtons(false);
        await updateDocument(document.document_id, 'note_rating', rating);
        setShowRatingButtons(false);  // Hide the rating buttons
    };


    const Sidebar = ({ macros, onSelectMacro, isExpanded, toggleSidebar, position = 'left', maxHeight, section }) => {
        const sectionMapping = {
            hpi: "HPI",
            ros: "ROS",
            physical_exam: "Physical",
            reevaluation: "Eval"
        };

        const sectionMacros = macros.filter(macro => macro.section === section);

        const handleCreateMacro = () => {
            navigate('/macros');
        };

        return (
            <div className={`transition-width duration-300 ${isExpanded ? 'w-64' : 'w-6'} flex flex-col rounded-l-lg ${position === 'right' ? 'rounded-r-lg' : 'rounded-l-lg'}`} style={{ maxHeight }}>
                <button
                    className={`bg-indigo-900 text-white hover:bg-indigo-900 focus:outline-none items-center ${position === 'right' ? 'rounded-r-lg' : 'rounded-l-lg'}`}
                    onClick={toggleSidebar}
                >
                    {isExpanded ? (position === 'left' ? '<' : '>') : (position === 'left' ? 'M' : 'M')}
                </button>
                {!isExpanded && (
                    <div className="flex-grow flex items-center justify-center">
                        <div className="text-center transform -rotate-90 whitespace-nowrap">
                            {/* {sectionMapping[section]} Macros */}
                        </div>
                    </div>
                )}
                {isExpanded && (
                    <div className="p-2 overflow-auto" style={{ maxHeight: '300px' }}>
                        <h2 className="text-md text-center font-bold mb-2">{sectionMapping[section]} Macros</h2>
                        {sectionMacros.length > 0 ? (
                            <div className="rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-full">
                                <div className="py-1" role="none">
                                    {sectionMacros.map((macro, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            className="text-gray-700 block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 hover:text-indigo-900"
                                            role="menuitem"
                                            tabIndex="-1"
                                            onClick={() => onSelectMacro(macro)}
                                        >
                                            {macro.title}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="text-center">
                                {/* <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        vectorEffect="non-scaling-stroke"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                    />
                                </svg> */}
                                {/* <h3 className="mt-2 text-sm font-semibold text-gray-900">No {sectionMapping[section]} macros</h3> */}
                                <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                <div className="mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                        onClick={handleCreateMacro}
                                    >
                                        {/* <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5 bg-indigo-900 hover:bg-indigo-900" aria-hidden="true" /> */}
                                        + New Macro
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };






    return (
        <div className="max-w-7xl mx-auto px-5">
            {showNotification && (
                <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
                    <div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-green-800">Successfully saved</p>
                            </div>
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                        onClick={() => setShowNotification(false)}
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showErrorNotification && (
                <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
                    <div className="rounded-md bg-red-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">Error</h3>
                                <div className="mt-2 text-sm text-red-700">
                                    <p>{errorMessage}</p>
                                </div>
                            </div>
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                                        onClick={() => setShowErrorNotification(false)}
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}






















            {/* {isCarepointPosthog && hasRiskMessages && !mdmCodingNotificationVisible && !riskScoreNotificationVisible && !isShowingAuditChartMessages && ( */}
            {hasRiskMessages && !mdmCodingNotificationVisible && !riskScoreNotificationVisible && !isShowingAuditChartMessages && (
                <div className="fixed top-40 right-4 z-50">
                    {isShowingRiskMessages ? (
                        <div className="h-[40vh] md:h-[350px] w-full md:w-[600px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white">
                            <div className="flex justify-between items-center bg-gradient-to-r from-indigo-700 to-indigo-900 text-white py-3 px-4">
                                <div className="font-bold text-xl flex items-center">
                                    <FontAwesomeIcon icon={faShield} className="mr-2" />
                                    Cleo Risk Analyst
                                </div>
                                <button
                                    onClick={() => setIsShowingRiskMessages(false)}
                                    className="p-1 rounded-full hover:bg-indigo-700 transition-colors duration-200"
                                >
                                    <XMarkIcon className="h-6 w-6" />
                                </button>
                            </div>
                            <div className="flex-grow overflow-y-auto p-4 space-y-4 text-xl leading-relaxed">
                                {renderRiskMessagesContent()}
                            </div>
                        </div>
                    ) : (
                        <div className="h-12 w-12 bg-indigo-900 rounded-full flex items-center justify-center cursor-pointer shadow-lg hover:shadow-xl" onClick={() => setIsShowingRiskMessages(true)}>
                            <ShieldCheckIcon className="h-6 w-6 text-white" />
                        </div>
                    )}
                </div>
            )}


            {isBetaPosthog && hasAuditChartMessages && !mdmCodingNotificationVisible && !riskScoreNotificationVisible && !isShowingRiskMessages && (
                // {hasAuditChartMessages && !mdmCodingNotificationVisible && !riskScoreNotificationVisible && !isShowingRiskMessages && (
                <div className="fixed top-56 right-4 z-50">
                    {isShowingAuditChartMessages && auditChartMessages.length > 0 ? (
                        <div className="h-[40vh] md:h-[350px] w-full md:w-[600px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white transition-all duration-300 ease-in-out">
                            <div className="flex justify-between items-center bg-gradient-to-r from-indigo-700 to-indigo-900 text-white py-3 px-4">
                                <div className="font-bold text-xl flex items-center">
                                    <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                    AI Chart Audit
                                </div>
                                <button
                                    onClick={() => setIsShowingAuditChartMessages(false)}
                                    className="p-1 rounded-full hover:bg-indigo-700 transition-colors duration-200"
                                >
                                    <XMarkIcon className="h-6 w-6" />
                                </button>
                            </div>
                            <div className="flex-grow overflow-y-auto p-4 space-y-4 text-xl leading-relaxed">
                                {renderAuditChartMessagesContent()}
                            </div>
                        </div>
                    ) : (
                        <button
                            onClick={() => setIsShowingAuditChartMessages(true)}
                            className="h-12 w-12 bg-indigo-900 rounded-full flex items-center justify-center cursor-pointer shadow-lg hover:shadow-xl"
                            title="Open AI Chart Audit"
                        >
                            <FontAwesomeIcon icon={faSearch} className="h-6 w-6 text-white group-hover:scale-110 transition-transform duration-200" />
                        </button>
                    )}
                </div>
            )}

            {/* Notification Card for MDM Coding */}
            {
                isShowingMDM && !riskScoreNotificationVisible && !isShowingAuditChartMessages && !isShowingRiskMessages && (
                    <div className="fixed top-24 right-4">
                        {mdmCodingNotificationVisible ? (
                            <div className="h-[60vh] md:h-[375px] w-full md:w-[200px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white">
                                <div className="flex justify-between items-center bg-indigo-900 text-white py-2 pl-4 pr-2">
                                    <div className="font-bold text-sm text-center flex-grow cursor-pointer" onClick={() => setMDMCodingNotificationVisible(true)}>
                                        Cleo MDM Assistant
                                    </div>
                                    <button onClick={() => setMDMCodingNotificationVisible(false)} className="p-1 rounded-full bg-white text-indigo-900 hover:text-indigo-900">
                                        <XMarkIcon className="h-5 w-5" />
                                    </button>
                                </div>
                                <div className="bg-white p-2 pt-2 px-0 flex-grow overflow-auto max-h-[calc(60vh-4rem)]">
                                    <div className="mt-2 p-2 rounded-lg shadow">
                                        <ProgressBar value={copa} title="Complexity of Problems Addressed" />
                                        <ProgressBar value={complexityOfData} title="Complexity of Data" />
                                        <ProgressBar value={riskOfComplications} title="Risk of Complications" />
                                    </div>
                                    <div className="mt-4 p-2 rounded-lg shadow">
                                        <div className="text-sm font-semibold text-gray-700">Coding Estimate</div>
                                        <div className="text-2xl font-bold text-indigo-900">
                                            {Math.floor(median([copa, complexityOfData, riskOfComplications]))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="h-12 w-12 bg-indigo-900 rounded-full flex items-center justify-center cursor-pointer shadow-lg hover:shadow-xl" onClick={() => setMDMCodingNotificationVisible(true)}>
                                <CalculatorIcon className="h-6 w-6 text-white" />
                            </div>
                        )}
                    </div>
                )
            }



























            {/* Document Details Section as a Horizontal Header */}
            <div className="header-container mb-16 mt-6 w-full">
                {/* Header Row with Go Back Button and Documentation Heading */}
                <div className="header-row flex justify-between items-center">
                    {/* Go Back Button */}
                    <button
                        onClick={goBack}
                        className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900">

                        <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                        Back to Dashboard
                    </button>

                    {/* Risk Score Dropdown Button */}
                    <div ref={riskScoreDropdownRef} className="relative inline-block text-left">
                        <div>
                            <button
                                type="button"
                                className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900"
                                id="menu-button"
                                aria-expanded="true"
                                aria-haspopup="true"
                                onClick={() => setShowRiskScoreDropdown(!showRiskScoreDropdown)}
                            >
                                Calculate Risk Score
                                <FontAwesomeIcon icon={faChartLine} className="ml-2 mt-1" />
                            </button>
                        </div>

                        {showRiskScoreDropdown && (
                            <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                <div className="py-1" role="none">
                                    {riskScores.map((score, index) => (
                                        <button
                                            key={score.risk_score_id}
                                            type="button"
                                            className="text-gray-700 block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 hover:text-indigo-900"
                                            role="menuitem"
                                            tabIndex="-1"
                                            id={`menu-item-${index}`}
                                            onClick={() => {
                                                setCurrentRiskScoreId(score.risk_score_id);
                                                setIsShowingRiskMessages(false);
                                                setIsShowingAuditChartMessages(false);
                                                setIsShowingMDM(false);
                                                setRiskScoreNotificationVisible(true);
                                            }}
                                        >
                                            {score.score_name}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>


                    <div className="relative inline-block text-left">
                        <div>
                            {(isLoadingMDM || documentStatus === 'IN_PROGRESS') ? (
                                <div className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Generating MDM...
                                </div>
                            ) : isShowingMDM ? (
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900"
                                    onClick={() => { setIsShowingMDM(false); setMDMCodingNotificationVisible(false); }} // Assuming setShowMDM changes the state to show/hide MDM
                                >
                                    Back to Documentation
                                </button>
                            ) : mdm !== null ? (
                                <div className="flex space-x-2">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center flex-grow rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900"
                                        onClick={() => { setIsShowingMDM(true); setMDMCodingNotificationVisible(true); setRiskScoreNotificationVisible(false); setIsShowingRiskMessages(false); }}
                                    >
                                        View MDM
                                        <FontAwesomeIcon icon={faCalculator} className="ml-2 mt-1" />
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center items-center rounded-md border border-[#DB104E] px-3 py-2 bg-transparent text-sm font-medium text-[#DB104E] hover:bg-[#DB104E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#DB104E] transition duration-150 ease-in-out"
                                        onClick={() => calculateMDM()}
                                        title="Regenerate MDM"
                                    >

                                        Regenerate MDM
                                        <FontAwesomeIcon icon={faSync} className="ml-2 h-4 w-4" />
                                    </button>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900"
                                    onClick={() => calculateMDM()}
                                >
                                    Generate MDM
                                    <FontAwesomeIcon icon={faCalculator} className="ml-2 mt-1" />
                                </button>
                            )}
                        </div>
                    </div>
                    {/* Left Placeholder for balancing */}
                    {/* <div className="header-placeholder"></div> */}

                    {/* Info Button */}
                    <div className="button-group flex">
                        <Button type="button" color="zinc" onClick={toggleDialog} className="mr-2">
                            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
                        </Button>

                        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} size="xl">
                            <DialogTitle>Cleo-Generated Note Information</DialogTitle>
                            {/* <DialogDescription>This is a detailed description or information you want to show.</DialogDescription> */}
                            <DialogBody>
                                <p>
                                    This page contains your Cleo-generated HPI, Review of Systems, Physical Exam, and Evaluations from the recorded audio of your patient encounter.
                                </p>
                                <p>&nbsp;</p> {/* Extra space between paragraphs */}
                                <p>&nbsp;</p> {/* Extra space before the final paragraph */}
                                <p>
                                    Clicking the 'Archive' button will remove the note from your dashboard.
                                </p>
                            </DialogBody>
                            <DialogActions>
                                <button plain onClick={() => setIsDialogOpen(false)} className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900">
                                    Close
                                </button>
                                {/* Add more actions if needed */}
                            </DialogActions>
                        </Dialog>

                        <button
                            color="bg-indigo-900"
                            onClick={approveDocument}
                            className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900">
                            Archive
                            <FontAwesomeIcon icon={faCheck} className="ml-2" />
                        </button>
                    </div>
                </div>


                {/* Document Details Row */}
                {document && (
                    <div className="document-details-card mt-4 bg-gray-100">
                        {/* Patient Detail */}
                        <div className="document-detail" onDoubleClick={() => setIsEditingPatientName(true)}>
                            <Text className="detail-heading">Patient Name or Room Number:</Text>
                            {isEditingPatientName ? (
                                <input
                                    type="text"
                                    className="detail-content"
                                    value={editedDocument.patient_name}
                                    onChange={(e) => updatePatientName(e.target.value)}
                                    onBlur={() => {
                                        setIsEditingPatientName(false);
                                        saveDocument('patient_name');
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            saveDocument('patient_name');
                                            setIsEditingProcedureDescription(false);
                                        }
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <>
                                    <Text className="detail-content">{patientName}</Text>
                                </>
                            )}
                        </div>

                        {/* Encounter Date Detail */}
                        <div className="document-detail">
                            <Text className="detail-heading">Encounter Date:</Text>
                            <Text className="detail-content">{formatDate(document.created_at)} at {formatTime(document.created_at)}</Text>
                        </div>

                        {/* Visit Description Detail */}
                        <div className="document-detail" onDoubleClick={() => setIsEditingProcedureDescription(true)}>
                            <Text className="detail-heading">Chief Complaint:</Text>
                            {isEditingProcedureDescription ? (
                                <input
                                    type="text"
                                    className="detail-content"
                                    value={editedDocument.procedure_description}
                                    onChange={(e) => updateProcedureDescription(e.target.value)}
                                    onBlur={() => {
                                        setIsEditingProcedureDescription(false);
                                        saveDocument('procedure_description');
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            saveDocument('procedure_description');
                                            setIsEditingProcedureDescription(false);
                                        }
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <>
                                    <Text className="detail-content">{procedureDescription}</Text>
                                </>
                            )}
                        </div>
                    </div>
                )}

                <div className="header-container mb-16 mt-6 w-full">

                    <div className="header-row flex justify-between items-center px-4">
                        {/* Audit Chart Button */}
                        <div className="flex-grow text-left">
                            {isBetaPosthog && !hasAuditChartMessages && (
                                <button
                                    onClick={auditChart}
                                    className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900"
                                >
                                    Audit Chart
                                    <FontAwesomeIcon icon={faSearch} className="ml-2" />
                                </button>
                            )}
                        </div>

                        {/* Feedback Request Section, centered */}
                        <div className="flex-grow text-center">
                            {showRatingButtons & noteFeedbackNotificationVisible ? (
                                <div className="flex flex-col items-center">
                                    <p className="text-sm font-medium">Help us improve. How did Cleo do on this note?</p>
                                    <div className="flex space-x-4">
                                        <button onClick={() => handleRatingUpdate('good')} className="text-white px-4 py-2 rounded">
                                            👍
                                        </button>
                                        <button onClick={() => handleRatingUpdate('medium')} className="text-white px-4 py-2 rounded">
                                            😐
                                        </button>
                                        <button onClick={() => handleRatingUpdate('bad')} className="text-white px-4 py-2 rounded">
                                            👎
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="relative w-full z-0">
                                    {!feedbackSubmitted && noteFeedbackNotificationVisible ? (
                                        <>
                                            <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                                                How could this note improve?
                                            </label>
                                            <textarea
                                                rows={4}
                                                name="comment"
                                                id="comment"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-900 sm:text-sm sm:leading-6"
                                                placeholder="What would you improve about this note?"
                                                value={noteFeedback}
                                                onChange={(e) => setNoteFeedback(e.target.value)}
                                            />
                                            <div className="absolute bottom-0 right-0 pb-2 pr-2">
                                                <button
                                                    onClick={submitNoteFeedback}
                                                    className={`inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900 ${!noteFeedback ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                    disabled={!noteFeedback}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </>
                                    ) : noteFeedbackNotificationVisible ? (
                                        <div className="flex flex-col items-center justify-center flex-grow">
                                            <CheckCircleIcon className="h-10 w-10 text-indigo-900" />
                                            <p className="text-indigo-900 font-bold mt-2 text-center">Thank you for the feedback!</p>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </div>

                        {/* Refresh and Copy All Buttons */}
                        <div className="flex-grow text-right flex justify-end items-center space-x-2">
                            <button
                                onClick={fetchDocument}
                                className="justify-center rounded-full border border-gray-300 shadow-sm p-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-900 transition duration-300 ease-in-out"
                                aria-label="Refresh document"
                            >
                                <FontAwesomeIcon icon={faSync} className="text-lg" />
                            </button>
                            {copiedAll ? (
                                <button className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900">
                                    Copied!
                                    <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                </button>
                            ) : (
                                <button onClick={copyAllToClipboard} className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900">
                                    Copy All
                                    <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>


            </div>

            {/* Clinical Note Section */}
            {isLoading ? (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="loading-text">{loadingMessage}</div>
                </div>
            ) : isShowingMDM ? (
                <div className="flex justify-center mt-8">
                    {/* MDM Section */}
                    <Fieldset className="flex flex-col w-full bg-white shadow-lg rounded-lg overflow-hidden mb-20">
                        <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                            <div className="font-bold text-slate-700 font-sans text-xl pl-2">Medical Decision-Making Documentation</div>
                            <div className="flex justify-around">
                                <button
                                    className={`rounded-md text-indigo-900 px-3 py-1 text-sm hover:text-indigo-900 focus:outline-none mr-2 ${isRemoved && mdm !== originalMDM ? 'cursor-not-allowed opacity-50' : ''}`}
                                    onClick={toggleBracketedText}
                                    disabled={isRemoved && mdm !== cleanedMDM}
                                >
                                    {isRemoved ? 'Undo Remove' : 'Remove Bracketed Lines'}
                                </button>
                                <Fieldset
                                    className="rounded p-2 cursor-pointer"
                                    onClick={() => { saveDocument('mdm'); }}
                                >
                                    Save
                                    <FontAwesomeIcon icon={faSave} className="text-indigo-900 ml-2 mr-4" />
                                </Fieldset>
                                {copiedMDM ? (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(mdm, 'mdm')}>
                                        Copied!
                                        <FontAwesomeIcon icon={faCheck} className="text-indigo-900 ml-2" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(mdm, 'mdm')}>
                                        Copy
                                        <FontAwesomeIcon icon={faCopy} className="text-indigo-900 ml-2" />
                                    </Fieldset>
                                )}
                            </div>
                        </Fieldset>
                        <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                            <textarea className="constant-size-textarea w-full border-none outline-none text-slate-600 whitespace-pre-line overflow-y-auto"
                                value={mdm || ''}
                                onChange={(e) => updateMDM(e.target.value)}
                                onBlur={() => saveDocument('mdm', mdm)}
                            >
                                {mdm || ''}
                            </textarea>
                        </div>
                    </Fieldset>
                </div>
            ) : (
                <div>
                    <div className="flex" style={{ marginBottom: '25px', minHeight: '175px' }}>
                        <Sidebar
                            macros={filteredMacros}
                            onSelectMacro={insertSelectedMacro}
                            isExpanded={showHpiMacros}
                            toggleSidebar={toggleHpiMacros}
                            position='left'
                            maxHeight='500px'
                            section="hpi"
                        />
                        <div className={`flex-grow transition-width duration-300 mr-6`}>
                            <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                    <div className="font-bold text-slate-700 font-sans text-xl pl-2">History of Present Illness</div>
                                    <div className="flex justify-around">
                                        <Fieldset
                                            className="rounded p-2 cursor-pointer"
                                            onClick={() => { saveDocument('hpi'); }}
                                        >
                                            Save
                                            <FontAwesomeIcon icon={faSave} className="text-indigo-900 ml-2 mr-4" />
                                        </Fieldset>
                                        {copiedHPI ? (
                                            <Fieldset
                                                className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                onClick={() => copyToClipboard(hpi, 'hpi')}>
                                                Copied!
                                                <FontAwesomeIcon icon={faCheck} className="text-indigo-900 ml-2" />
                                            </Fieldset>
                                        ) : (
                                            <Fieldset
                                                className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                onClick={() => copyToClipboard(hpi, 'hpi')}>
                                                Copy
                                                <FontAwesomeIcon icon={faCopy} className="text-indigo-900 ml-2" />
                                            </Fieldset>
                                        )}
                                    </div>
                                </Fieldset>
                                <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                    <TextareaAutosize
                                        className="w-full border-none outline-none text-slate-600 resize-none whitespace-pre-line overflow-y-auto"
                                        value={hpi || ''}
                                        onChange={(e) => updateHPI(e.target.value)}
                                        onBlur={() => saveDocument('hpi', hpi)}>
                                    </TextareaAutosize>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '30px', marginBottom: '25px', minHeight: '175px' }}>
                        {/* ROS Section with Sidebar on the Left */}
                        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                            <Sidebar
                                macros={filteredMacros}
                                onSelectMacro={insertSelectedMacro}
                                isExpanded={showRosMacros}
                                toggleSidebar={toggleRosMacros}
                                position='left'
                                maxHeight='500px'
                                section="ros"
                            />
                            <div className={`flex-grow transition-width duration-300`}>
                                <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                    <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                        <div className="font-bold text-slate-700 font-sans text-xl pl-2">Review of Systems</div>
                                        <div className="flex justify-around">
                                            <Fieldset
                                                className="rounded p-2 cursor-pointer"
                                                onClick={() => { saveDocument('ros'); }}
                                            >
                                                Save
                                                <FontAwesomeIcon icon={faSave} className="text-indigo-900 ml-2 mr-4" />
                                            </Fieldset>
                                            {copiedROS ? (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(ros, 'ros')}>
                                                    Copied!
                                                    <FontAwesomeIcon icon={faCheck} className="text-indigo-900 ml-2" />
                                                </Fieldset>
                                            ) : (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(ros, 'ros')}>
                                                    Copy
                                                    <FontAwesomeIcon icon={faCopy} className="text-indigo-900 ml-2" />
                                                </Fieldset>
                                            )}
                                        </div>
                                    </Fieldset>
                                    <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                        <TextareaAutosize
                                            className="w-full border-none outline-none text-slate-600 resize-none whitespace-pre-line overflow-y-auto"
                                            value={ros || ''}
                                            onChange={(e) => updateROS(e.target.value)}
                                            onBlur={() => saveDocument('ros', ros)}>
                                        </TextareaAutosize>
                                    </div>
                                </Fieldset>
                            </div>
                        </div>

                        {/* Physical Exam Section with Sidebar on the Right */}
                        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                            <div className={`flex-grow transition-width duration-300`}>
                                <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                    <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                        <div className="font-bold text-slate-700 font-sans text-xl pl-2">Physical Exam</div>
                                        <div className="flex justify-around">
                                            <Fieldset
                                                className="rounded p-2 cursor-pointer"
                                                onClick={() => { saveDocument('physical_exam'); }}
                                            >
                                                Save
                                                <FontAwesomeIcon icon={faSave} className="text-indigo-900 ml-2 mr-4" />
                                            </Fieldset>
                                            {copiedExam ? (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(exam, 'physical_exam')}>
                                                    Copied!
                                                    <FontAwesomeIcon icon={faCheck} className="text-indigo-900 ml-2" />
                                                </Fieldset>
                                            ) : (
                                                <Fieldset
                                                    className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                    onClick={() => copyToClipboard(exam, 'physical_exam')}>
                                                    Copy
                                                    <FontAwesomeIcon icon={faCopy} className="text-indigo-900 ml-2" />
                                                </Fieldset>
                                            )}
                                        </div>
                                    </Fieldset>
                                    <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                        <TextareaAutosize className="w-full border-none outline-none text-slate-600 whitespace-pre-line overflow-y-auto"
                                            value={exam || ''}
                                            onChange={(e) => updateExam(e.target.value)}
                                            onBlur={() => saveDocument('physical_exam', exam)}>
                                        </TextareaAutosize>
                                    </div>
                                </Fieldset>
                            </div>
                            <Sidebar
                                macros={filteredMacros}
                                onSelectMacro={insertSelectedMacro}
                                isExpanded={showExamMacros}
                                toggleSidebar={toggleExamMacros}
                                position="right"
                                maxHeight='500px'
                                section="physical_exam"
                            />
                        </div>
                    </div>


                    <div style={{ display: 'flex', width: '100%', marginBottom: '50px' }}>
                        {/* Evaluations Section */}
                        <Sidebar
                            macros={filteredMacros}
                            onSelectMacro={insertSelectedMacro}
                            isExpanded={showEvaluationsMacros}
                            toggleSidebar={toggleEvaluationsMacros}
                            position='left'
                            maxHeight='500px'
                            section="reevaluation"
                        />
                        <div className={`flex-grow transition-width duration-300 mr-6`}>
                            <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden" style={{ minHeight: '175px', maxHeight: '500px', overflowY: 'auto' }}>
                                <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                                    <div className="font-bold text-slate-700 font-sans text-xl pl-2">Evaluations</div>
                                    <div className="flex justify-around">
                                        <Fieldset
                                            className="rounded p-2 cursor-pointer"
                                            onClick={() => { saveDocument('reeval'); }}
                                        >
                                            Save
                                            <FontAwesomeIcon icon={faSave} className="text-indigo-900 ml-2 mr-4" />
                                        </Fieldset>
                                        {copiedReeval ? (
                                            <Fieldset
                                                className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                onClick={() => copyToClipboard(reeval, 'reeval')}>
                                                Copied!
                                                <FontAwesomeIcon icon={faCheck} className="text-indigo-900 ml-2" />
                                            </Fieldset>
                                        ) : (
                                            <Fieldset
                                                className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                                onClick={() => copyToClipboard(reeval, 'reeval')}>
                                                Copy
                                                <FontAwesomeIcon icon={faCopy} className="text-indigo-900 ml-2" />
                                            </Fieldset>
                                        )}
                                    </div>
                                </Fieldset>
                                <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                                    <TextareaAutosize className="w-full border-none outline-none text-slate-600 whitespace-pre-line overflow-y-auto"
                                        value={reeval || ''}
                                        onChange={(e) => updateReeval(e.target.value)}
                                        onBlur={() => saveDocument('reeval', reeval)}>
                                    </TextareaAutosize>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                </div>
            )
            }


            {/* Notification Card for Risk Score */}
            {
                riskScoreNotificationVisible && !mdmCodingNotificationVisible && (
                    <RenderRiskScoreNotification riskScoreId={currentRiskScoreId} />
                )
            }
        </div >
    );
}

export default PatientInfoCarepoint;
