import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useLocation, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit, faCopy, faCheck, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'

import { Fieldset } from '../components/catalyst/fieldset';
import { Text } from '../components/catalyst/text';
//import { Textarea } from '../components/catalyst/textarea';
import { Button } from '../components/catalyst/button';
import '../styles/ViewReports.css';

function ViewReports() {
    const [reports, setReports] = useState('');
    const [summary, setSummary] = useState('');
    const [cdtCodes, setCdtCodes] = useState('');
    const { instance, accounts } = useMsal();
    const [copied, setCopied] = useState(false);
    const [copiedSummary, setCopiedSummary] = useState(false);
    const [copiedCdtCodes, setCopiedCdtCodes] = useState(false);
    //const [loadingReports, setLoadingReports] = useState(false);
    const [loadingSummary, setLoadingSummary] = useState(false);
    const [loadingCdtCodes, setLoadingCdtCodes] = useState(false);
    const [errorSummary, setErrorSummary] = useState('');
    const [errorCdtCodes, setErrorCdtCodes] = useState('');
    const [isEditingReports, setIsEditingReports] = useState(false);
    const [isEditingSummary, setIsEditingSummary] = useState(false);
    const [isEditingCdtCodes, setIsEditingCdtCodes] = useState(false);
    const location = useLocation();
    const document = location.state?.document;
    const navigate = useNavigate();
    const [editedDocument, setEditedDocument] = useState(document);


    // Call Summarize Note API
    const summarizeNote = async () => {
        setLoadingSummary(true);
        setErrorSummary(''); // Reset error message
        if (accounts.length > 0 && document.document_id) {
            try {
                const request = {
                    scopes: protectedResources.apiSummarizeNote.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ document_id: document.document_id })
                };

                const fetchResponse = await fetch(protectedResources.apiSummarizeNote.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (data.document_content) {
                    setSummary(data.document_content);
                } else {
                    setErrorSummary('No summary available');
                }
            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error summarizing note:', error);
                setErrorSummary('Failed to summarize note');
            } finally {
                setLoadingSummary(false);
            }
        } else {
            setLoadingSummary(false);
        }
    };


    // Call Suggest CDT Codes API
    const suggestCDTCodes = async () => {
        setLoadingCdtCodes(true);
        setErrorCdtCodes(''); // Reset error message
        if (accounts.length > 0 && document.document_id) {
            try {
                const request = {
                    scopes: protectedResources.apiSuggestCDTCodes.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ document_id: document.document_id })
                };

                const fetchResponse = await fetch(protectedResources.apiSuggestCDTCodes.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (data.cdt_codes) {
                    setCdtCodes(data.cdt_codes);
                } else {
                    setErrorCdtCodes('No CDT codes available');
                }
            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error suggesting CDT codes:', error);
                setErrorCdtCodes('Failed to suggest CDT codes');
            } finally {
                setLoadingCdtCodes(false);
            }
        } else {
            setLoadingCdtCodes(false);
        }
    };

    const handleTokenExpiration = (error) => {
        if (error instanceof InteractionRequiredAuthError) {
            // Token expired, log the user out
            instance.logoutRedirect(); // or instance.logoutPopup()
        } else {
            console.error('Error:', error);
        }
    };

    const updateReports = (newReports) => {
        setReports(newReports);
        setEditedDocument(prev => ({ ...prev, document_content: newReports }));
    };

    const updateSummary = (newSummary) => {
        setSummary(newSummary);
        setEditedDocument(prev => ({ ...prev, patient_summary: newSummary }));
    };

    const updateCdtCodes = (newCdtCodes) => {
        setCdtCodes(newCdtCodes);
        setEditedDocument(prev => ({ ...prev, code_suggestions: newCdtCodes }));
    };

    const saveDocument = async (fieldToSave) => {
        let hasChanges = false;
        let newValue;

        switch (fieldToSave) {
            case 'document_content':
                hasChanges = document.document_content !== reports;
                newValue = reports;
                break;
            case 'patient_summary':
                hasChanges = document.patient_summary !== summary;
                newValue = summary;
                break;
            case 'code_suggestions':
                hasChanges = document.code_suggestions !== cdtCodes;
                newValue = cdtCodes;
                break;
            default:
                console.log('Invalid field to save');
                return;
        }

        if (!hasChanges) {
            console.log("No changes to save.");
            return; // Exit if no changes
        }

        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": document.document_id, "field_to_update": fieldToSave, "new_value": newValue })
            };

            const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (data.updatedDocument) {
                // Update your document state here if necessary
            }
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }
    };





    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text);
        if (type === 'reports') {
            setCopied(true);
            setTimeout(() => setCopied(false), 3000);
        } else if (type === 'summary') {
            setCopiedSummary(true);
            setTimeout(() => setCopiedSummary(false), 3000);
        } else if (type === 'cdtCodes') {
            setCopiedCdtCodes(true);
            setTimeout(() => setCopiedCdtCodes(false), 3000);
        }
    };
    const goBack = () => {
        console.log('Navigating back to dashboard');
        navigate('/notedashboard'); // Adjust the path as needed for your dashboard route
    };

    useEffect(() => {
        if (document) {
            setReports(document.document_content);
            setSummary(document.patient_summary || '');
            setCdtCodes(document.code_suggestions || '');
        }
    }, [document]);

    const formatTime = (utcString) => {

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const utcDate = new Date(utcString);
        const formatter = new Intl.DateTimeFormat(undefined, options);
        return formatter.format(utcDate);
    };

    const formatDate = (utcString) => {
        const date = new Date(utcString);

        const options = { month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    };



    return (
        <div className="max-w-7xl mx-auto px-5">
            {/* Document Details Section as a Horizontal Header */}
            <div className="header-container mb-16 mt-6 w-full">
                {/* Header Row with Go Back Button and Documentation Heading */}
                <div className="header-row flex justify-between items-center">
                    {/* Go Back Button */}
                    <Button color="indigo" onClick={goBack} className="go-back-button">
                        <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                        Note Dashboard
                    </Button>


                    {/* Documentation Heading */}
                    {/* <div className="section-heading">
                        <div className="text-3xl font-bold text-slate-700">Documentation</div>
                    </div> */}

                    {/* Left Placeholder for balancing */}
                    <div className="header-placeholder"></div>


                </div>

                {/* Document Details Row */}
                {document && (
                    <div className="document-details-card mt-4 bg-gray-100">
                        {/* Patient Detail */}
                        <div className="document-detail">
                            <Text className="detail-heading">Patient:</Text>
                            <Text className="detail-content">{document.patient_name}</Text>
                        </div>

                        {/* Encounter Date Detail */}
                        <div className="document-detail">
                            <Text className="detail-heading">Encounter Date:</Text>
                            <Text className="detail-content">{formatDate(document.created_at)} at {formatTime(document.created_at)}</Text>
                        </div>

                        {/* Visit Description Detail */}
                        <div className="document-detail">
                            <Text className="detail-heading">Visit Description:</Text>
                            <Text className="detail-content">{document.procedure_description}</Text>
                        </div>
                    </div>
                )}
            </div>

            <div style={{ display: 'flex', height: '80vh', gap: '50px', marginBottom: '50px' }}>
                {/* Left Section for the clinical note */}
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Fieldset className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden">
                        <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                            <div className="font-bold text-slate-700 font-sans text-xl pl-2">Clinical Note</div>
                            <div className="flex justify-around">
                                {isEditingReports ? (
                                    <Fieldset
                                        className="rounded p-2 cursor-pointer"
                                        onClick={() => { setIsEditingReports(false); saveDocument('document_content'); }}
                                    >
                                        Save
                                        <FontAwesomeIcon icon={faSave} className="text-indigo-900 ml-2 mr-4" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded p-2 cursor-pointer"
                                        onClick={() => setIsEditingReports(true)}>
                                        Edit
                                        <FontAwesomeIcon icon={faEdit} className="text-indigo-900 ml-2 mr-4" />
                                    </Fieldset>
                                )}
                                {copied ? (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(reports, 'reports')}>
                                        Copied!
                                        <FontAwesomeIcon icon={faCheck} className="text-indigo-900 ml-2" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(reports, 'reports')}>
                                        Copy
                                        <FontAwesomeIcon icon={faCopy} className="text-indigo-900 ml-2" />
                                    </Fieldset>
                                )}
                            </div>
                        </Fieldset>
                        <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-hidden">
                            {isEditingReports ? (
                                <textarea
                                    className="constant-size-textarea w-full border-none outline-none resize-none bg-gray-100 text-slate-700"
                                    value={reports}
                                    onChange={(e) => updateReports(e.target.value)}
                                />
                            ) : (
                                <div className="constant-size-textarea w-full border-none outline-none text-slate-600 whitespace-pre-line overflow-y-auto">
                                    {reports || 'No content available'}
                                </div>
                            )}
                        </div>
                    </Fieldset>
                </div>
                {/* Right Section for summary and CDT codes */}
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '30px', height: '100%' }}>
                    {/* Summary Section */}
                    <Fieldset className="h-1/2 flex flex-col bg-white shadow-lg rounded-lg overflow-hidden">
                        <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                            <div className="font-bold text-slate-700 font-sans text-xl pl-2">Patient Summary</div>
                            <div className="flex justify-around">
                                {isEditingSummary ? (
                                    <Fieldset
                                        className="rounded p-2 cursor-pointer"
                                        onClick={() => { setIsEditingSummary(false); saveDocument('patient_summary'); }}
                                    >
                                        Save
                                        <FontAwesomeIcon icon={faSave} className="text-indigo-900 ml-2 mr-4" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded p-2 cursor-pointer"
                                        onClick={() => setIsEditingSummary(true)}>
                                        Edit
                                        <FontAwesomeIcon icon={faEdit} className="text-indigo-900 ml-2 mr-4" />
                                    </Fieldset>
                                )}
                                {copiedSummary ? (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(summary, 'summary')}>
                                        Copied!
                                        <FontAwesomeIcon icon={faCheck} className="text-indigo-900 ml-2" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(summary, 'summary')}>
                                        Copy
                                        <FontAwesomeIcon icon={faCopy} className="text-indigo-900 ml-2" />
                                    </Fieldset>
                                )}
                            </div>
                        </Fieldset>
                        <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                            {loadingSummary ? (
                                <div className="loading-container">
                                    <div className="loading-spinner"></div>
                                    <Text>Generating patient summary. This may take up to 60 seconds...</Text>
                                </div>
                            ) : errorSummary ? (
                                <Text className="text-red-600">{errorSummary}</Text>
                            ) : isEditingSummary ? (
                                <textarea
                                    className="w-full h-full border-none outline-none resize-none bg-gray-100 text-slate-700"
                                    value={summary}
                                    onChange={(e) => updateSummary(e.target.value)}
                                />
                            ) : summary ? (
                                <textarea
                                    className="w-full h-full border-none outline-none resize-none text-slate-600"
                                    value={summary}
                                />
                            ) : (
                                <button
                                    className="bg-indigo-900 rounded p-2 hover:bg-indigo-900"
                                    onClick={summarizeNote}>
                                    <Text className="text-white">Summarize Note for Patient</Text>
                                </button>
                            )}
                        </div>
                    </Fieldset>

                    <Fieldset className="h-1/2 flex flex-col bg-white shadow-lg rounded-lg overflow-hidden">
                        <Fieldset className="flex justify-between items-center bg-gray-100 pl-2 py-1">
                            <div className="font-bold text-slate-700 font-sans text-xl pl-2">Suggested Billing Codes</div>
                            <div className="flex justify-around">
                                {isEditingCdtCodes ? (
                                    <Fieldset
                                        className="rounded p-2 cursor-pointer"
                                        onClick={() => { setIsEditingCdtCodes(false); saveDocument('code_suggestions'); }}
                                    >
                                        Save
                                        <FontAwesomeIcon icon={faSave} className="text-indigo-900 ml-2 mr-4" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded p-2 cursor-pointer"
                                        onClick={() => setIsEditingCdtCodes(true)}>
                                        Edit
                                        <FontAwesomeIcon icon={faEdit} className="text-indigo-900 ml-2 mr-4" />
                                    </Fieldset>
                                )}
                                {copiedCdtCodes ? (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(cdtCodes, 'cdtCodes')}>
                                        Copied!
                                        <FontAwesomeIcon icon={faCheck} className="text-indigo-900 ml-2" />
                                    </Fieldset>
                                ) : (
                                    <Fieldset
                                        className="rounded py-2 pl-2 pr-3 cursor-pointer"
                                        onClick={() => copyToClipboard(cdtCodes, 'cdtCodes')}>
                                        Copy
                                        <FontAwesomeIcon icon={faCopy} className="text-indigo-900 ml-2" />
                                    </Fieldset>
                                )}
                            </div>
                        </Fieldset>
                        <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-y-auto">
                            {loadingCdtCodes ? (
                                <div className="loading-container">
                                    <div className="loading-spinner"></div>
                                    <Text>Generating suggested billing codes. This may take up to 60 seconds...</Text>
                                </div>
                            ) : errorCdtCodes ? (
                                <Text className="text-red-600">{errorCdtCodes}</Text>
                            ) : isEditingCdtCodes ? (
                                <textarea
                                    className="w-full h-full border-none outline-none resize-none bg-gray-100 text-slate-700"
                                    value={cdtCodes}
                                    onChange={(e) => updateCdtCodes(e.target.value)}
                                />
                            ) : cdtCodes ? (
                                <textarea
                                    className="w-full h-full border-none outline-none resize-none text-slate-600"
                                    value={cdtCodes}
                                />
                            ) : (
                                <button
                                    className="bg-indigo-900 text-white rounded p-2 hover:bg-indigo-900"
                                    onClick={suggestCDTCodes}>
                                    <Text className="text-white">Suggest Billing Codes</Text>
                                </button>
                            )}
                        </div>
                    </Fieldset>
                </div>
            </div>
        </div>
    );
}

export default ViewReports;
