import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useMsal, MsalProvider } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';

import { PageLayout } from "./components/PageLayout";
import { Home } from "./pages/Home";
import { b2cPolicies } from "./authConfig";
import { useLocation } from "react-router-dom";

import { useFeatureFlagEnabled } from "posthog-js/react";

import ViewReports from "./pages/ViewReports";
import NoteDashboard from "./pages/NoteDashboard";
import AcceptPolicies from "./pages/AcceptPolicies";

import ViewERPatientInfo from "./pages/ViewERPatientInfo";
import ERDashboard from "./pages/ERDashboard";
import PatientInfoCarepoint from "./pages/PatientInfoCarepointPOC";
import CarepointLocationSurvey from "./pages/CarepointLocationSurvey";
import HospitalistNoteDashboard from "./pages/HospitalistNoteDashboard";
import HospitalistChart from "./pages/HospitalistChart";

import AviationDashboard from "./pages/AviationDashboard";
import AviationAnalyticsHub from "./pages/AviationAnalyticsHub";
import FlightInfo from "./pages/FlightInfo";

import AdminDashboard from "./pages/AdminDashboard";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import TableNoteDashboard from "./pages/TableNoteDashboard";
import TeacherNoteDashboard from "./pages/TeacherNoteDashboard";

import MacrosPage from "./pages/Macros";
import Settings from "./pages/Settings";

import GlobalAdminDashboard from "./pages/GlobalAdminDashboard";
import OrganizationAdminDashboard from "./pages/OrganizationAdminDashboard";

import SignUp from "./pages/SignUp";
import Subscribe from "./pages/Subscribe";
import ProcessSignUp from "./pages/ProcessSignUp";
import ProcessSignUpD2C from "./pages/ProcessSignUpD2C";

import { protectedResources } from "./authConfig";
import { AuthProvider } from './contexts/AuthProvider';

import "./styles/App.css";

//...
import * as Sentry from "@sentry/react";

import posthog from "posthog-js";

posthog.init("phc_Ixe2VTs6tgoDI3WWt26mowQzBhCEXsCEUhYXMmhHJmH", {
  api_host: "https://app.posthog.com",
});

Sentry.init({
  dsn: "https://f3a793673660bb4367a89f3d6aba2866@o4506684392407040.ingest.sentry.io/4506684598648832",
  integrations: [
    Sentry.feedbackIntegration({
      colorScheme: "system",
    }),
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/cleohealth\.io\/api/],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const hasRequiredRole = async (instance, requiredRoles) => {
  const accounts = instance.getAllAccounts();
  if (accounts.length > 0) {
    try {
      const request = {
        scopes: protectedResources.apiGetMyInfo.scopes,
        account: accounts[0],
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const fetchResponse = await fetch(
        protectedResources.apiGetMyInfo.endpoint,
        requestOptions
      );
      const actorInfo = await fetchResponse.json();

      if (requiredRoles.includes(actorInfo.admin_status)) {
        console.log(`Access granted for User ID: ${actorInfo.actor_uuid}`);
        return true;
      } else {
        console.log(`Access denied for User ID: ${actorInfo.actor_uuid}`);
        return false;
      }
    } catch (error) {
      console.error("Error checking admin status:", error);
      return false;
    }
  }
  console.log('No accounts found or user is not signed in.');
  return false;
};

const ProtectedRoute = ({ children, requiredRoles }) => {
  const { instance } = useMsal();
  const location = useLocation();
  const [hasAccess, setHasAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAccess = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      const accessStatus = await hasRequiredRole(instance, requiredRoles);
      setHasAccess(accessStatus);
      setIsLoading(false);
    };

    checkAccess();
  }, [instance, requiredRoles]);

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  if (!hasAccess) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

const Pages = () => {
  const location = useLocation();

  const { instance } = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        // Only redirect if not coming from the admin dashboard
        // if (location.pathname !== '/admin') {
        //     navigate('/notedashboard');
        // }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.includes("AADB2C90118")) {
          // Handling forgotten password
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        } else if (isTokenExpiredError(event.error)) {
          // Handling token expiration
          instance.loginRedirect(); // or instance.loginPopup()
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, navigate, location]);

  function isTokenExpiredError(error) {
    // Logic to determine if the error is due to token expiration
    // This could be based on specific error codes or messages
    return (
      error &&
      error.errorMessage &&
      error.errorMessage.indexOf("token expired") !== -1
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/macros" element={<MacrosPage />} />
      <Route path="/settings/*" element={<Settings />} />
      <Route path="/global-admin-dashboard" element={
        <ProtectedRoute requiredRoles={["GLOBAL_ADMIN"]}>
          <GlobalAdminDashboard />
        </ProtectedRoute>
      } />
      <Route path="/organization-admin-dashboard" element={
        <ProtectedRoute requiredRoles={["GLOBAL_ADMIN", "ORGANIZATION_ADMIN"]}>
          <OrganizationAdminDashboard />
        </ProtectedRoute>
      } />
      <Route path="/notedashboard" element={<NoteDashboard />} />
      <Route path="/view-report" element={<ViewReports />} />
      <Route path="/aviation-dashboard" element={<AviationDashboard />} />
      <Route
        path="/aviation-analytics-hub"
        element={<AviationAnalyticsHub />}
      />
      <Route path="/er-dashboard" element={<ERDashboard />} />
      <Route path="/view-er-patient" element={<PatientInfoCarepoint />} />
      <Route path="/flight-info" element={<FlightInfo />} />
      <Route path="/view-er-patient-info" element={<ViewERPatientInfo />} />
      <Route path="/carepoint-location-survey" element={<CarepointLocationSurvey />} />
      <Route path="/hospitalist-inpatient-dashboard" element={<HospitalistNoteDashboard />} />
      <Route path="/hospitalist-chart" element={<HospitalistChart />} />

      {/* <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
      <Route path="/accept-policies" element={<AcceptPolicies />} />
      <Route path="/notes" element={<TableNoteDashboard />} />
      <Route path="/teacher-dashboard" element={<TeacherNoteDashboard />} />

      <Route path="/signup" element={<SignUp />} />
      <Route path="/subscribe" element={<Subscribe />} />

      {/* process-sign-up is for CarePoint, process-signup is for D2C flow */}
      <Route path="/process-sign-up" element={<ProcessSignUp />} />
      <Route path="/process-signup" element={<ProcessSignUpD2C />} />
      <Route
        path="/admin"
        element={
          <ProtectedRoute requiredRoles={["GLOBAL_ADMIN"]}>
            <AdminDashboard />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <AuthProvider>
        <PageLayout>
          <Pages />
        </PageLayout>
      </AuthProvider>
    </MsalProvider>
  );
};

export default App;