// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardContainer {
    background: linear-gradient(135deg, #7f5a83, #0d324d); /* Adjust the gradient colors as needed */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    color: white;
    font-size: 24px; /* Adjust font size as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust height as needed */
    width: 300px; /* Adjust width as needed */
    margin: auto;
}

.analytics-hub-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.analytics-hub-loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #4f6672;
    /* Your theme color */
    width: 40px;
    height: 40px;
    /* Safari */
    animation: spin 2s linear infinite;
    margin-bottom: 10px;
}

/* Keyframes for spin animation */

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/AviationAnalyticsHub.css"],"names":[],"mappings":"AAAA;IACI,qDAAqD,EAAE,yCAAyC;IAChG,kBAAkB;IAClB,0CAA0C;IAC1C,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,eAAe,EAAE,+BAA+B;IAChD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,4BAA4B;IAC3C,YAAY,EAAE,2BAA2B;IACzC,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,6BAA6B;IAC7B,qBAAqB;IACrB,WAAW;IACX,YAAY;IAEZ,WAAW;IACX,kCAAkC;IAClC,mBAAmB;AACvB;;AAEA,iCAAiC;;AAajC;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".cardContainer {\n    background: linear-gradient(135deg, #7f5a83, #0d324d); /* Adjust the gradient colors as needed */\n    border-radius: 8px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n    padding: 20px;\n    text-align: center;\n    color: white;\n    font-size: 24px; /* Adjust font size as needed */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 200px; /* Adjust height as needed */\n    width: 300px; /* Adjust width as needed */\n    margin: auto;\n}\n\n.analytics-hub-loading-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    height: 100%;\n}\n\n.analytics-hub-loading-spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-radius: 50%;\n    border-top: 4px solid #4f6672;\n    /* Your theme color */\n    width: 40px;\n    height: 40px;\n    -webkit-animation: spin 2s linear infinite;\n    /* Safari */\n    animation: spin 2s linear infinite;\n    margin-bottom: 10px;\n}\n\n/* Keyframes for spin animation */\n@-webkit-keyframes spin {\n\n    /* Safari */\n    0% {\n        -webkit-transform: rotate(0deg);\n    }\n\n    100% {\n        -webkit-transform: rotate(360deg);\n    }\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
