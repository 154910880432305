import React, { createContext, useState, useContext, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { protectedResources } from '../authConfig';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
    const { instance, accounts, inProgress } = useMsal();
    const [actorInfo, setActorInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchActorInfo = async () => {
        if (accounts.length === 0 || inProgress !== "none") {
            setIsLoading(false);
            return;
        }

        try {
            const request = {
                scopes: protectedResources.apiGetMyInfo.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            const fetchResponse = await fetch(protectedResources.apiGetMyInfo.endpoint, requestOptions);
            const data = await fetchResponse.json();
            setActorInfo(data);
        } catch (error) {
            console.error('Error fetching actor info:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchActorInfo();
    }, [instance, accounts, inProgress]);

    const updateActorInfo = (field, value) => {
        setActorInfo(prevInfo => ({
            ...prevInfo,
            [field]: value
        }));
    };

    return (
        <AuthContext.Provider value={{ actorInfo, isLoading, updateActorInfo, isAuthenticated: accounts.length > 0 }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}