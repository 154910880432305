import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Confetti from 'react-confetti';
// import '../css_files/LoadingScreen.css';
import { jwtDecode } from 'jwt-decode';

const ProcessSignUp = () => {
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(true);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [error, setError] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(() => {
        const hash = window.location.hash.substr(1);
        const params = new URLSearchParams(hash);
        const idToken = params.get('id_token');
        if (idToken) {
            try {
                const decodedToken = jwtDecode(idToken);
                setIsSuccessful(true);
                setIsLoading(false);
                setUserEmail(decodedToken.email); // Adjust according to your token's claims
                setUserName(decodedToken.name);
                setShowConfetti(true);

                setTimeout(() => {
                    setShowConfetti(false);
                }, 7000);
            } catch (error) {
                console.error('Error decoding ID token:', error);
                setIsLoading(false);
                setError('There was an error decoding your sign-up information. Please contact jackson@cleo-ai.co with your name and email.');
            }
        } else {
            setIsLoading(false);
            setError('There was an error completing your sign-up. Please contact jackson@cleo-ai.co with your name and email.');
        }
    }, [searchParams]);

    return (
        <div className="bg-white px-6 py-32 lg:px-8">
            {showConfetti && <Confetti />}
            <div className="mx-auto max-w-3xl text-center">
                {!isLoading && isSuccessful ? (
                    <>
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Welcome to Cleo, {userName}!
                        </h1>
                        <p className="mt-6 text-xl leading-8 text-gray-700">
                            Continue following the instructions in your onboarding email to begin using Cleo.
                        </p>
                        {/* Additional content here */}
                    </>
                ) : (
                    // Display error message
                    <div>
                        <h2 className="text-xl font-bold text-red-600">Error</h2>
                        <p className="mt-2 text-gray-800">{error}</p>
                    </div>
                )}
            </div>
        </div>
    );

};

export default ProcessSignUp;
