import React, { useState, useEffect, useCallback } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useNavigate } from 'react-router-dom';
import '../styles/NoteDashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisH, faComments, faClipboardList } from '@fortawesome/free-solid-svg-icons'

import { Fieldset, Legend } from '../components/catalyst/fieldset';
import { Text } from '../components/catalyst/text';



function NoteDashboard() {
    const { instance, accounts, inProgress } = useMsal();
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);

    const fetchAllReports = useCallback(async () => {
        setIsLoading(true);
        if (accounts.length > 0) {


            try {
                const request = {
                    scopes: protectedResources.apiGetDocumentsForTeacher.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                // const documentStatus = 'APPROVED'; // replace with the status you want to filter by

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                };

                // const fetchResponse = await fetch(`${protectedResources.apiGetDocumentsForTeacher.endpoint}?document_status=${documentStatus}`, requestOptions);                const data = await fetchResponse.json();
                const fetchResponse = await fetch(`${protectedResources.apiGetDocumentsForTeacher.endpoint}}`, requestOptions);
                const data = await fetchResponse.json();


                if (data.documents && data.documents.length > 0) {
                    data.documents.forEach(doc => console.log(`Raw created_at from server: ${doc.created_at}`));
                    // Sort documents by 'created_at' in descending order
                    const sortedDocuments = data.documents.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    setDocuments(sortedDocuments);
                } else {
                    setDocuments([]);
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching reports:', error);
            }
        } setIsLoading(false);
    }, [instance, accounts, handleTokenExpiration]);

    useEffect(() => {
        const loading = inProgress === InteractionStatus.Startup ||
            inProgress === InteractionStatus.HandleRedirect ||
            inProgress === InteractionStatus.Login ||
            inProgress === InteractionStatus.Logout;

        setIsLoading(loading);
        fetchAllReports();
    }, [fetchAllReports, inProgress]);


    const handleDocumentClick = (doc) => {
        navigate('/view-report', { state: { document: doc } });
    };


    const formatTime = (utcString) => {

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const utcDate = new Date(utcString + 'Z');
        const formatter = new Intl.DateTimeFormat(undefined, options);
        return formatter.format(utcDate);
    };


    return (
        <div className="dashboard-container">
            <div className="w-full py-4 mb-1 rounded-md">
                <Fieldset className="flex flex-row justify-between items-center">
                    <Fieldset className="flex items-center space-x-4">
                        <FontAwesomeIcon icon={faClipboardList} className="text-indigo-900 text-3xl" />
                        <Fieldset>
                            <h1 className="text-2xl font-bold text-indigo-800">Note Dashboard</h1>
                            <p className="text-md text-indigo-900">Track and manage your documentation efficiently.</p>
                        </Fieldset>
                    </Fieldset>
                </Fieldset>

            </div>

            {isLoading ? (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="loading-text">Loading notes...</div>
                </div>
            ) : (
                documents.length > 0 ? (

                    <Fieldset className="w-full bg-white rounded-lg">
                        <Fieldset className="bg-gray-100 rounded-lg shadow-lg mb-4 hover:bg-gray-100 cursor-pointer px-4 py-3 transition duration-300 ease-in-out">
                            <Fieldset className="flex flex-row items-left text-left gap-4">
                                <Text className="w-1/3 font-bold">Patient Info</Text>
                                <Text className="w-1/6 font-bold">Appointment Time</Text>
                                <Text className="w-1/6 font-bold">Note Type</Text>
                                <Text className="w-1/6 font-bold">Summary</Text>
                                <Text className="w-1/6 font-bold">Codes</Text>
                                <Text className="w-1/6 font-bold">Status</Text>
                            </Fieldset>
                        </Fieldset>
                        {documents.map((doc, index) => (
                            <Fieldset key={index}
                                className="bg-white rounded-lg shadow-lg mb-2 hover:bg-gray-200 cursor-pointer p-4 transition duration-300 ease-in-out"
                                onClick={() => handleDocumentClick(doc)}>
                                <Fieldset className="flex flex-row items-left text-left gap-4">
                                    <Fieldset className='w-1/3 flex flex-column text-left '>
                                        <Text className="font-bold text-black">{doc.patient_name}</Text>
                                        <Fieldset className="flex flex-row items-center text-left">
                                            <FontAwesomeIcon icon={faComments} className="mr-1 mt-1 text-indigo-800 text-sm" />
                                            <Text className="text-black w-3/4">{doc.procedure_description}</Text>
                                        </Fieldset>
                                    </Fieldset>
                                    <Text className="w-1/6 flex items-center text-black pl-2">{formatTime(doc.created_at)}</Text>
                                    <Text className="w-1/6 flex items-center text-black pl-1">{doc.procedure_code}</Text>
                                    <Fieldset className="w-1/6 flex justify-left pl-5 items-center">
                                        {doc.patient_summary ? (
                                            <Fieldset className="flex justify-center items-center w-8 h-8 bg-green-200 rounded-lg text-xl">
                                                <FontAwesomeIcon icon={faCheck} className="text-green-600" />
                                            </Fieldset>
                                        ) : (
                                            <Fieldset className="w-1/6 flex justify-center items-center w-8 h-8 bg-gray-200 rounded-lg text-xl">
                                                <FontAwesomeIcon icon={faEllipsisH} className="text-gray-500" />
                                            </Fieldset>
                                        )}
                                    </Fieldset>
                                    <Fieldset className="w-1/6 flex justify-left pl-2 items-center">
                                        {doc.code_suggestions ? (
                                            <Fieldset className="flex justify-center items-center w-8 h-8 bg-green-200 rounded-lg text-xl">
                                                <FontAwesomeIcon icon={faCheck} className="text-green-600" />
                                            </Fieldset>
                                        ) : (
                                            <Fieldset className="w-1/6 flex justify-center items-center w-8 h-8 bg-gray-200 rounded-lg text-xl">
                                                <FontAwesomeIcon icon={faEllipsisH} className="text-gray-500" />
                                            </Fieldset>
                                        )}
                                    </Fieldset>
                                    <Fieldset className="w-1/6 flex justify-left pl-2 items-center">
                                        {doc.document_status !== "APPROVED" ? (
                                            <Fieldset className="flex justify-center items-center w-8 h-8 bg-green-200 rounded-lg text-xl">
                                                <FontAwesomeIcon icon={faCheck} className="text-green-600" />
                                            </Fieldset>
                                        ) : (
                                            <Fieldset className="w-1/6 flex justify-center items-center w-8 h-8 bg-gray-200 rounded-lg text-xl">
                                                <FontAwesomeIcon icon={faEllipsisH} className="text-gray-500" />
                                            </Fieldset>
                                        )}
                                    </Fieldset>
                                </Fieldset>
                            </Fieldset>
                        ))}
                    </Fieldset>
                ) : (
                    <Fieldset className="w-full flex flex-col items-center justify-center h-full">
                        <Legend>No Documents Available</Legend>
                        <Text className="mt-4 text-center">
                            There are no documents available.
                        </Text>
                    </Fieldset>
                )
            )}
        </div>
    );





}

export default NoteDashboard;
