import React, { useMemo } from 'react';
import ApexChart from 'react-apexcharts';

const BlockProgressChart = ({ numerator, denominator }) => {
    const remaining = denominator - numerator;

    const chartOptions = useMemo(() => {
        return {
            chart: {
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: ['Completed', 'Remaining'],
            colors: ['#7f5a83', '#0d324d'], // Adjusted to indigo shades
            legend: {
                position: 'bottom' // Hides the legend
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex]; // Displaying actual numbers
                },
                style: {
                    colors: ['#fff'],
                    fontSize: '16px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                },
                dropShadow: {
                    enabled: false
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            plotOptions: {
                pie: {
                    expandOnClick: false, // Prevents slice expansion on click
                    dataLabels: {
                        offset: -10 // Adjusts label positions inside slices
                    }
                }
            },
            tooltip: {
                enabled: false // Disables tooltips
            }
        };
    }, []);

    const chartSeries = useMemo(() => [numerator, remaining], [numerator, remaining]);

    return (
        <div className="flex justify-center items-center w-full h-full">
            <ApexChart options={chartOptions} series={chartSeries} type="pie" />
        </div>
            
    );
};

export default BlockProgressChart;