import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useNavigate } from 'react-router-dom';

import BlockProgressChart from '../components/BlockProgressChart.jsx';
import AllBlocksProgressChart from '../components/AllBlockProgressChart.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons'

import { Fieldset } from '../components/catalyst/fieldset';
import { Text } from '../components/catalyst/text';

import styles from '../styles/AviationAnalyticsHub.css';

const exampleBlocksData = {
        'TR': {
            'progress': 5,
            'remaining': 2
          },
          'IR': {
            'progress': 2,
            'remaining': 8
          },
          'BFM': {
            'progress': 0,
            'remaining': 15
          },
          'CQ': {
            'progress': 0,
            'remaining': 8
          }
  };

const exampleFlightsAndSims = {
    'flights': 3,
    'sims': 4
  };


function AviationAnalyticsHub() {
    const { instance, accounts, inProgress } = useMsal();
    const [flightProgress, setFlightProgress] = useState(0);
    const [blockFlights, setBlockFlights] = useState(0);
    const [lastDocumentContent, setLastDocumentContent] = useState("");
    const [lastFlightName, setLastFlightName] = useState("");
    const [lastTrainingBlock, setLastTrainingBlock] = useState("");
    const [numRecentFlights, setNumRecentFlights] = useState(0);
    const [numRecentSims, setNumRecentSims] = useState(0);
    const [allTrainingBlocksProgress, setAllTrainingBlocksProgress] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [performanceAnalysisContent, setPerformanceAnalysisContent] = useState("");
    const [isLoadingPerformanceAnalysis, setIsLoadingPerformanceAnalysis] = useState(false);
    const [queriedPerformanceAnalysis, setQueriedPerformanceAnalysis] = useState(false);
    const [isLoadingFlightEvaluation, setIsLoadingFlightEvaluation] = useState(false);
    const [queriedFlightEvaluation, setQueriedFlightEvaluation] = useState(false);
    const [numFlights, setNumFlights] = useState(0);
    const [isFlightsChecked, setIsFlightsChecked] = useState(true);
    const [isSimsChecked, setIsSimsChecked] = useState(false);
    const [flightToEvaluate, setFlightToEvaluate] = useState("");
    const [evaluatedFlightContent, setEvaluatedFlightContent] = useState("");


    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);

    const AnalyzeSnaPerformance = useCallback(async () => {
        setIsLoadingPerformanceAnalysis(true);
        setQueriedPerformanceAnalysis(false);

        const flightType = isFlightsChecked && isSimsChecked ? 'Both'
                        : isFlightsChecked ? 'FLIGHT'
                        : 'SIM';

        if (accounts.length > 0) {
            try {
                // FIRST: pull numbers for current user's flight progress
                const performanceAnalysisRequest = {
                    scopes: protectedResources.apiAnalyzeSnaPerformance.scopes,
                    account: accounts[0]
                };

                const performanceAnalysisResponse = await instance.acquireTokenSilent(performanceAnalysisRequest);
                const token = performanceAnalysisResponse.accessToken;

                const body = {
                    num_flights: numFlights,
                ...(flightType !== "Both" && {flight_type: flightType})
                };
                console.log(body);

                const performanceAnalysisRequestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(body)
                };



                const performanceAnalysisResponseOptions = await fetch(protectedResources.apiAnalyzeSnaPerformance.endpoint, performanceAnalysisRequestOptions);
                const performanceAnalysisData = await performanceAnalysisResponseOptions.json();


                if (performanceAnalysisData.document_content) {
                    setPerformanceAnalysisContent(performanceAnalysisData.document_content);
                } else {
                    setPerformanceAnalysisContent("ERROR RETRIEVING FROM SERVER")
                }
            }  catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching information:', error);
            }
        }
        setIsLoadingPerformanceAnalysis(false);
        setQueriedPerformanceAnalysis(true);
    }, [numFlights, isFlightsChecked, isSimsChecked, instance, accounts, handleTokenExpiration]);

    const EvaluateFlight = useCallback(async () => {
        setIsLoadingFlightEvaluation(true);
        setQueriedFlightEvaluation(false);

        if (accounts.length > 0) {


            try {
                // FIRST: pull numbers for current user's flight progress
                const evaluateFlightRequest = {
                    scopes: protectedResources.apiEvaluateFlight.scopes,
                    account: accounts[0]
                };

                const evaluateFlightResponse = await instance.acquireTokenSilent(evaluateFlightRequest);
                const token = evaluateFlightResponse.accessToken;

                const body = {
                    flight_name: flightToEvaluate,
                };
                console.log(body);

                const evaluateFlightRequestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(body)
                };

                const evaluateFlightResponseOptions = await fetch(protectedResources.apiEvaluateFlight.endpoint, evaluateFlightRequestOptions);
                const evaluateFlightData = await evaluateFlightResponseOptions.json();

                if (evaluateFlightData.document_content) {
                    setEvaluatedFlightContent(evaluateFlightData.document_content);
                } else {
                    setEvaluatedFlightContent("ERROR RETRIEVING FROM SERVER")
                }
            }  catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching information:', error);
            }
        }
        setIsLoadingFlightEvaluation(false);
        setQueriedFlightEvaluation(true);
    }, [flightToEvaluate, instance, accounts, handleTokenExpiration]);

    const fetchStarterStatistics = useCallback(async () => {
        setIsLoading(true);
        if (accounts.length > 0) {

            try {
                // FIRST: pull numbers for current user's flight progress
                const progressRequest = {
                    scopes: protectedResources.apiGetBlockProgress.scopes,
                    account: accounts[0]
                };

                const progressResponse = await instance.acquireTokenSilent(progressRequest);
                const token = progressResponse.accessToken;

                const progressRequestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };

                const progressResponseOptions = await fetch(protectedResources.apiGetBlockProgress.endpoint, progressRequestOptions);
                const progressData = await progressResponseOptions.json();


                if (progressData.current && progressData.total) {
                    setFlightProgress(progressData.current);
                    setBlockFlights(progressData.total);
                } else {
                    setFlightProgress(-1);
                    setBlockFlights(-1);
                }

                // SECOND: pull the user's last flight report
                const flightReportRequestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };

                const flightReportResponseOptions = await fetch(protectedResources.apiGetLastFlightReport.endpoint, flightReportRequestOptions);
                const flightReportData = await flightReportResponseOptions.json();

                if (flightReportData.flight_report) {
                    setLastTrainingBlock(flightReportData.flight_report.block_name)
                    setLastFlightName(flightReportData.flight_report.flight_name)
                    setLastDocumentContent(flightReportData.flight_report.document_content)
                } else {
                    setLastTrainingBlock("ERROR_NOT_FETCHED");
                    setLastFlightName("ERROR_NOT_FETCHED");
                    setLastDocumentContent("ERROR_NOT_FETCHED");
                }

                // THIRD: pull the user's number of flight and sims
                const numFlightsSimsRequestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };

                const numFlightsSimsResponseOptions = await fetch(protectedResources.apiGetRecentFlightsSims.endpoint, numFlightsSimsRequestOptions);
                const numFlightsSimsData = await numFlightsSimsResponseOptions.json();

                if (numFlightsSimsData.num_flights !== undefined && numFlightsSimsData.num_sims !== undefined) {
                    setNumRecentFlights(numFlightsSimsData.num_flights);
                    setNumRecentSims(numFlightsSimsData.num_sims);
                } else {
                    setNumRecentFlights(-1);
                    setNumRecentSims(-1);
                }

                // FOURTH: pull the user's progress on each training block
                const allTrainingBlocksProgressRequestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };

                const allTrainingBlocksProgressResponseOptions = await fetch(protectedResources.apiGetAllTrainingBlocksProgress.endpoint, allTrainingBlocksProgressRequestOptions);
                const allTrainingBlocksProgressData = await allTrainingBlocksProgressResponseOptions.json();


                if (allTrainingBlocksProgressData.progress_dict) {
                    setAllTrainingBlocksProgress(allTrainingBlocksProgressData.progress_dict)
                } else {
                    console.log("ERROR FETCHING DATA")
                    setAllTrainingBlocksProgress({error: "Error fetching data"})
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching information:', error);
            }
        } setIsLoading(false);
    }, [instance, accounts,handleTokenExpiration]);

    useEffect(() => {
        const loading = inProgress === InteractionStatus.Startup ||
            inProgress === InteractionStatus.HandleRedirect ||
            inProgress === InteractionStatus.Login ||
            inProgress === InteractionStatus.Logout;

        setIsLoading(loading);
        fetchStarterStatistics();
    }, [fetchStarterStatistics, inProgress]);

    return (
        <div className="dashboard-container">
            <div className="w-full pt-5 pb-12 mb-1 rounded-md">
                <Fieldset className="flex flex-row justify-between items-left">
                    <Fieldset className="flex items-center space-x-4">
                        <FontAwesomeIcon icon={faChartLine} className="text-slate-700 text-6xl" />
                        <Fieldset>
                            <h1 className="text-4xl font-bold text-slate-700">Analytics Hub</h1>
                            <p className="text-lg text-slate-700">Get the 30,000ft View of Your Performance.</p>
                        </Fieldset>
                    </Fieldset>
                </Fieldset>
            </div>

            {isLoading ? (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="loading-text">Loading your analytics hub...</div>
                </div>
            ) : (
                <div className="flex flex-col h-auto mb-50">
                    <div style={{ display: 'flex', height: '30vh', gap: '50px', justifyContent: 'space-between'}}>
                        <Fieldset className="w-1/3 flex flex-col justify-between items-center bg-gradient-to-r from-gray-200 to-gray-100 shadow-lg rounded-lg pt-3 pb-8 px-5 mx-auto h-full">
                            <div className="font-bold text-center text-slate-700 font-sans text-xl mb-2">Your Current Block ({lastTrainingBlock})</div>
                            <div className="text-center text-white items-center">
                                <BlockProgressChart numerator={flightProgress} denominator={blockFlights} />
                            </div>
                        </Fieldset>
                        <Fieldset className="w-1/3 flex flex-col justify-between items-center bg-gradient-to-r from-gray-200 to-gray-100 shadow-lg rounded-lg pt-3 pb-8 px-5 mx-auto h-full">
                            <div className="font-bold text-center text-slate-700 font-sans text-xl mb-2">Your Total Progress</div>
                            <AllBlocksProgressChart blocksData={allTrainingBlocksProgress} />
                        </Fieldset>
                        <Fieldset className="w-1/3 flex flex-col justify-between items-center bg-gradient-to-r from-gray-200 to-gray-100 shadow-lg rounded-lg pt-3 pb-8 px-5 mx-auto h-full">
                            <div className="font-bold text-center text-slate-700 font-sans text-xl mb-2">Last Week at a Glance</div>
                            <Fieldset className="flex items-center text-center justify-center">
                                <Fieldset className="flex flex-col items-center mt-4 mx-8 mb-10">
                                    <div className="text-xl font-bold text-slate-500">Flights</div>
                                    <div className="text-8xl text-slate-700 font-semibold">{numRecentFlights}</div>
                                </Fieldset>
                                <Fieldset className="flex flex-col items-center mt-4 mx-8 mb-10">
                                    <div className="text-xl font-bold text-slate-500">Sims</div>
                                    <div className="text-8xl text-slate-700 font-semibold">{numRecentSims}</div>
                                </Fieldset>
                            </Fieldset>
                        </Fieldset>
                    </div>
                    <div className="mt-20 flex justify-center w-full h-90">
                        <div className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden w-full mx-4">
                            <Fieldset className="flex justify-center items-center bg-gray-100 pl-2 py-3 text-center">
                                <div className="font-bold text-slate-700 font-sans text-xl">Your Last Debrief ({lastFlightName})</div>
                            </Fieldset>
                            <div className="px-4 py-3 border-t border-gray-200 overflow-hidden">
                                <textarea
                                    className="w-full h-full border-none outline-none text-slate-700 overflow-y-auto"
                                    style={{ height: '250px' }}
                                    value={lastDocumentContent}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <Fieldset className="mt-20 mb-20 flex justify-center w-full h-auto">
                        <div className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden w-3/5 h-96 mx-4">
                            <Fieldset className="flex justify-center items-center bg-gray-100 pl-2 py-3 text-center">
                                <div className="font-bold text-slate-700 font-sans text-xl">Analyze Your Performance</div>
                            </Fieldset>
                            <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-hidden">
                                {isLoadingPerformanceAnalysis ? (
                                    <div className="analytics-hub-loading-container">
                                        <div className="analytics-hub-loading-spinner"></div>
                                        <Text>Generating performance analysis. This may take up to 60 seconds...</Text>
                                    </div>
                                ) : queriedPerformanceAnalysis ? (
                                    <textarea
                                        className="w-full h-full border-none outline-none text-slate-700 overflow-y-auto"
                                        value={performanceAnalysisContent}
                                        readOnly
                                    />
                                ) : (
                                    <div className="flex flex-col space-y-4">
                                        <div className="flex items-center space-x-4">
                                        <label className="font-medium text-slate-700">
                                            <input
                                                type="checkbox"
                                                value="Flights"
                                                checked={isFlightsChecked}
                                                onChange={() => setIsFlightsChecked(!isFlightsChecked)}
                                            /> Flights
                                        </label>
                                        <label className="font-medium text-slate-700">
                                            <input
                                                type="checkbox"
                                                value="Sims"
                                                checked={isSimsChecked}
                                                onChange={() => setIsSimsChecked(!isSimsChecked)}
                                            /> Sims
                                        </label>
                                        </div>
                                        <input
                                            type="number"
                                            placeholder="Enter number (e.g., 10)"
                                            className="w-2/3 px-2 py-2 border rounded-md text-slate-700"
                                            value={numFlights}
                                            onChange={(e) => setNumFlights(Number(e.target.value))}
                                        />
                                        <button
                                            className="w-2/3 bg-slate-800 text-white font-bold py-2 px-4 rounded hover:bg-slate-600"
                                            onClick={AnalyzeSnaPerformance}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden w-3/5 h-96 mx-4 mb-120">
                            <Fieldset className="flex justify-center items-center bg-gray-100 pl-2 py-3 text-center">
                                <div className="font-bold text-slate-700 font-sans text-xl">Get Flight Insights</div>
                            </Fieldset>
                            <div className="flex-grow px-4 py-3 border-t border-gray-200 overflow-hidden">
                                {isLoadingFlightEvaluation ? (
                                    <div className="analytics-hub-loading-container">
                                        <div className="analytics-hub-loading-spinner"></div>
                                        <Text>Generating flight insights. This may take up to 60 seconds...</Text>
                                    </div>
                                ) : queriedFlightEvaluation ? (
                                    <textarea
                                        className="w-full h-full border-none outline-none text-slate-700 overflow-y-auto    "
                                        value={evaluatedFlightContent}
                                        readOnly
                                    />
                                ) : (
                                    <div className="flex flex-col space-y-4">
                                        <input
                                            type="text"
                                            placeholder="Enter flight name (e.g., TR4106)"
                                            className="w-2/3 px-2 py-2 border rounded-md text-slate-700"
                                            value={flightToEvaluate}
                                            onChange={(e) => setFlightToEvaluate(e.target.value)}
                                        />
                                        <button
                                            className="w-2/3 bg-slate-800 text-white font-bold py-2 px-4 rounded hover:bg-slate-600"
                                            onClick={EvaluateFlight}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Fieldset>


                </div>
            )}
        </div>
    );
}

export default AviationAnalyticsHub;
